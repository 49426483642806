import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { Row, Col } from 'reactstrap';

const CastTypeMembers = ({ castMembers }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);

  return (
    <div>
      {castMembers && castMembers.length > 0 ? (
        <Row>
          <Col className="d-flex">
            <ColumnWrapper>
              <DetailText>{t('crew_starring')}:</DetailText>
            </ColumnWrapper>
            <div>
              <DetailLabel> {castMembers.split(', ').join('\n')}</DetailLabel>
            </div>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

const DetailText = styled.div`
  font-size: 16px;
  line-height: 1.6;
  width: 20px;
  color: #ffffff;
  margin-left: 8px;
`;

const DetailLabel = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 1.6;
  width: auto;
  padding: 0 8px;
  white-space: pre-line;
  color: #9ea0a5;
`;

const ColumnWrapper = styled.div`
  width: 70px;
`;

export default CastTypeMembers;
