import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { isAllowed } from 'utils/helpers';
import { getUser } from 'redux/reducers/sessionReducer';
import './Authorize.scss';

class Authorize extends Component {
  renderChildrenWithProps(children, renderIfUnauthorized = false) {
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        disabled: renderIfUnauthorized,
        className: renderIfUnauthorized ? 'is-disabled' : null,
        onClick: null,
      })
    );

    return renderIfUnauthorized ? <Fragment>{childrenWithProps}</Fragment> : null;
  }

  render() {
    const { user, children, renderIfUnauthorized, forPermissions } = this.props;

    return (
      <Fragment>
        {isAllowed(forPermissions, { user }) ? children : this.renderChildrenWithProps(children, renderIfUnauthorized)}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: getUser(state),
  };
};

export default connect(mapStateToProps)(Authorize);
