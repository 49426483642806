import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ErrorMessage = ({ message, className, ...rest }) => {
  if (!message) {
    return null;
  }

  return (
    <Error {...rest} className={`my-2 ${className}`}>
      {message}
    </Error>
  );
};

const Error = styled.p`
  margin-top: 5px;
  color: #ec4444;
  font-size: 12px;
`;

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default ErrorMessage;
