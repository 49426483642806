import React from 'react';
import styled from 'styled-components';
import { DropdownToggle as ReactstrapDropdownToggle } from 'reactstrap';
import { FaSortDown } from 'react-icons/fa';

const DropdownToggleButton = ({ disabled, onClick, children, className, ...rest }) => {
  return (
    <DropdownToggle disabled={disabled} onClick={onClick} className={className} {...rest}>
      {children}
      <IconWrapper>
        <IconDown />
      </IconWrapper>
    </DropdownToggle>
  );
};

const DropdownToggle = styled(ReactstrapDropdownToggle)`
  height: 49px;
  padding-left: 2rem;
  padding-right: 2.5rem;
  border-radius: 39px;
  border: solid 1px #707070;
  background-color: var(--darker);
  color: var(--white);
  text-transform: uppercase;

  &&:hover,
  &&:focus,
  &&:active {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }

  &&:disabled {
    &:hover {
      background-color: var(--secondary) !important;
      cursor: not-allowed;
    }
  }
`;

const IconWrapper = styled.div`
  display: inline;
  position: relative;
  margin-left: 0.5rem;
`;

const IconDown = styled(FaSortDown)`
  position: absolute;
  color: var(--white);
`;

export default DropdownToggleButton;
