import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import routes from 'utils/routes';
import { getUser } from 'redux/reducers/sessionReducer';

// !! NOTE: This hoc will be replaced when Authorization hoc is implemented
const Authentication = WrappedComponent =>
  function Callback(props) {
    const user = useSelector(getUser);

    if (!user) return <Redirect to={routes.LOGIN.path} />;

    return <WrappedComponent {...props} />;
  };

export default Authentication;
