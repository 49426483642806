import React from 'react';
import styled from 'styled-components';

import { useRoot } from 'context/RootContext';
import Alert from 'components/Alerts/Alert';

function Alerts() {
  const { clearNotification, notifications } = useRoot();
  const handleAlertClose = id => clearNotification(id);

  return (
    notifications &&
    notifications.length > 0 && (
      <Container className="fixed-bottom">
        {notifications.map(a => (
          <Alert key={a.id} id={a.id} variant={a.variant} duration={a.duration} onClose={handleAlertClose}>
            {a.message}
          </Alert>
        ))}
      </Container>
    )
  );
}

export default Alerts;

const Container = styled.div`
  width: 100%;
  height: 48px;

  box-shadow: 0px 1px 1px 0 rgba(22, 29, 37, 0.1);
`;
