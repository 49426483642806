import React from 'react';
import styled from 'styled-components';

const HeaderText = props => <Wrapper {...props}>{props.children}</Wrapper>;

const Wrapper = styled.h4`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.44;
  color: var(--white);
`;

export default HeaderText;
