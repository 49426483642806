import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from 'components/Typography';
import { LOCALIZATION_PAGES, CURRENCY } from 'utils/constants';

const Price = ({ price, showTotal, discount, cart }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE_CART);
  const displayPrice = `${CURRENCY.BAM} ${Number(price).toFixed(2)}`;

  return (
    showTotal && (
      <Wrapper>
        {discount && cart && cart.discount && (
          <div className="d-flex justify-content-between">
            <Text18>{`${t('discount')} ${Number.parseFloat(cart.discount.percentage).toFixed(2)}%`}</Text18>
            <Text>{`${CURRENCY.BAM} - ${Number(cart.discountedAmmount).toFixed(2)}`}</Text>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <Text18>{t('total')}</Text18>
          <Text>
            {discount && cart ? `${CURRENCY.BAM} ${Number(cart.totalWithDiscount).toFixed(2)}` : displayPrice}
          </Text>
        </div>
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 2.5rem 2.5rem 2.5rem;
  border-top: solid 1px RGBA(112, 112, 112, 0.25);
`;

const Text18 = styled(Text)`
  font-size: 18px;
`;

export default Price;
