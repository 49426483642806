import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { LOCALIZATION_PAGES } from 'utils/constants';
import { useToggle } from 'utils/hooks';

import { FilmPoster, ScreeningItem } from 'components';
import FilmDetails from './FilmDetails';
import FilmBasicInfo from './FilmBasicInfo';
import { Button } from 'components';

const FilmDataContainer = ({ film, filmScreenings, detailsFirst = false }) => {
  const { title = '', runtimeHumanReadable = '', filmProgrammes = [], poster = '' } = film ? film : {};
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);
  const [isOnDetails, toggleView] = useToggle(detailsFirst);
  return (
    <Row>
      <Col className="d-flex">
        <FilmWrapper>
          <FilmPoster poster={poster ? poster : null} size={'large'} />
          {!isOnDetails && (
            <div>
              <FilmBasicInfo title={title} runtime={runtimeHumanReadable} filmProgrammes={filmProgrammes} />
              <Button block={true} color="default" onClick={toggleView}>
                {t('view_details')}
              </Button>
            </div>
          )}
        </FilmWrapper>
      </Col>
      <Col hidden-md-up={12}>
        <div className="w-100">
          {isOnDetails ? (
            <FilmDetails film={film} toggleView={toggleView} />
          ) : (
            <div>
              {filmScreenings && filmScreenings.data ? (
                filmScreenings.data.map((screening, index) => (
                  <ScreeningItem key={`${screening.id}-${index}`} screening={screening} />
                ))
              ) : (
                <div>{tCommon('no_entries')}</div>
              )}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

const FilmWrapper = styled.div`
  margin-right: 2rem;
`;

export default FilmDataContainer;
