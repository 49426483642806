import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import SeatsLegend from 'components/Purchase/ReservedPurchase/SeatsLegend';
import ScreeningInfo from 'components/Purchase/ReservedPurchase/ScreeningInfo';
import AccreditationInfo from 'components/Purchase/ReservedPurchase/AccreditationInfo';
import Loader from 'components/Loader/Loader';
import { Text } from 'components/Typography';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { LOCALIZATION_PAGES, TICKET_TYPE_SELECTORS } from 'utils/constants';

import TransformableSeatsLayout from 'components/Purchase/ReservedPurchase/TransformableSeatsLayout';

const ReservedPurchaseDisplay = memo(
  ({ selector }) => {
    const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
    const {
      isLoading,
      ticketTypeSelectorId,
      layout,
      accreditation,
      selectedAccCount,
      remaining,
    } = useReservedPurchaseContext();

    return isLoading ? (
      <div className="my-5">
        <Loader />
      </div>
    ) : (
      selector === ticketTypeSelectorId && (
        <>
          <ScreeningInfo />
          <Wrapper>
            {layout && layout.length > 0 ? (
              <>
                <TransformableSeatsLayout layout={layout} />
                <LegendWrapper>
                  {selector === TICKET_TYPE_SELECTORS.ACCREDITATION && <AccreditationInfo />}
                  <SeatsLegend />
                </LegendWrapper>
              </>
            ) : (
              <span className="flex-grow-1 text-center">{t('no_layout')}</span>
            )}
          </Wrapper>
          {selector === TICKET_TYPE_SELECTORS.ACCREDITATION && accreditation && layout && layout.length > 0 && (
            <SelectedAccrMsg
              dangerouslySetInnerHTML={{
                __html: t('selected_accreditations', {
                  selected: selectedAccCount,
                  remaining,
                }),
              }}
            />
          )}
        </>
      )
    );
  },
  (prevVal, nextVal) => prevVal.selector === nextVal.selector
);

const Wrapper = styled.div`
  display: flex;
  margin: 0 7rem 3rem 7rem;
`;

const LegendWrapper = styled.div`
  margin-left: 5rem;
`;

const SelectedAccrMsg = styled(Text)`
  padding-left: 1.5rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-weight: 300;
`;
export default ReservedPurchaseDisplay;
