import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from 'components/Button/Button';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from 'components/Modal';

const Modal = ({
  isOpen = false,
  onConfirm,
  onDecline,
  title,
  confirmText,
  declineText,
  loading,
  children,
  justifyFooter,
  customSize,
  buttonDisabled,
  hideCancelButton,
  useToggle = true,
  size = 'xl',
  ...rest
}) => {
  return (
    <ModalWrapper isOpen={isOpen} toggle={() => useToggle && onDecline} customSize={customSize} size={size} {...rest}>
      <ModalHeader toggle={onDecline}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter justifyfooter={justifyFooter}>
        {onConfirm && (
          <CustomButton color="primary" loading={loading} disabled={buttonDisabled} onClick={onConfirm}>
            {confirmText}
          </CustomButton>
        )}
        {!hideCancelButton && (
          <CustomButton color="default" onClick={onDecline}>
            {declineText}
          </CustomButton>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(({ customSize, children, ...rest }) => (
  <ModalContainer {...rest}>{children}</ModalContainer>
))`
  ${({ customSize }) =>
    customSize &&
    css`
      max-width: ${customSize}px;
    `}
`;

const CustomButton = styled(Button)`
  min-width: 160px;
`;

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  declineText: PropTypes.string,
};

export default Modal;
