import React from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';

const CheckBoxList = ({ listItems, onChange, checked }) => {
  return (
    <>
      {listItems.map((listItem, index) => (
        <SpacedCheckBox
          key={index}
          label={listItem.name}
          id={listItem.id}
          type="checkbox"
          name={`cb${listItem.id}`}
          checked={checked(listItem.id)}
          onChange={e => onChange(e, listItem.id)}
        />
      ))}
    </>
  );
};

const SpacedCheckBox = styled(Checkbox)`
  padding-right: 50px;
`;

export default CheckBoxList;
