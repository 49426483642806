import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { API_ENDPOINTS } from 'utils/constants';

const useScreeningSeats = id => {
  const path = `${API_ENDPOINTS.SCREENINGS}/${id}/availability`;
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
  const { showNotification } = useRoot();
  const [{ result: seats, loading, error, setResult: setSeats }, getSeats] = useApi(path, { initialFetch: false });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('failed_to_fetch_screening_seats'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ seats, loading }, getSeats, setSeats];
};

export default useScreeningSeats;
