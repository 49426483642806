import React from 'react';
import { useTranslation } from 'react-i18next';

import ReservedPurchaseDisplay from 'components/Purchase/ReservedPurchase/ReservedPurchaseDisplay';
import ValidateAccreditation from 'components/Purchase/ReservedPurchase/ValidateAccreditation';
import { Tab, Tabs, TabCustomLabel } from 'components/Tabs';
import { PurchaseModal } from 'components/Modal';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { TICKET_TYPE_SELECTORS, LOCALIZATION_PAGES, TAB_TYPES } from 'utils/constants';

const regularSaleIcon = `${process.env.PUBLIC_URL}/assets/icons/money.svg`;
const accreditationsIcon = `${process.env.PUBLIC_URL}/assets/icons/contact.svg`;
const gratisIcon = `${process.env.PUBLIC_URL}/assets/icons/gift.svg`;

const ReservedPurchase = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
  const {
    isModalOpen,
    unSelectScreening,
    setTicketTypeSelectorId,
    onSubmit,
    addingSeatsLoading,
    selectorCapacities,
    layout,
    isLoading,
    accreditation,
    ticketType,
    selectedSeats,
  } = useReservedPurchaseContext();

  const count = selectedSeats.length > 0 && !isLoading ? selectedSeats.length : 0;

  const handleCapacity = selector => {
    if (isLoading) return [];
    if (!selectorCapacities) return [0, 0];

    let capacity = selectorCapacities.find(c => c.ticketTypeSelectorId === selector);

    if (capacity) {
      return [capacity.totalNonAvailable, capacity.totalAvailable + capacity.totalNonAvailable];
    } else return [0, 0];
  };

  const getAvailability = selector => {
    if (isLoading) return false;

    if (!selectorCapacities) return true;

    let capacity = selectorCapacities.find(c => c.ticketTypeSelectorId === selector);

    if (capacity) {
      let total = parseInt(capacity.totalAvailable) + parseInt(capacity.totalNonAvailable);
      if (total) return false;
      return true;
    } else return true;
  };

  return (
    <PurchaseModal
      centered
      loading={addingSeatsLoading}
      isOpen={isModalOpen}
      onConfirm={onSubmit}
      onDecline={unSelectScreening}
      confirmText={t('add_tickets', { count })}
      declineText={t('cancel')}
      confirmDisabled={layout.length < 1 || isLoading}
      customSize="fit-content"
    >
      <Tabs type={TAB_TYPES.PURCHASE}>
        <Tab
          label={
            <TabCustomLabel
              icon={regularSaleIcon}
              text={t('regular_sale')}
              value={handleCapacity(TICKET_TYPE_SELECTORS.REGULAR)}
              onClick={() => setTicketTypeSelectorId(TICKET_TYPE_SELECTORS.REGULAR)}
            />
          }
          disabled={getAvailability(TICKET_TYPE_SELECTORS.REGULAR)}
        >
          <ReservedPurchaseDisplay selector={TICKET_TYPE_SELECTORS.REGULAR} />
        </Tab>

        <Tab
          label={
            <TabCustomLabel
              icon={accreditationsIcon}
              text={t('accreditations')}
              value={handleCapacity(TICKET_TYPE_SELECTORS.ACCREDITATION)}
              onClick={() => setTicketTypeSelectorId(TICKET_TYPE_SELECTORS.ACCREDITATION)}
            />
          }
          disabled={getAvailability(TICKET_TYPE_SELECTORS.ACCREDITATION)}
        >
          {accreditation && ticketType ? (
            <ReservedPurchaseDisplay selector={TICKET_TYPE_SELECTORS.ACCREDITATION} />
          ) : (
            <ValidateAccreditation />
          )}
        </Tab>
        <Tab
          label={
            <TabCustomLabel
              icon={gratisIcon}
              text={t('gratis')}
              value={handleCapacity(TICKET_TYPE_SELECTORS.GRATIS)}
              onClick={() => setTicketTypeSelectorId(TICKET_TYPE_SELECTORS.GRATIS)}
            />
          }
          disabled={getAvailability(TICKET_TYPE_SELECTORS.GRATIS)}
        >
          <ReservedPurchaseDisplay selector={TICKET_TYPE_SELECTORS.GRATIS} />
        </Tab>
      </Tabs>
    </PurchaseModal>
  );
};

export default ReservedPurchase;
