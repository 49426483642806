import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import Payment from '../Payment/Payment';
import ReservationModal from '../Reservations/ReservationModal';

import routes, { generateLink } from 'utils/routes';
import { getShift } from 'redux/reducers/sessionReducer';
import { useToggle } from 'utils/hooks';
import { LOCALIZATION_PAGES, USER_ROLES, SHIFT_MODES } from 'utils/constants';
import { usePurchaseContext } from 'context/PurchaseContext';
import Authorize from 'components/Authorization/Authorize';
import permissions from 'utils/permissions';

const AdminButtons = ({ user, cart, setIsTicketCancelation, setShowReservations, shiftStatus }) => {
  const history = useHistory();
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE_CART);

  const goToPrintLog = () => history.push(generateLink(routes.PRINT_LOG));

  return (
    user?.role !== USER_ROLES.REGULAR &&
    (!cart || cart?.length < 1) && (
      <ButtonWrapper>
        {shiftStatus && (
          <Authorize forPermissions={['all', permissions.CanCancelTickets]} renderIfUnauthorized={true}>
            <Button block color="default" className="w-100" onClick={setIsTicketCancelation}>
              {t('cancel_tickets')}
            </Button>
          </Authorize>
        )}
        <Authorize
          forPermissions={['any', permissions.CanViewAllReservations, permissions.CanViewPersonalReservations]}
          renderIfUnauthorized={true}
        >
          <Button block color="default" className="w-100" onClick={setShowReservations}>
            {t('reservations')}
          </Button>
        </Authorize>
        <Authorize
          forPermissions={['any', permissions.CanViewAllPrintLogs, permissions.CanViewPersonalPrintLogs]}
          renderIfUnauthorized={true}
        >
          <Button block color="default" className="w-100" onClick={goToPrintLog}>
            {t('print_log')}
          </Button>
        </Authorize>
      </ButtonWrapper>
    )
  );
};

const PurchaseButtons = ({
  cart,
  showReservation = true,
  enableReservationPayment = false,
  disablePurchaseButton = false,
  backToReservations = null,
  discount,
}) => {
  const [isPaymentOpen, togglePayment] = useToggle(false);
  const [isReservationOpen, toggleReservation] = useToggle(false);
  const shift = useSelector(getShift);
  const { clearCartLocal } = usePurchaseContext();

  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE_CART);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);

  return (
    cart?.cartScreenings.length > 0 && (
      <ButtonWrapper>
        <Authorize
          forPermissions={
            backToReservations
              ? ['all', permissions.CanUpdateReservations, permissions.CanPrintReservations]
              : ['any', permissions.CanUseInvoice, permissions.CanUseCard, permissions.CanUseCash]
          }
          renderIfUnauthorized={true}
        >
          <Button
            block
            className="w-100"
            onClick={togglePayment}
            disabled={
              !shift ||
              shift !== SHIFT_MODES.STARTED ||
              disablePurchaseButton ||
              (backToReservations && !enableReservationPayment)
            }
          >
            {!shift || shift !== SHIFT_MODES.STARTED ? t('start_shift_to_proceed') : tCommon('payment')}
          </Button>
        </Authorize>
        {showReservation && (
          <Authorize forPermissions={['all', permissions.CanCreateReservations]} renderIfUnauthorized={true}>
            <Button
              block
              color="default"
              className="w-100"
              onClick={toggleReservation}
              disabled={!shift || shift !== SHIFT_MODES.STARTED || !cart?.allowReservation}
            >
              {tCommon('reservation')}
            </Button>
          </Authorize>
        )}

        <Payment
          isOpen={isPaymentOpen}
          togglePayment={togglePayment}
          cartId={!showReservation ? cart.id : ''}
          backToReservations={backToReservations}
          discount={discount}
          skipPaymentSelection={cart?.total === 0}
        />
        <ReservationModal
          isOpen={isReservationOpen}
          toggleReservation={toggleReservation}
          cartId={cart?.id}
          clearCartLocal={clearCartLocal}
        />
      </ButtonWrapper>
    )
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem;
`;

export { AdminButtons, PurchaseButtons };
