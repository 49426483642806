import React from 'react';
import { Row, Col } from 'reactstrap';
import { LinkButton } from 'components';
import { FiArrowLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { LOCALIZATION_PAGES } from 'utils/constants';

const ViewHeader = ({ linkBack }) => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  return (
    <Row className="mb-4">
      <Col>
        <LinkButton to={linkBack}>
          <FiArrowLeft />
          {tCommon('back')}
        </LinkButton>
      </Col>
    </Row>
  );
};

export default ViewHeader;
