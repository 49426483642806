import React from 'react';
import { Text } from 'components';
import styled from 'styled-components';
import { usePurchaseContext } from 'context/PurchaseContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const AccreditationLimitMessage = ({ accreditation, values }) => {
  const { ticketsUsedForScreening, ticketsUsedForDay, ticketsLimitDaily, ticketsLimitPerScreening } = accreditation;

  const countRemainingAccreditationTickets = () => {
    const remainingDaily = ticketsLimitDaily - ticketsUsedForDay;
    const remainingScreening = ticketsLimitPerScreening - ticketsUsedForScreening;

    const lesserValue = Math.min(remainingDaily, remainingScreening);
    return lesserValue;
  };

  const countAccreditationTickets = values => {
    let count = 0;
    if (values && values.ticketInserts)
      values.ticketInserts.forEach(insert => {
        if (insert && insert.ticketSeatTypes)
          insert.ticketSeatTypes.forEach(ticketSeatType => {
            if (insert.ticketTypeSelectorId === -1) count += ticketSeatType.quantity;
          });
      });
    return count;
  };

  const countCartTicketsForCurrentScreening = () => {
    let count = 0;
    if (cart && cart.cartScreenings)
      cart.cartScreenings
        .filter(
          x =>
            x.screeningId === selectedScreening.id &&
            moment(x.screeningDateTime).isSame(selectedScreening.startTime, 'day')
        )
        .forEach(screening => {
          screening.seatTypes.forEach(seatType => {
            if (seatType.ticketTypeSelectorId === -1) {
              count += seatType.count;
            }
          });
        });
    return count;
  };

  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { selectedScreening, cart } = usePurchaseContext();

  const cartTicketsForCurrentScreening = countCartTicketsForCurrentScreening();
  const accreditationTickets = countAccreditationTickets(values);
  const remainingTickets = countRemainingAccreditationTickets();

  const remainingForScreening = remainingTickets - accreditationTickets + cartTicketsForCurrentScreening;
  return (
    <>
      {remainingForScreening > 0 && (
        <IndicatorMessage>
          {t('note_selected_accreditation_tickets', {
            screening: accreditationTickets,
          })}
          {t('note_able_to_select_more_count', { count: remainingForScreening })}
        </IndicatorMessage>
      )}
      {remainingForScreening === 0 && (
        <IndicatorMessage className="text-warning">
          {t('note_selected_accreditation_tickets', {
            screening: accreditationTickets,
          })}
          {t('note_not_able_to_select_more')}
        </IndicatorMessage>
      )}
      {remainingForScreening < 0 && (
        <IndicatorMessage className="text-lipstick-red">
          {t('note_selected_accreditation_tickets', {
            screening: accreditationTickets,
          })}

          {t('note_exceeded_limit', { count: accreditationTickets - remainingTickets })}
        </IndicatorMessage>
      )}
    </>
  );
};

const IndicatorMessage = styled(Text)`
  margin-top: 4rem;
  margin-bottom: 0;
  line-height: 1.75;
`;

export default AccreditationLimitMessage;
