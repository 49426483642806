import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components';
import { LOCALIZATION_PAGES, PAYMENT_TABS, PAYMENT_TYPE } from 'utils/constants';
import { useTickets, useCartTicketsPayment } from 'utils/hooks/api';
import { PaymentModal } from '../Modal';
import { usePurchaseContext } from 'context/PurchaseContext';
import Authorize from 'components/Authorization/Authorize';
import permissions from 'utils/permissions';
import useCartTicketsWithUnavailableSeats from 'utils/hooks/api/useCartTicketsWithUnavailableSeats';

const cashUrl = `${process.env.PUBLIC_URL}/assets/icons/money.svg`;
const creditCardUrl = `${process.env.PUBLIC_URL}/assets/icons/pay.svg`;
const invoiceUrl = `${process.env.PUBLIC_URL}/assets/icons/invoice.svg`;
const validatingTicketUrl = `${process.env.PUBLIC_URL}/assets/icons/validating-ticket.svg`;

const Payment = ({
  isOpen,
  togglePayment,
  discount,
  cartId = null,
  backToReservations = null,
  skipPaymentSelection = false,
}) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const [{ tickets, loading: ticketsLoading }, getTickets] = useTickets(cartId);
  const [{ resultTicketsPayment }, ticketsPayment] = useCartTicketsPayment();
  const [clicked, setClicked] = useState(null);
  const [nav, setNav] = useState(skipPaymentSelection ? PAYMENT_TABS.PROCESSING_PAYMENT : PAYMENT_TABS.PAYMENT_METHOD);
  const [confirmText, setConfirmText] = useState(t('proceed'));
  const [cancelText, setCancelText] = useState(t('cancel'));
  const [printDisabledText] = useState(t('print_disabled'));
  const { clearCart, cart } = usePurchaseContext();
  const [
    { anyTicketsInCartWithSeatUnavailable },
    getAnyTicketsInCartWithSeatUnavailable,
  ] = useCartTicketsWithUnavailableSeats(cart.id);

  const onDecline = () => {
    if (nav === PAYMENT_TABS.PRINTING_TICKETS) clearCart();
    togglePayment();
    setTimeout(() => {
      setNav(skipPaymentSelection ? PAYMENT_TABS.PROCESSING_PAYMENT : PAYMENT_TABS.PAYMENT_METHOD);
      setConfirmText(t('proceed'));
    }, 300);
  };

  const onPaymentMethod = type => {
    setClicked(type);
  };

  const onProceed = () => {
    switch (nav) {
      case PAYMENT_TABS.PAYMENT_METHOD:
        setNav(PAYMENT_TABS.PROCESSING_PAYMENT);
        setConfirmText(t('print_tickets'));
        break;
      case PAYMENT_TABS.PROCESSING_PAYMENT:
        ticketsPayment({
          tickets: tickets.map(x => x.id),
          cartId: cartId,
          paymentType: skipPaymentSelection ? PAYMENT_TYPE.NONE : clicked,
          discountId: discount && discount.id,
        });
        setNav(PAYMENT_TABS.PRINTING_TICKETS);
        setCancelText(t('done'));
        break;
      default:
        setNav(PAYMENT_TABS.PAYMENT_METHOD);
        setConfirmText(t('proceed'));
        break;
    }
  };

  useEffect(() => {
    if (resultTicketsPayment) {
      if (cartId == null || cartId === '') {
        if (nav !== PAYMENT_TABS.PRINTING_TICKETS) clearCart();
      } else backToReservations();
    }
  }, [resultTicketsPayment, clearCart, cartId, backToReservations, nav]);

  useEffect(() => {
    if (cart) {
      getTickets();
    }
  }, [cart, getTickets]);

  useEffect(() => {
    if (cart && nav === PAYMENT_TABS.PROCESSING_PAYMENT) {
      getAnyTicketsInCartWithSeatUnavailable();
    }
  }, [cart, getAnyTicketsInCartWithSeatUnavailable, nav]);

  function getProcessingPaymentModalContent() {
    if (anyTicketsInCartWithSeatUnavailable) {
      return (
        <PaymentBody column>
          <PaymentProcessing>{t('print_disabled_title')}</PaymentProcessing>
          <ProcessingText>{t('print_disabled')}</ProcessingText>
          <Circle>
            <PaymentIcon src={creditCardUrl} />
          </Circle>
        </PaymentBody>
      );
    } else {
      return (
        <PaymentBody column>
          <PaymentProcessing>
            {skipPaymentSelection ? t('payment_not_needed') : t('processing_payment')}
          </PaymentProcessing>
          <ProcessingText>
            {skipPaymentSelection ? t('payment_not_needed_confirm') : t('payment_confirmation_text')}
          </ProcessingText>
          <Circle>
            <PaymentIcon src={creditCardUrl} />
          </Circle>
        </PaymentBody>
      );
    }
  }

  return (
    <PaymentModal
      centered
      isOpen={isOpen}
      onDecline={onDecline}
      title={anyTicketsInCartWithSeatUnavailable ? t('print_disabled_title') : t('payment_method_modal_title')}
      confirmDisabled={clicked == null}
      confirmText={confirmText}
      declineText={cancelText}
      printDisabledText={printDisabledText}
      onConfirm={() => onProceed()}
      isCustomHeader={nav !== PAYMENT_TABS.PAYMENT_METHOD}
      showDecline={nav !== PAYMENT_TABS.PRINTING_TICKETS}
      size="lg"
      nav={nav}
      tickets={tickets}
      printDisabled={anyTicketsInCartWithSeatUnavailable}
    >
      {ticketsLoading ? (
        <Loader />
      ) : (
        <>
          {nav === PAYMENT_TABS.PAYMENT_METHOD ? (
            <PaymentBody className="p-5">
              <Authorize forPermissions={['all', permissions.CanUseCash]} renderIfUnauthorized={true}>
                <IconWrapper
                  onClick={() => onPaymentMethod(PAYMENT_TYPE.CASH)}
                  className={clicked === PAYMENT_TYPE.CASH ? 'highlighted-border' : ''}
                >
                  <PaymentIcon src={cashUrl} />
                  <PaymentDescription>{t('cash')}</PaymentDescription>
                </IconWrapper>
              </Authorize>
              <Authorize forPermissions={['all', permissions.CanUseCard]} renderIfUnauthorized={true}>
                <IconWrapper
                  onClick={() => onPaymentMethod(PAYMENT_TYPE.CREDIT_CARD)}
                  className={clicked === PAYMENT_TYPE.CREDIT_CARD ? 'highlighted-border' : ''}
                >
                  <PaymentIcon src={creditCardUrl} />
                  <PaymentDescription>{t('credit_cart')}</PaymentDescription>
                </IconWrapper>
              </Authorize>
              <Authorize forPermissions={['all', permissions.CanUseInvoice]} renderIfUnauthorized={true}>
                <IconWrapper
                  onClick={() => onPaymentMethod(PAYMENT_TYPE.INVOICE)}
                  className={clicked === PAYMENT_TYPE.INVOICE ? 'highlighted-border' : ''}
                >
                  <PaymentIcon isInverted src={invoiceUrl} />
                  <PaymentDescription>{t('invoice')}</PaymentDescription>
                </IconWrapper>
              </Authorize>
            </PaymentBody>
          ) : (
            <>
              {nav === PAYMENT_TABS.PROCESSING_PAYMENT ? (
                getProcessingPaymentModalContent()
              ) : (
                <>
                  {nav === PAYMENT_TABS.PRINTING_TICKETS ? (
                    <PaymentBody column>
                      <PaymentProcessing>{t('print_loading')}</PaymentProcessing>
                      <ProcessingText>{t('print_completed_text')}</ProcessingText>
                      <Circle>
                        <PaymentIcon src={validatingTicketUrl} />
                      </Circle>
                    </PaymentBody>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      )}
    </PaymentModal>
  );
};

const PaymentIcon = styled.img`
  width: 68px;
  height: 68px;
  ${({ isInverted }) =>
    isInverted &&
    css`
      filter: invert(1);
    `}
`;

const PaymentDescription = styled.div`
  padding-top: 10px;
`;

const IconWrapper = styled.div`
  width: 242px;
  height: 242px;
  background-color: rgba(255, 255, 255, 0.06);
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const PaymentBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  width: 100%;
  text-align: center;
`;

const PaymentProcessing = styled.div`
  width: 243.1px;
  height: 26px;
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

const ProcessingText = styled.div`
  margin-top: 14px;
  height: 19px;
  opacity: 0.5;
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
`;

const Circle = styled.div`
  border: 1px solid #ffffff;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 62px;
  margin-bottom: 78px;
`;

export default Payment;
