import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LOCALIZATION_PAGES } from 'utils/constants';

const CrewTypeMembers = ({ crewMembers }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);

  return crewMembers && crewMembers.length > 0 ? (
    <>
      {crewMembers.map((crewMembers, index) => (
        <Wrapper key={index} className="d-flex">
          <DetailText>{t(crewMembers.crewTypeName)}: </DetailText>
          <DetailLabel>{crewMembers.crewMembersCsv.split(', ').join('\n')}</DetailLabel>
        </Wrapper>
      ))}
    </>
  ) : null;
};

const Wrapper = styled.div`
  margin-bottom: 12px;
`;

const DetailText = styled.div`
  font-size: 16px;
  line-height: 1.6;
  width: auto;
  min-width: 200px;
  color: #ffffff;
`;

const DetailLabel = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 1.6;
  width: auto;
  padding: 0 8px;
  white-space: pre-line;
  color: #9ea0a5;
`;

export default CrewTypeMembers;
