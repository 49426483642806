import React from 'react';
import { Progress as ReactstrapProgress } from 'reactstrap';
import styled from 'styled-components';

const Progress = ({ value, max, className }) => {
  let colorByValue;
  const percentage = Math.round((value / max) * 100);
  if (percentage <= 50) colorByValue = 'success';
  else if (percentage <= 75) colorByValue = 'warning';
  else if (percentage <= 100) colorByValue = 'danger';
  else if (percentage > 100) colorByValue = 'danger';
  else colorByValue = 'light';
  return <Wrapper max={max} value={value} color={colorByValue} className={className} />;
};

const Wrapper = styled(ReactstrapProgress)`
  max-width: 356px;
  width: 100%;
  height: 4px;
  margin: 0.2rem 0;
  background-color: rgba(255, 255, 255, 0.07);
`;

export default Progress;
