import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS } from 'utils/constants';

const useCustomerAccreditationNumber = (accreditationNumber, screeningId) => {
  const path = `${API_ENDPOINTS.CUSTOMER_ACCREDITATIONS}/${accreditationNumber}/availability?screeningId=${screeningId}`;

  const [
    { result: accreditation, loading, error, setResult: setAccreditation, setError: setAccreditationError },
    getAccreditation,
  ] = useApi(path, {
    initialFetch: false,
  });

  return [{ accreditation, loading, error }, getAccreditation, setAccreditation, setAccreditationError];
};

export default useCustomerAccreditationNumber;
