import React from 'react';
import { Title as DefaultTitle, Text } from 'components';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import InitialsAvatar from 'components/InitialsAvatar/InitialsAvatar';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const AccreditationInfoDistributed = ({ accreditation, handleRemoveAccreditation }) => {
  const {
    ticketsUsedForScreening,
    ticketsUsedForDay,
    customerFullName,
    ticketsLimitDaily,
    ticketsLimitPerScreening,
    barcode,
  } = accreditation;
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  return (
    <>
      <Row>
        <Col xs="2">
          <InitialsAvatar fullName={customerFullName} size={57} fontSize={22} />
        </Col>
        <Col xs="6">
          <Title className="mb-1">{customerFullName}</Title>
          <Text className="mt-0 mb-4">{barcode}</Text>
        </Col>
        <Col xs="4">
          <RemoveButton color="default" onClick={() => handleRemoveAccreditation()}>
            {tCommon('remove')}
          </RemoveButton>
        </Col>
      </Row>
      <Row>
        <Col xs={{ size: 10, offset: 2 }}>
          <Text>
            {t('daily_limit')} <strong>{ticketsLimitDaily}</strong>. {t('purchased')}: {ticketsUsedForDay}/
            {ticketsLimitDaily}.
          </Text>
          <Text>
            {t('screening_limit')} <strong>{ticketsLimitPerScreening}</strong>. {t('purchased')}:{' '}
            {ticketsUsedForScreening}/{ticketsLimitPerScreening}.
          </Text>
        </Col>
      </Row>
    </>
  );
};

const Title = styled(DefaultTitle)`
  font-size: 18px;
`;

const RemoveButton = styled(Button)`
  border-radius: 4px;
  height: 49px;
  margin-left: 0.5rem;
  width: 140px;
`;

export default AccreditationInfoDistributed;
