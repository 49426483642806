import React, { memo } from 'react';
import styled from 'styled-components';

import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import Authorize from 'components/Authorization/Authorize';

const Seat = memo(
  ({ seat, x, y }) => {
    const { handleSeat, seatTypes } = useReservedPurchaseContext();
    const color = seatTypes.find(s => s.seatType.id === seat?.seatTypeId)?.color;

    return (
      seat && (
        <>
          {!seat.isAvailable ? (
            <NotAvailableSeat />
          ) : (
            <Authorize forPermissions={['all'].concat(seat.permissions)} renderIfUnauthorized={true}>
              <Wrapper
                onClick={() => handleSeat(x, y)}
                isSelected={seat.isSelected}
                backgroundColor={color}
                isDisabled={seat.isDisabled}
                isSold={seat.isSold}
              >
                {seat.label}
              </Wrapper>
            </Authorize>
          )}
        </>
      )
    );
  },
  (prevVal, nextVal) => prevVal === nextVal
);

const NotAvailableSeat = styled.div`
  min-width: 40px;
  min-height: 40px;
  /* margin-right: 0.3rem; */
  margin-right: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 0.3rem; */
  margin-right: 1rem;
  min-width: 40px;
  min-height: 40px;
  text-transform: uppercase;
  background-color: ${({ isDisabled, backgroundColor, isSelected, isSold }) => {
    if (isDisabled) return '#d9d9d9';
    if (isSelected) return '#1e2025';
    if (isSold) return '#f63275';
    return backgroundColor;
  }};
  border: ${({ isSelected, isDisabled }) => (isSelected && !isDisabled ? '1px solid #f63275' : '')};
  color: ${({ isSelected, isDisabled, isSold }) => (isSelected || isSold || isDisabled ? '#ffffff' : 'black')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.2' : '1')};

  &&:hover {
    cursor: ${({ isDisabled, isSold, isSelected }) =>
      isDisabled || (isSold && !isSelected) ? 'not-allowed' : 'pointer'};
  }
`;

export default Seat;
