import React from 'react';
import styled, { css } from 'styled-components';
import { Navbar, Nav } from 'reactstrap';

import LanguageSelect from 'components/LanguageSelect/LanguageSelect';
import HeaderMenu from 'components/Layout/HeaderMenu';
import CurrentDateTime from 'components/CurrentDateTime/CurrentDateTime';

// temporary file, svg file not provided by SFF crew
const logoPath = `${process.env.PUBLIC_URL}/sff-logo-white@3x.png`;

const Header = ({ hasCart }) => {
  return (
    <Navigation hasCart={hasCart}>
      <SFFLogo>
        <img src={logoPath} alt="Sarajevo Film Festival" />
      </SFFLogo>
      <Nav className="mr-auto" />
      <CurrentDateTime />
      <LanguageSelect />
      <HeaderMenu />
    </Navigation>
  );
};

const Navigation = styled(Navbar)`
  z-index: 6;
  width: -webkit-fill-available;
  left: 0;
  ${({ hasCart }) =>
    hasCart
      ? css`
          position: sticky;
        `
      : css`
          position: relative;
        `}
  top: 0;
  height: 104px;
  background-color: var(--black);
  align-items: center;
  padding: 0.5rem 50px 0.5rem 60px;
`;

const SFFLogo = styled.div`
  display: flex;
  flex-wrap: wrap;

  img {
    width: 184px;
  }
`;

export default Header;
