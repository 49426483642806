import { useEffect } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const useUpdateReservationStatus = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const { showNotification } = useRoot();
  const path = `reservations/${id}/status`;

  const [
    { result: resultReservationStatus, loading: loadingReservationStatus, error: errorReservationStatus },
    updateReservationStatus,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorReservationStatus) {
      showNotification(
        {
          message: t('alert_status_update_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorReservationStatus, showNotification, t]);

  useEffect(() => {
    if (resultReservationStatus) {
      showNotification({
        message: t('alert_status_update_success'),
      });
    }
  }, [resultReservationStatus, showNotification, t]);

  return [{ resultReservationStatus, loadingReservationStatus }, updateReservationStatus];
};

export default useUpdateReservationStatus;
