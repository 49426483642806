import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';

const useAddDiscountToCart = discountCode => {
  const path = `/carts/discount?discountCode=${discountCode}`;

  const [{ result, loading, error, setError: setDiscountError }, addDiscount] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  return [{ result, loading, error }, addDiscount, setDiscountError];
};

export default useAddDiscountToCart;
