import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Label as DefaultLabel } from 'components';
import { Input } from 'components/Form';
import { LOCALIZATION_PAGES } from 'utils/constants';

const TicketInput = ({ handleChange, ticketNumber, ticketLoading, inputOnClick, validationMessage }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.TICKET_CANCELATION);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  return (
    <Wrapper>
      <Label>{t('label_placeholder')}</Label>
      <div className="d-flex align-items-center">
        <InputWrapper>
          <Input onChange={handleChange} value={ticketNumber} invalid={validationMessage && true} />
        </InputWrapper>
        <ConfirmButton
          color="default"
          onClick={inputOnClick}
          loading={ticketLoading}
          disabled={!ticketNumber || ticketNumber.length > 10}
        >
          {tCommon('confirm')}
        </ConfirmButton>
      </div>
      {validationMessage && <ErrorMsg>{validationMessage}</ErrorMsg>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  width: 400px;
  & input {
    background-color: rgba(0, 0, 0, 0.11);
    border: solid 1px rgba(226, 229, 237, 0.11);
    height: 49px;
    color: var(--white);
    &:focus {
      background-color: rgba(0, 0, 0, 0.11);
      border: solid 1px rgba(226, 229, 237, 0.11);
      color: var(--white);
    }
  }
  & .is-invalid {
    border-color: #ff004e;
    background: none;
  }
  & .is-invalid:focus {
    border-color: #ff004e;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.19);
  }
`;

const Label = styled(DefaultLabel)`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.21;
  text-transform: none;
  color: var(--white);
  margin-bottom: 0.8rem;
`;

const ConfirmButton = styled(Button)`
  border-radius: 4px;
  height: 49px;
  margin-left: 0.5rem;
  width: 140px;
`;

const ErrorMsg = styled.p`
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  color: #ff004e;
`;

export default TicketInput;
