import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import SeatsLayout from 'components/Purchase/ReservedPurchase/SeatsLayout';
import { TransformWrapper, TransformComponent as DefaultTransformComponent } from 'react-zoom-pan-pinch';
import Button from 'components/Button/Button';

import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { FiArrowRight, FiArrowLeft, FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

const TransformableSeatsLayout = ({ layout, wrapperSize = 400 }) => {
  const [autoScale, setAutoScale] = useState(1);

  useEffect(() => {
    if (layout) {
      const parentSize = wrapperSize;
      const seatSize = 56;
      const labelOffset = 1;
      const height = parentSize / ((layout.length + labelOffset) * seatSize);
      const width = parentSize / ((layout[0]?.length + labelOffset) * seatSize);
      if (height <= width) {
        if (height < 1) setAutoScale(height);
      } else {
        if (width < 1) setAutoScale(width);
      }
    }
  }, [layout, setAutoScale, wrapperSize]);

  return (
    <TransformWrapper doubleClick={{ disabled: true }}>
      {({ zoomIn, zoomOut, resetTransform, setPositionX, setPositionY, positionX, positionY, scale, ...rest }) => (
        <div className="d-flex">
          <TransformComponent size={wrapperSize}>
            <SeatsLayout scale={autoScale} />
          </TransformComponent>
          <LayoutOptions>
            <LayoutOption onClick={zoomIn}>
              <AiOutlineZoomIn />
            </LayoutOption>
            <LayoutOption onClick={zoomOut}>
              <AiOutlineZoomOut />
            </LayoutOption>
            <LayoutOption onClick={resetTransform}>
              <MdClose />
            </LayoutOption>
            <LayoutOption onClick={() => setPositionX(positionX + scale * autoScale * 56, 200, 'easeOut')}>
              <FiArrowLeft />
            </LayoutOption>
            <LayoutOption onClick={() => setPositionX(positionX - scale * autoScale * 56, 200, 'easeOut')}>
              <FiArrowRight />
            </LayoutOption>
            <LayoutOption onClick={() => setPositionY(positionY + scale * autoScale * 56, 200, 'easeOut')}>
              <FiArrowUp />
            </LayoutOption>
            <LayoutOption onClick={() => setPositionY(positionY - scale * autoScale * 56, 200, 'easeOut')}>
              <FiArrowDown />
            </LayoutOption>
          </LayoutOptions>
        </div>
      )}
    </TransformWrapper>
  );
};

const LayoutOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  > .btn:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const LayoutOption = styled(({ children, ...rest }) => (
  <Button color="default" {...rest}>
    {children}
  </Button>
))`
  width: 52px;
  height: 38px;
  padding: 0 !important;
  text-align: center;
  line-height: 38px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  svg {
    margin-bottom: 5px;
    margin-right: 0;
  }
`;

const TransformComponent = styled(DefaultTransformComponent)`
  ${({ size }) =>
    size
      ? css`
          height: ${size}px;
          width: ${size}px;
        `
      : css`
          height: 400px;
          width: 400px;
        `}
`;

export default TransformableSeatsLayout;
