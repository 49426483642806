import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';

import { useToggle } from 'utils/hooks';

const Select = ({ open, value, items, onSelect, ToggleElem, color, isUpper, ...rest }) => {
  const [isOpen, setIsOpen] = useToggle(open);

  return (
    <CustomDropdown isOpen={isOpen} toggle={setIsOpen} {...rest}>
      <ToggleElem />
      <CustomDropdownMenu color={color} right {...rest}>
        {items.map(item => (
          <CustomDropdownItem
            color={color}
            key={item.id}
            disabled={item.disabled === true}
            onClick={() => onSelect(item)}
            active={value && value.value === item.value}
          >
            <DropdownItemText color={color} isUpper={isUpper}>
              {item.NAME}
            </DropdownItemText>
          </CustomDropdownItem>
        ))}
      </CustomDropdownMenu>
    </CustomDropdown>
  );
};

const CustomDropdown = styled(Dropdown)`
  width: ${({ width }) => (width ? width : '')};
`;

const CustomDropdownMenu = styled(DropdownMenu)`
  max-height: 270px;
  overflow: auto;
  background-color: ${({ color }) => (color === 'light' ? '' : '#2a2c30')};
  min-width: 150px;
  padding: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.47);
  width: ${({ selectWidth }) => (selectWidth ? selectWidth : '')};
  button:first-child {
    border-bottom: 1px solid rgba(234, 237, 243, 0.13);
  }
  &&::-webkit-scrollbar {
    width: 5px;
    background: var(--darker);
  }
  &&::-webkit-scrollbar-thumb {
    background: var(--secondary);
  }
`;

const CustomDropdownItem = styled(DropdownItem)`
  height: 70px;
  :hover {
    background-color: ${({ color }) => (color === 'light' ? '' : '#3c4048')};
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
const DropdownItemText = styled.span`
  color: ${({ color }) => (color === 'light' ? '#2f3036' : 'var(--white)')};
  text-transform: ${({ isUpper }) => (isUpper ? 'uppercase' : 'none')};
  font-size: 16px;
  line-height: 1.57;
`;

Select.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.object,
  items: PropTypes.array,
  labelKey: PropTypes.string,
  onSelect: PropTypes.func,
  width: PropTypes.string,
};

export default Select;
