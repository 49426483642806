import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DefaultButton from 'components/Button/Button';

const LinkButton = ({ to, color = 'default', children, ...rest }) => {
  return (
    <Link to={to}>
      <Button color={color} {...rest}>
        {children}
      </Button>
    </Link>
  );
};

const Button = styled(DefaultButton)`
  padding: 0 40px;
`;

export default LinkButton;
