import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button/Button';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from 'components/Modal';

const FilterModal = ({
  isOpen = false,
  onConfirm,
  onDecline,
  title,
  confirmText,
  declineText,
  loading,
  children,
  justifyFooter,
  customSize,
  buttonDisabled,
  hideCancelButton,
  showResetButton = false,
  onReset,
  resetText,
  ...rest
}) => {
  return (
    <ModalWrapper isOpen={isOpen} toggle={onDecline} customSize={customSize} {...rest}>
      <ModalHeader toggle={onDecline}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter justifyfooter={justifyFooter}>
        <Left>
          {onConfirm && (
            <CustomButton color="primary" loading={loading} disabled={buttonDisabled} onClick={onConfirm}>
              {confirmText}
            </CustomButton>
          )}
          {!hideCancelButton && (
            <CustomButton color="default" onClick={onDecline}>
              {declineText}
            </CustomButton>
          )}
        </Left>
        <Right>
          {showResetButton && (
            <CustomButton color="default" onClick={onReset}>
              {resetText}
            </CustomButton>
          )}
        </Right>
      </ModalFooter>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(({ customSize, children, ...rest }) => (
  <ModalContainer {...rest}>{children}</ModalContainer>
))`
  max-width: ${({ customSize }) => (customSize ? `${customSize}px` : 'max-content')};
`;

const CustomButton = styled(Button)`
  min-width: 160px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

FilterModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  declineText: PropTypes.string,
};

export default FilterModal;
