import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Text } from 'components/Typography';
import { useScreening } from 'utils/hooks/api';
import { LOCALIZATION_PAGES, CURRENCY } from 'utils/constants';
import PurchaseButton from 'components/Purchase/PurchaseButton';
import Authorize from 'components/Authorization/Authorize';
import permissions from 'utils/permissions';

const CartItem = ({ item, allowCartEdit = true, reservation = null, shiftStatus }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { screeningId, screeningName, screeningCode, screeningDateTime, seatTypes } = item;

  const [{ resultScreening }, getScreening] = useScreening(screeningId);

  useEffect(() => {
    if (screeningId == null || screeningId !== resultScreening?.id) {
      getScreening();
    }
  }, [screeningId, getScreening, resultScreening]);

  const date = screeningDateTime ? moment(screeningDateTime).format('DD.MM.YYYY @ HH:mm') : null;

  return (
    <Wrapper>
      <div className="d-flex justify-content-between">
        <div>
          <Text18>{screeningName ? screeningName : ''}</Text18>
        </div>
        {allowCartEdit && (
          <Authorize
            forPermissions={reservation ? ['all', permissions.CanUpdateReservations] : ['all', permissions.CanUseCart]}
            renderIfUnauthorized={true}
          >
            <CustomButton color="default" screening={resultScreening} reservationEdit={true} shiftStatus={shiftStatus}>
              {t('change')}
            </CustomButton>
          </Authorize>
        )}
      </div>
      <div>
        <Detail>
          {screeningCode ? screeningCode : null} {' | '} {date ? date : ''}{' '}
        </Detail>
      </div>

      {seatTypes?.map((t, index) => {
        let ticketCount = `${t.count} X`;
        let name = t.seatTypeName;
        let price = `${CURRENCY.BAM} ${Number(t.price).toFixed(2)}`;
        const { accreditationNumber } = t;

        return (
          <Ticket key={`${t.name}-${t.id}-${index}`}>
            <TicketInfo>
              <div className="d-flex my-3">
                <Text className="mr-4">{ticketCount}</Text>
                <Text>{name}</Text>
              </div>
              <Text className="d-flex align-items-center">{price}</Text>
            </TicketInfo>
            {accreditationNumber && <AccreditationText>Accreditation ID: {accreditationNumber}</AccreditationText>}
          </Ticket>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2.5rem 2.5rem 1rem 2.5rem;
  border-bottom: solid 1px RGBA(112, 112, 112, 0.25);
  &&:last-child {
    border-bottom: none;
  }
`;

const Text18 = styled(Text)`
  margin-top: 0;
  font-size: 18px;
`;

const AccreditationText = styled(Text)`
  margin-left: 3rem;
  margin-top: 0;
  font-size: 14px;
`;

const Detail = styled.div`
  opacity: 0.5;
  font-size: 14px;
  margin: 0;
  line-height: 1.21;
  color: var(--white);
`;

const CustomButton = styled(PurchaseButton)`
  height: 29px;
  font-size: 12px;
  padding: 0 1rem;
  width: fit-content;
`;

const Ticket = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: solid 1px RGBA(112, 112, 112, 0.25);

  &&:last-child {
    border-bottom: none;
  }
`;

const TicketInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default CartItem;
