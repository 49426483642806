import React from 'react';
import styled from 'styled-components';

import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/bs';
import { getCurrentLanguageFromStorage } from 'utils/localization/localization';

// Returns the current time and queues re-renders every `refreshCycle` milliseconds (default: 100ms)
const CurrentDateTime = (refreshCycle = 1000) => {
  const [now, setNow] = useState(getFormatedDateAndTime());

  useEffect(() => {
    const intervalId = setInterval(() => setNow(getFormatedDateAndTime()), refreshCycle);

    return () => clearInterval(intervalId);
  }, [refreshCycle, setNow]);

  return <Container>{now}</Container>;
};

// moment: https://momentjs.com
const getFormatedDateAndTime = () => {
  let currentLanguage = getCurrentLanguageFromStorage();
  moment.locale(currentLanguage.ISO_CODE);

  let current = moment().format('dddd, DD.MM.YYYY HH:mm');
  return current;
};

const Container = styled.div`
  font-size: 16px;
  color: var(--white);
  font-weight: normal;
  margin: 0 30px;
`;

export default CurrentDateTime;
