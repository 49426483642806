import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

const useScreeningSaleCapacities = id => {
  const path = `screenings/${id}/sales/capacities`;
  const signalRPath = `${process.env.REACT_APP_API_URL}/screeningCapacityHub`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const [connection, setConnection] = useState(null);
  const { showNotification } = useRoot();
  const [
    { result: resultSaleCapacities, loading: loadingSaleCapacities, error, setResult },
    getScreeningSaleCapacities,
  ] = useApi(path);

  useEffect(() => {
    if (error && error.status !== 404) {
      showNotification(
        {
          message: t('alert_screening_sale_capacities_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(signalRPath, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, [signalRPath]);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        connection.on('ScreeningCapacityChanged', message => {
          if (parseInt(message.screeningId) === parseInt(id)) {
            setResult(message);
          }
        });
      });
      return () => {
        connection.stop('ScreeningCapacityChanged');
      };
    }
  }, [connection, setResult, id]);

  return [{ resultSaleCapacities, loadingSaleCapacities, error }, getScreeningSaleCapacities];
};

export default useScreeningSaleCapacities;
