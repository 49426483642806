import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import CartItem from 'components/PurchaseCart/CartItem';
import Price from 'components/PurchaseCart/Price';
import Loader from 'components/Loader/Loader';
import ActionsDropdown from './ActionsDropdown';
import { usePurchaseContext } from 'context/PurchaseContext';
import { Text } from 'components/Typography';
import { PurchaseButtons } from 'components/PurchaseCart/CartButtons';
import { useUpdateReservationStatus } from 'utils/hooks/api';
import { LOCALIZATION_PAGES, RESERVATION_STATUS, RESERVATION_STATUS_VALUE } from 'utils/constants';
import permissions from 'utils/permissions';
import { isAllowed } from 'utils/helpers';
import { getUser } from 'redux/reducers/sessionReducer';

const ReservationDetails = ({ reservation, setReservation, setShowReservationDetails, shiftStatus }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const {
    cart,
    loadingUpdateCart,
    loadingCart,
    clearCartLocal,
    setReservationId,
    reservationId,
  } = usePurchaseContext();
  const [{ resultReservationStatus }, updateReservationStatus] = useUpdateReservationStatus(reservation?.id);
  const [enablePurchaseButton, setEnablePurchaseButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [tempStatus, setTempStatus] = useState(reservation?.status);
  let user = { role: 'admin' };
  const currentUser = useSelector(getUser);
  const canPrintReservation = isAllowed(['all', permissions.CanPrintReservations], { user: currentUser });

  useEffect(() => {
    if (resultReservationStatus) {
      setReservation(draft => {
        draft.status = tempStatus;
      });
    }

    if (reservation && reservation?.id && reservation?.id !== reservationId) {
      clearCartLocal();
      setReservationId(reservation?.id);
    }

    setEnablePurchaseButton(
      reservation?.status === RESERVATION_STATUS.APPROVED || reservation?.status === RESERVATION_STATUS.CUSTOM_APPROVED
    );
    setIsLoading(false);
  }, [
    resultReservationStatus,
    setReservation,
    tempStatus,
    reservation,
    setReservationId,
    reservationId,
    clearCartLocal,
    setIsLoading,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = () => {
    clearCartLocal();
    setShowReservationDetails();
    setReservation(draft => {
      draft.id = null;
    });
  };

  const getLabel = status => {
    switch (status) {
      case RESERVATION_STATUS.PENDING:
        return <span style={{ color: '#ffb200', fontSize: '14px' }}>{t('status_pending')}</span>;
      case RESERVATION_STATUS.APPROVED:
        return <span style={{ color: '#92e992', fontSize: '14px' }}>{t('status_approved')}</span>;
      case RESERVATION_STATUS.CUSTOM_APPROVED:
        return <span style={{ color: '#92e992', fontSize: '14px' }}>{t('status_custom_approved')}</span>;
      case RESERVATION_STATUS.DECLINED:
        return <span style={{ color: '#ff004e', fontSize: '14px' }}>{t('status_declined')}</span>;
      case RESERVATION_STATUS.DELIVERED:
        return <span style={{ color: '#92e992', fontSize: '14px' }}>{t('status_delivered')}</span>;
      case RESERVATION_STATUS.EXPIRED:
        return <span style={{ color: '#ff004e', fontSize: '14px' }}>{t('status_expired')}</span>;
      case RESERVATION_STATUS.CANCELLED:
        return <span style={{ color: '#ff004e', fontSize: '14px' }}>{t('status_cancelled')}</span>;
      default:
        return null;
    }
  };

  const updateStatus = async status => {
    switch (status) {
      case RESERVATION_STATUS.APPROVED: {
        await updateReservationStatus({ status: RESERVATION_STATUS_VALUE.APPROVED });
        setEnablePurchaseButton(true);
        setTempStatus(RESERVATION_STATUS.APPROVED);
        break;
      }
      case RESERVATION_STATUS.DECLINED: {
        await updateReservationStatus({ status: RESERVATION_STATUS_VALUE.DECLINED });
        setEnablePurchaseButton(false);
        setTempStatus(RESERVATION_STATUS.DECLINED);
        break;
      }
      case RESERVATION_STATUS.CANCELLED: {
        await updateReservationStatus({ status: RESERVATION_STATUS_VALUE.CANCELLED });
        setEnablePurchaseButton(false);
        setTempStatus(RESERVATION_STATUS.CANCELLED);
        break;
      }
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <ReservationHeaderWrapper>
        <Left>
          <BackArrow onClick={goBack} />
          <Title>
            <Text16>{reservation?.title}</Text16>
            <Text>{getLabel(reservation?.status)}</Text>
          </Title>
        </Left>
        <Right>
          <ActionsDropdown status={reservation?.status} updateStatus={updateStatus} />
        </Right>
      </ReservationHeaderWrapper>
      {isLoading || loadingCart || loadingUpdateCart ? (
        <div className="align-self-center" style={{ height: '100vh' }}>
          <Loader />
        </div>
      ) : (
        <>
          <TicketsWrapper className="flex-grow-1">
            {cart?.cartScreenings?.map((i, index) => (
              <CartItem
                key={`${i.screeningCode}.${index}`}
                item={i}
                allowCartEdit={reservation?.status === RESERVATION_STATUS.PENDING}
                reservation={true}
                shiftStatus={shiftStatus}
              />
            ))}
          </TicketsWrapper>
        </>
      )}
      <div>
        <Price price={cart?.total} showTotal={cart ? true : false} cart={cart} discount={cart?.discount} />

        <PurchaseButtons
          user={user}
          cart={cart}
          showReservation={false}
          enableReservationPayment={
            reservation.status === RESERVATION_STATUS.APPROVED ||
            reservation.status === RESERVATION_STATUS.CUSTOM_APPROVED
          }
          backToReservations={goBack}
          disablePurchaseButton={!enablePurchaseButton && !canPrintReservation}
          discount={cart?.discount}
        />

        <FooterLabel>{t('footer')}</FooterLabel>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  right: 0px;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: right;
  width: 450px;
  min-height: 100vh;
  padding: 0;
  box-shadow: 5px 8px 18px 0 rgba(0, 0, 0, 0.7);
`;
const ReservationHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 3rem 1.5rem 3rem;
`;

const Text16 = styled(Text)`
  font-size: 16px;
`;

const BackArrow = styled(BsArrowLeft)`
  font-size: 22px;
  margin-top: 0.9em;

  &:hover {
    cursor: pointer;
  }
`;

const TicketsWrapper = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 60px - 270px);

  // scrollbar width
  ::-webkit-scrollbar {
    width: 6px;
  }

  // scrollbar Handle color
  ::-webkit-scrollbar-thumb {
    background: #676767;
  }

  // scrollbar Handle color on hover
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const FooterLabel = styled.p`
  margin-top: 1.5rem;
  opacity: 0.2;
  font-size: 14px;
  line-height: 1.36;
  text-align: center;
  color: var(--white);
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const Right = styled(Left)`
  justify-content: right;
`;

const Title = styled.div`
  padding-left: 1em;
  max-width: 220px;
`;

export default ReservationDetails;
