import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Modal as ReactstrapModal, ModalFooter } from 'reactstrap';

import Button from 'components/Button/Button';

const PurchaseModal = ({
  isOpen = false,
  onConfirm = () => {},
  onDecline = () => {},
  confirmText,
  declineText,
  loading,
  children,
  confirmDisabled = false,
  customSize,
  ...rest
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onDecline} customSize={customSize} {...rest}>
      {children}
      <Footer>
        <CustomButton color="primary" loading={loading} onClick={onConfirm} disabled={confirmDisabled}>
          {confirmText}
        </CustomButton>
        <Button color="default" onClick={onDecline}>
          {declineText}
        </Button>
      </Footer>
    </Modal>
  );
};

const Footer = styled(ModalFooter)`
  justify-content: flex-start;
  padding: 1rem 1.3rem;
  border-top: 0;
`;

const Modal = styled(({ customSize, children, ...rest }) => <ReactstrapModal {...rest}>{children}</ReactstrapModal>)`
  color: var(--white) !important;

  &.modal-dialog {
    ${({ customSize }) => {
      switch (customSize) {
        case 'fit-content':
          return css`
            max-width: fit-content;
          `;
        case 'md':
          return css`
            max-width: 600px;
          `;
        default:
          return 'max-width: max-content;';
      }
    }}
  }
  .modal-content {
    background-color: #2e3034;
  }
`;

const CustomButton = styled(Button)`
  min-width: 160px;
`;

PurchaseModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  declineText: PropTypes.string,
};

export default PurchaseModal;
