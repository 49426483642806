import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { usePurchaseContext } from 'context/PurchaseContext';
import { useRoot } from 'context/RootContext';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { LOCALIZATION_PAGES, SEATING_TYPES, ALERT_VARIANTS } from 'utils/constants';

const PurchaseButton = ({ disabled, screening, reservationEdit = false, children, shiftStatus = true, ...rest }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { openCart: openCartDist, reservationId } = usePurchaseContext();
  const { onSelectScreening } = useReservedPurchaseContext();
  const { showNotification } = useRoot();

  const openCart = screening => {
    if (reservationId && !reservationEdit) {
      showNotification(
        {
          message: t('alert_reservation_processing_in_progress'),
        },
        ALERT_VARIANTS.DANGER
      );
    } else {
      if (screening?.seatingType === SEATING_TYPES.RESERVED) onSelectScreening(screening);
      if (screening?.seatingType === SEATING_TYPES.DISTRIBUTION) openCartDist(screening);
    }

    if (!screening?.seatingType) {
      showNotification(
        {
          message: t('alert_no_seating_type'),
        },
        ALERT_VARIANTS.INFO
      );
    }
  };

  return (
    <Button block disabled={disabled || !shiftStatus} onClick={() => openCart(screening)} {...rest}>
      {children}
    </Button>
  );
};

export default PurchaseButton;
