import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import routes from 'utils/routes';

const useSeatAvailability = id => {
  const path = `${routes.SCREENINGS.path}/${id}/availability`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);

  const { showNotification } = useRoot();
  const [
    { result: seatAvailability, loading: loadingAvailability, error: errorAvailability },
    getSeatAvailability,
  ] = useApi(path, { initialFetch: false });

  useEffect(() => {
    if (errorAvailability) {
      showNotification(
        {
          message: t('alert_seat_availability_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorAvailability, showNotification, t]);

  return [{ seatAvailability, loadingAvailability, errorAvailability }, getSeatAvailability];
};

export default useSeatAvailability;
