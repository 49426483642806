import { object, string, ref } from 'yup';

const CHANGE_PASSWORD_SCHEMA = object().shape({
  previousPassword: string()
    .min('8', 'change_password:password_lenght_warning')
    .required('change_password:required_old_password'),
  proposedPassword: string()
    .min('8', 'change_password:password_lenght_warning')
    .matches(/[0-9]/, 'change_password:number_warning')
    .matches(/[a-z]/, 'change_password:lowercase_warning')
    .matches(/[A-Z]/, 'change_password:uppercase_warning')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'change_password:special_char_warning')
    .required('change_password:required_new_password'),
  confirmNewPassword: string()
    .oneOf([ref('proposedPassword'), null], 'change_password:required_match_password')
    .required('change_password:required_confirmed_password'),
});

export default CHANGE_PASSWORD_SCHEMA;
