import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { sessionReducer, uiReducer } from 'redux/reducers';
import notificationMiddleware from 'redux/customMiddlewares/notificationMiddleware';

import { RootProvider } from 'context/RootContext';
import Root from 'components/Root/Root';
import 'utils/localization/i18n';
import CustomBrowserRouter from 'utils/customHistory';
import GlobalStyle from 'globalStyle';
import { Loader } from 'components';

const rootReducer = combineReducers({
  session: sessionReducer,
  ui: uiReducer,
});

const middlewares = [thunkMiddleware, notificationMiddleware];

// use logger middleware only while developing
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const getStore = () => {
  return store;
};

function App() {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <RootProvider>
        <Suspense fallback={<Loader />}>
          <CustomBrowserRouter>
            <Root />
          </CustomBrowserRouter>
        </Suspense>
      </RootProvider>
    </Provider>
  );
}

export default App;
