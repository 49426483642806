import React from 'react';
import styled from 'styled-components';

import { Text } from 'components/Typography';

const TabCustomLabel = ({ icon, text, value, onClick }) => (
  <TabWrapper onClick={onClick}>
    <CustomImg src={icon} alt={text}></CustomImg>
    <CustomText>{text}</CustomText>
    {value ? <CustomText>{value.join('/')}</CustomText> : null}
  </TabWrapper>
);

const TabWrapper = styled.div`
  height: 100px;
  padding-top: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
`;

const CustomImg = styled.img`
  height: 33px;
`;

const CustomText = styled(Text)`
  font-size: 16px;
  line-height: 1.31;
  margin: 0;
`;

export default TabCustomLabel;
