import React from 'react';
import styled from 'styled-components';

import { Dropdown, DropdownToggleButton } from 'components/Dropdown';
import { Checkbox } from 'components/Form';

const MultiselectDropdown = ({ toggleText, items, isCheckedHandler, onChangeHandler, disabled, dropdownWith }) => {
  const Toggle = () => {
    return <DropdownToggleButton disabled={disabled}>{toggleText}</DropdownToggleButton>;
  };

  return (
    <Dropdown ToggleElem={Toggle} width={dropdownWith}>
      {items &&
        items.map(item => (
          <CheckboxItem key={item.id}>
            <Checkbox
              label={item.name}
              id={`checkbox-${item.id}-${item.name}`}
              type="checkbox"
              name="multipleChoiceFilter"
              checked={isCheckedHandler(item.id)}
              onChange={e => onChangeHandler(e, item.id)}
            />
          </CheckboxItem>
        ))}
    </Dropdown>
  );
};

const CheckboxItem = styled.div`
  padding: 0.25rem 1.5rem;

  &:first-child {
    padding-top: 0.25rem;
  }
  &:last-child {
    padding-bottom: 0.25rem;
  }
  & > div {
    margin-bottom: 0;
  }
`;

export default MultiselectDropdown;
