import React from 'react';
import styled, { css } from 'styled-components';
import { Row } from 'reactstrap';

import Seat from 'components/Purchase/ReservedPurchase/Seat';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';

const Seats = ({ scale, ...rest }) => {
  const { layout, colLabels, rowLabels } = useReservedPurchaseContext();

  return (
    <Wrapper scale={scale} {...rest}>
      <Inner scale={scale}>
        <SeatRow className="flex-nowrap">
          {colLabels.map(cl => {
            return (
              <Cell key={cl} className="d-flex align-items-center justify-content-center flex-shrink-0">
                {cl}
              </Cell>
            );
          })}
        </SeatRow>

        {layout.map((row, i) => {
          return (
            <React.Fragment key={i}>
              <SeatRow className="flex-nowrap">
                <Cell className="d-flex align-items-center justify-content-center flex-shrink-0">{rowLabels[i]}</Cell>
                {row.map((cell, j) => {
                  return <Seat x={i} y={j} key={`${cell.row}.${cell.number}`} seat={cell} />;
                })}
              </SeatRow>
            </React.Fragment>
          );
        })}
      </Inner>
    </Wrapper>
  );
};

const Inner = styled.div`
  width: 100%;

  ${({ scale }) =>
    scale
      ? css`
          transform: scale(${scale});
          transform-origin: left top;
        `
      : css`
          margin: 1rem;
        `}
`;

const Wrapper = styled.div`
  text-align: center;
  max-height: 400px;
  max-width: 400px;
  ${({ scale }) =>
    scale
      ? css`
          overflow: hidden;
        `
      : css`
          overflow: auto;
          padding-right: 2rem;
          padding-left: 1rem;
        `}

  &&::-webkit-scrollbar {
    width: 5px;
    background: var(--darker);
  }
  &&::-webkit-scrollbar-thumb {
    background: var(--secondary);
  }
`;

const SeatRow = styled(Row)`
  margin-bottom: 1rem;
`;

const Cell = styled.div`
  min-width: 40px;
  min-height: 40px;
  color: white;
  /* margin-right: 5px; */
  /* margin-right: 0.3rem; */
  margin-right: 1rem;
  text-transform: uppercase;
`;

export default Seats;
