import React from 'react';
import styled from 'styled-components';
import { Label as ReacstrapLabel } from 'reactstrap';

const Label = props => (
  <Wrapper {...props} color={props.color}>
    {props.children}
  </Wrapper>
);

const Wrapper = styled(ReacstrapLabel)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--secondary);
  text-transform: uppercase;
`;

export default Label;
