import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';

const useCartTicketsPayment = values => {
  const path = '/carts/tickets/payment';
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { showNotification } = useRoot();
  const [
    { result: resultTicketsPayment, loading: loadingTicketsPayment, error: errorTicketsPayment },
    ticketsPayment,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
    data: values,
  });

  useEffect(() => {
    if (errorTicketsPayment) {
      showNotification(
        {
          message: t('alert_ticket_payment_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorTicketsPayment, showNotification, t]);

  useEffect(() => {
    if (resultTicketsPayment) {
      showNotification({
        message: t('alert_ticket_payment_success'),
      });
    }
  }, [resultTicketsPayment, showNotification, t]);

  return [{ resultTicketsPayment, loadingTicketsPayment, errorTicketsPayment }, ticketsPayment];
};

export default useCartTicketsPayment;
