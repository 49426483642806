import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';

import FilterModal from 'components/Modal/FilterModal';
import DatePickerModal from 'components/DatePicker/DatePickerModal';
import RequesterDropdown from './RequesterDropdown';
import { CheckBoxList } from 'components/Form';
import { Label, Button } from 'components';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { useUsers } from 'utils/hooks/api';
import { useToggle } from 'utils/hooks';

const ReservationFilterModal = ({ showFilterModal, onSubmit, onDecline, query, setQuery }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const [{ result: users, loading: loadingUsers }] = useUsers();
  const [onOpenDatePicker, toggleOnOpenDatePicker] = useToggle(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const allRequesters = { id: '', NAME: t('all_users'), label: 'all_users' };
  const [user, setUser] = useImmer(allRequesters);
  const buttonStartDate = startDate ? `${moment(startDate).format('DD.MM.YYYY')}` : t('date_from');
  const buttonEndDate = startDate ? `${moment(endDate).format('DD.MM.YYYY')}` : t('date_to');

  const statusList = [
    { id: 0, name: t('status_pending') },
    { id: 1, name: t('status_approved') },
    { id: 2, name: t('status_custom_approved') },
    { id: 3, name: t('status_declined') },
    { id: 4, name: t('status_delivered') },
    { id: 5, name: t('status_expired') },
    { id: 6, name: t('status_cancelled') },
  ];

  const dropdownUsersHandler = useCallback(users => {
    return (
      users &&
      users.data.length &&
      users.data.length > 0 &&
      users.data.map(u => {
        return {
          id: u.id,
          name: u.fullName,
        };
      })
    );
  }, []);

  const dropdownUsers = useMemo(() => dropdownUsersHandler(users), [dropdownUsersHandler, users]);

  const applyDateRange = (start, end) => {
    if (start && end) {
      setStartDate(moment(start).format('YYYY-MM-DD'));
      setEndDate(moment(end).format('YYYY-MM-DD'));
    } else {
      setStartDate(null);
      setEndDate(null);
    }

    toggleOnOpenDatePicker();
  };

  const applyFilters = () => {
    setQuery(draft => {
      draft.startDate = startDate ? startDate : '';
      draft.endDate = endDate ? endDate : '';
      draft.statuses = statuses ? statuses : [];
      draft.userId = user ? user.id : '';
    });

    onSubmit();
  };

  const statusOnChangeHandler = (e, id) => {
    if (e.target.checked) {
      const temp = [id, ...statuses];
      setStatuses(temp);
    } else {
      let del = [...statuses];
      const index = del.indexOf(id);
      del.splice(index, 1);
      setStatuses(del);
    }
  };

  const statusCheckedHandler = id => statuses.includes(id);

  const onReset = () => {
    setStartDate(null);
    setEndDate(null);
    setStatuses([]);
    setUser(() => allRequesters);
  };

  return (
    <FilterModal
      centered
      customSize="730"
      title={t('reservations_filter')}
      confirmText={tCommon('apply')}
      declineText={t('cancel')}
      loading={loadingUsers}
      isOpen={showFilterModal}
      buttonDisabled={false}
      onConfirm={applyFilters}
      onDecline={onDecline}
      showResetButton={true}
      onReset={onReset}
      resetText={t('filter_reset')}
    >
      <div>
        <div>
          <Label>{t('date')}</Label>
        </div>
        <InputWrapper>
          <DateButton color="default" onClick={toggleOnOpenDatePicker}>
            {buttonStartDate}
          </DateButton>
          <DateButton color="default" onClick={toggleOnOpenDatePicker}>
            {buttonEndDate}
          </DateButton>
        </InputWrapper>
        <div>
          <Label>{t('requester')}</Label>
        </div>
        <InputWrapper>
          <RequesterDropdown
            users={dropdownUsers || []}
            setUser={setUser}
            isLoading={loadingUsers}
            selected={user}
            style={{
              width: '680px',
              height: '55px',
              borderRadius: '4px',
              border: 'solid 1px rgba(226, 229, 237, 0.11)',
              backgroundColor: 'rgba(0, 0, 0, 0.11)',
              textAlign: 'left',
              textTransform: 'none',
            }}
          />
        </InputWrapper>
        <div>
          <Label>{t('statuses')}</Label>
        </div>
        <InputWrapperLeft>
          <CheckBoxList listItems={statusList} onChange={statusOnChangeHandler} checked={statusCheckedHandler} />
        </InputWrapperLeft>
      </div>
      <DatePickerModal
        isOpen={onOpenDatePicker}
        onConfirm={applyDateRange}
        onDecline={toggleOnOpenDatePicker}
        title={t('select_reservation_date')}
      />
    </FilterModal>
  );
};

const DateButton = styled(Button)`
  height: 55px;
  width: 330px;
  padding-left: 2rem;
  padding-right: 2.5rem;
  border-radius: 4px;
  border: solid 1px rgba(226, 229, 237, 0.11);
  background-color: rgba(0, 0, 0, 0.11);
  text-align: left;
  text-transform: none;

  &&:hover,
  &&:focus,
  &&:active {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white);
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const InputWrapperLeft = styled(InputWrapper)`
  justify-content: left;
`;

export default ReservationFilterModal;
