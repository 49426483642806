import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TiArrowRight, TiArrowLeft } from 'react-icons/ti';
import { Container, Row, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { getMonth, getYear } from 'utils/helpers';
import { LOCALIZATION_PAGES, DATEPICKER } from 'utils/constants';
import { useToggle } from 'utils/hooks';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import DatePickerWrapper from './DatePickerWrapper';

const DatePickerModal = ({ isOpen, onConfirm, onDecline, title }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //? Library workaround
  //? There is no method to revert back to current month after clicking today/reset buttons
  //? Setting updateOnChange to false after these events
  //? useEffect sets it to true to rerender the Datepicker
  //////////////////////////////////////////////////////
  const [updateOnChange, toggleChange] = useToggle(true);

  useEffect(() => {
    if (!updateOnChange) {
      toggleChange();
    }
  }, [toggleChange, updateOnChange]);
  //////////////////////////////////////////////////////
  //? Workaround code above this line

  const onRangeChange = date => {
    if (date instanceof Date && !startDate && !endDate) {
      setStartDate(date);
      setEndDate(date);
    } else if (date === DATEPICKER.today) {
      setStartDate(new Date());
      setEndDate(new Date());
      toggleChange();
    } else if (date === DATEPICKER.reset) {
      setStartDate(null);
      setEndDate(null);
      toggleChange();
    } else if (date.getTime() === startDate.getTime()) {
      setStartDate(date);
      setEndDate(date);
    } else if (date < startDate) {
      setStartDate(date);
      setEndDate(date);
    } else if (date > startDate && date < endDate) {
      setEndDate(date);
    } else if (date.getTime() === endDate.getTime()) {
      setStartDate(date);
      setEndDate(date);
    } else if (date > endDate) {
      setEndDate(date);
    }
  };

  const DatePickerHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    return (
      <div>
        <Header>
          <DateNav left="true" color="default" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <TiArrowLeft />
          </DateNav>
          <DisplayMonth>
            {getMonth(date)} {getYear(date)}
          </DisplayMonth>
          <DateNav right="true" color="default" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <TiArrowRight />
          </DateNav>
        </Header>
      </div>
    );
  };

  return (
    <PortalModal
      size="lg"
      centered
      isOpen={isOpen}
      onConfirm={() => onConfirm(startDate, endDate)}
      onDecline={onDecline}
      title={title ? title : t('datepicker_modal_title')}
      confirmText={t('apply')}
      declineText={t('cancel')}
      justifyFooter={'start'}
    >
      <Container fluid>
        <Row>
          <Col sm={8}>
            {updateOnChange ? (
              <PortalDatePicker>
                <DatePicker
                  inline
                  fixedHeight
                  renderCustomHeader={DatePickerHeader}
                  onChange={date => onRangeChange(date)}
                  startDate={startDate}
                  endDate={endDate}
                  disabledKeyboardNavigation
                  selectsEnd
                />
              </PortalDatePicker>
            ) : (
              <DatePickerPlaceholder />
            )}
          </Col>
          <SideMenuCol sm={4}>
            <div className="w-100 text-center mb-3 mt-3">
              <Button color="default" onClick={() => onRangeChange(DATEPICKER.today)}>
                {t('today')}
              </Button>
            </div>
            <div className="w-100 text-center">
              <Button color="default" onClick={() => onRangeChange(DATEPICKER.reset)}>
                {t('reset')}
              </Button>
            </div>
          </SideMenuCol>
        </Row>
      </Container>
    </PortalModal>
  );
};

const DatePickerPlaceholder = styled.div`
  height: 484px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const DisplayMonth = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  line-height: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const DateNav = styled(Button)`
  width: 59px;
  height: 34px;
  border-radius: 39px;
  position: relative;
  ${props => {
    if (props.left)
      return css`
        margin-left: 2rem;
      `;
    if (props.right)
      return css`
        margin-right: 2rem;
      `;
  }}
  > svg {
    position: absolute;
    left: 23px;
    top: 9px;
  }
`;

const SideMenuCol = styled(Col)`
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--dark);
`;

const PortalModal = styled(Modal)`
  .modal-content {
    background-color: transparent;
  }
  .modal-header,
  .modal-body,
  .modal-footer {
    background-color: var(--darker);
    color: var(--white);
  }
  .modal-body {
    padding-left: 0;
    padding-right: 0;
  }
  .modal-header {
    border-bottom-color: var(--dark);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.18;
  }
  .modal-footer {
    border-top-color: var(--dark);
  }
`;

const PortalDatePicker = styled(DatePickerWrapper)`
  background-color: transparent;
  box-shadow: 0 0 0 0;
  .react-datepicker {
    background-color: transparent;
    border: 0;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: var(--light);
    width: 2.75rem;
    line-height: 2.75rem;
  }
  .react-datepicker__month {
    background-color: transparent;
    padding: 0 1rem 1rem 1rem;
  }
  .react-datepicker__month-container {
    box-shadow: 0 0 0 0;
  }
  .react-datepicker__header {
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    background-color: transparent;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: var(--primary);
    color: var(--light);
    border-radius: 100%;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover {
    border-radius: 100%;
    background-color: var(--light);
    color: var(--dark);
  }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:hover {
    background-color: var(--light);
    color: var(--dark);
  }
`;

export default DatePickerModal;
