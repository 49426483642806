import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { ModalHeader } from 'components/Modal';
import { Text } from 'components/Typography';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { handleData } from 'utils/helpers';

const ScreeningInfo = () => {
  const { resultScreening } = useReservedPurchaseContext();

  return (
    resultScreening && (
      <CustomModalHeader>
        <ScreeningTitle dangerouslySetInnerHTML={{ __html: handleData(resultScreening.title) }} />
        <ScreeningInfoItems className="d-flex align-items-center flex-wrap">
          <Text>{handleData(resultScreening.code)}</Text>
          <Text>{handleData(resultScreening.location)}</Text>
          <Text>{resultScreening.date ? moment(resultScreening.date).format('DD.MM.YYYY') : '-'}</Text>
        </ScreeningInfoItems>
      </CustomModalHeader>
    )
  );
};

const ScreeningTitle = styled(Text)`
  font-size: 21px;
  line-height: 1.62;
`;

const ScreeningInfoItems = styled.div`
  > p + p:before {
    content: '|';
    margin: 0 0.5rem;
  }
`;

const CustomModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;

export default ScreeningInfo;
