import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input as ReactstrapInput, Button, Row, Col } from 'reactstrap';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

import { Text } from 'components/Typography';
import { LOCALIZATION_PAGES } from 'utils/constants';
import ErrorMessage from './ErrorMessage';

const ModalTicketInput = ({
  seat,
  validationMessage,
  value,
  onChange,
  onBlur,
  validate,
  isLimitReached,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { t: tPurchase } = useTranslation(LOCALIZATION_PAGES.PURCHASE);

  const { seatTypeName, totalSeats, totalSoldSeats, price } = seat;
  const availableSeats = totalSeats - totalSoldSeats;
  const isSoldOut = availableSeats <= 0;
  const count = isSoldOut ? t('sold_out') : `${t('total_available')} ${availableSeats}/${totalSeats}`;

  const handleChange = async (value, step) => {
    onChange(value + step);
  };

  const handleKeyboardInput = e => {
    if (e.charCode < 48 || e.charCode > 57) e.preventDefault();
  };

  const handleBlur = async e => {
    await onBlur(e);
    if (!value) {
      onChange(0);
    }
  };

  const handleFocus = e => e.target.select();

  return (
    <Wrapper>
      <TicketRow className="align-items-center" isSoldOut={isSoldOut}>
        <Col xs={4}>
          <PrimaryText disabled={disabled}>{seatTypeName}</PrimaryText>
          <SecondaryText disabled={disabled}>{count}</SecondaryText>
        </Col>
        <Col xs={3}>
          <PrimaryText disabled={disabled}>{Number(price).toFixed(2)} BAM</PrimaryText>
          <SecondaryText disabled={disabled}>{t('per_ticket')}</SecondaryText>
        </Col>

        <Col xs={5} className="d-flex justify-content-end">
          <Input
            type="number"
            {...props}
            value={value}
            onChange={disabled ? null : onChange}
            min={0}
            max={availableSeats}
            disabled={availableSeats <= 0 || disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyPress={handleKeyboardInput}
          />
          <LeftArrowButton
            onClick={() => handleChange(value, 1)}
            disabled={value >= availableSeats || isLimitReached || disabled}
          >
            <UpIcon />
          </LeftArrowButton>
          <ArrowButton onClick={() => handleChange(value, -1)} disabled={value <= 0 || disabled}>
            <DownIcon />
          </ArrowButton>
        </Col>
      </TicketRow>
      {validationMessage && validationMessage !== 'accreditationLimitExceeded' && (
        <Row>
          <Col className="d-flex justify-content-end">
            <ErrorMessage message={tPurchase(validationMessage)} />
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const TicketRow = styled(({ isSoldOut, children, ...rest }) => <Row {...rest}>{children}</Row>)`
  ${({ isSoldOut }) =>
    isSoldOut
      ? css`
          opacity: 0.35;
          position: relative;
          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 15;
          }
          * {
            opacity: 1 !important;
          }
        `
      : null}
`;

const Input = styled(ReactstrapInput)`
  width: 92px;
  height: 49px;
  margin-right: 0.2rem;
  border-radius: 4px;
  border: solid 1px rgba(226, 229, 237, 0.11);
  background-color: rgba(0, 0, 0, 0.11);
  color: var(--white);
  &&::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &&[type='number'] {
    -moz-appearance: textfield;
  }

  &&:focus {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white);
    border: 1px solid var(--dark);
    box-shadow: none;
  }

  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &&:disabled {
    background-color: rgba(0, 0, 0, 0.11);
    /* color: var(--white); */
    opacity: 0.35;
  }
`;

const PrimaryText = styled(({ disabled, children, ...rest }) => <Text {...rest}>{children}</Text>)`
  ${({ disabled }) =>
    disabled
      ? css`
          font-size: 18px;
          margin-top: 0;
          opacity: 0.7;
        `
      : css`
          font-size: 18px;
          margin-top: 0;
        `}
`;

const SecondaryText = styled(({ disabled, children, ...rest }) => <Text {...rest}>{children}</Text>)`
  ${({ disabled }) =>
    disabled
      ? css`
          font-size: 14px;
          font-weight: 300;
          opacity: 0.4;
          margin: 0;
        `
      : css`
          font-size: 14px;
          font-weight: 300;
          opacity: 0.6;
          margin: 0;
        `}
`;

const ArrowButton = styled(Button)`
  width: 49px;
  height: 49px;
  border-radius: 4px;
  border: solid 1px var(--secondary);
  background-color: transparent;
  &:disabled {
    opacity: 0.35;
    background-color: transparent;
  }
`;

const LeftArrowButton = styled(ArrowButton)`
  margin-right: 0.4rem;
  margin-left: 0.2rem;
`;

const UpIcon = styled(TiArrowSortedUp)`
  width: 24.2px;
  height: 28px;
  color: white;
`;
const DownIcon = styled(TiArrowSortedDown)`
  width: 24.2px;
  height: 28px;
  color: white;
`;

export default ModalTicketInput;
