import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';
import styled from 'styled-components';

import { ErrorMessage } from 'components/Form';

const Checkbox = ({ width = 'auto', label, validationMessage, ...props }) => {
  return (
    <CheckboxWrapper>
      <CheckboxInput id={label} label={label} {...props} />
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  width: PropTypes.string || PropTypes.number,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
};

const CheckboxInput = styled(CustomInput)`
  > * {
    cursor: pointer;
    font-size: 16px;
    line-height: 1.65;
    padding-left: 0.6rem;
  }

  & label {
    color: var(--white);
  }
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 1rem;
`;

export default Checkbox;
