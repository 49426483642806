import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

const Tooltip = styled(UncontrolledTooltip)`
  .tooltip .arrow {
    &::before {
      border-bottom-color: var(--gray);
    }
  }

  .tooltip-inner {
    font-size: 14px;
    line-height: 1.57;
    text-align: center;
    background-color: var(--gray);
    padding: 0.3rem 0.75rem;
  }
`;

export default Tooltip;
