import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { DropdownToggle } from 'reactstrap';
import { useSelector } from 'react-redux';

import Select from 'components/Select/Select';
import { LOCALIZATION_PAGES, RESERVATION_STATUS } from 'utils/constants';
import { usePurchaseContext } from 'context/PurchaseContext';
import { isAllowed } from 'utils/helpers';
import { getUser } from 'redux/reducers/sessionReducer';
import permissions from 'utils/permissions';

const ActionsDropdown = memo(({ status, updateStatus, ...rest }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const user = useSelector(getUser);
  const canApprove = isAllowed(['all', permissions.CanSetApprovals], { user });
  const canCancel = isAllowed(['all', permissions.CanCancelReservations], { user });

  const { getAccreditation } = usePurchaseContext();

  const approveButton = {
    id: 1,
    NAME: t('approve'),
    label: 'approve',
    onSelect: () => {
      updateStatus(RESERVATION_STATUS.APPROVED);
    },
    disabled: !canApprove,
  };

  const declineButton = {
    id: 2,
    NAME: t('decline'),
    label: 'decline',
    onSelect: () => {
      updateStatus(RESERVATION_STATUS.DECLINED);
      getAccreditation();
    },
    disabled: !canApprove,
  };

  const cancelButton = {
    id: 3,
    NAME: t('cancel_reservation'),
    label: 'cancel',
    onSelect: () => {
      updateStatus(RESERVATION_STATUS.CANCELLED);
      getAccreditation();
    },
    disabled: !canCancel,
  };
  const items = [];

  if (status === RESERVATION_STATUS.PENDING) items.push(approveButton, declineButton);
  else if (status === RESERVATION_STATUS.APPROVED || status === RESERVATION_STATUS.CUSTOM_APPROVED)
    items.push(cancelButton);

  const onSelect = item => {
    item.onSelect(item);
  };

  const Toggle = () => {
    return (
      <FilterButton color="default" disabled={items?.length === 0}>
        {t('options')}
        <IconWrapper>
          <SortIconUp />
          <SortIconDown />
        </IconWrapper>
      </FilterButton>
    );
  };

  return <Select ToggleElem={Toggle} items={items} onSelect={onSelect} {...rest} />;
});

const FilterButton = styled(DropdownToggle)`
  height: 29px;
  font-size: 12px;
  padding: 0 1rem;
  width: fit-content;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--white);
  border: 1px solid #707070;
  border-radius: 39px;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 92, 94, 0.5);
  }
  &:hover {
    color: var(--secondary);
    background-color: transparent;
  }
  svg {
    margin-bottom: 3px;
    margin-right: 1rem;
  }
  &:disabled {
    cursor: auto;
  }
`;

const IconWrapper = styled.div`
  display: inline;
  position: relative;
  margin-left: 20px;
`;

const SortIconUp = styled(FaSortUp)`
  opacity: 30%;
  position: absolute;
  top: 3px;
  left: -10px;
`;

const SortIconDown = styled(FaSortDown)`
  opacity: 30%;
  position: absolute;
  top: 3px;
  left: -10px;
`;

export default ActionsDropdown;
