import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const path = '/carts/tickets/reserved';

const useAddReservedSeats = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
  const { showNotification } = useRoot();
  const [{ result, loading, error }, addReservedSeats] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('tickets_add_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('tickets_add_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading }, addReservedSeats];
};

export default useAddReservedSeats;
