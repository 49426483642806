import React, { memo } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Select from 'components/Select/Select';
import { getUser } from 'redux/reducers/sessionReducer';
import { DropdownToggleButton as ToggleButton } from 'components/Dropdown';
import { LOCALIZATION_PAGES } from 'utils/constants';

const RequesterDropdown = memo(({ setUser, users, isLoading, selected }) => {
  const user = useSelector(getUser);
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const items = [
    { id: user.id, name: t('my_user', { me: user.fullName }), label: 'my_user' },
    { id: '', name: t('all_users'), label: 'all_users' },
    ...users,
  ].map(i => {
    return {
      id: i.id,
      NAME: i.name,
      label: i.label,
      onSelect: thisItem => {
        setUser(() => thisItem);
      },
    };
  });

  i18n.on('languageChanged', () => {
    setUser(draft => {
      if (draft.label) draft.NAME = t(draft.label);
    });
  });

  const Toggle = () => (
    <DropdownToggleButton disabled={users.length < 1 || isLoading}>{selected.NAME}</DropdownToggleButton>
  );

  return <Select ToggleElem={Toggle} items={items} onSelect={item => item.onSelect(item)} selectWidth={'680px'} />;
});

const DropdownToggleButton = styled(ToggleButton)`
  width: 680px;
  height: 55px;
  border-radius: 4px;
  border: solid 1px rgba(226, 229, 237, 0.11);
  background-color: rgba(0, 0, 0, 0.11);
  text-align: left;
  text-transform: none;
`;

export default RequesterDropdown;
