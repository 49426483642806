import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

const useCheckAuth = () => {
  const [{ result: resultCheckAuth, loading: loadingCheckAuth, error: errorCheckAuth }, checkAuth] = useApi(
    API_ENDPOINTS.CHECK_AUTH,
    {
      initialFetch: false,
      verb: HTTP_VERBS.POST,
    }
  );
  return [{ resultCheckAuth, loadingCheckAuth, errorCheckAuth }, checkAuth];
};

export default useCheckAuth;
