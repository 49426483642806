import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';

import { isNumber } from 'utils/helpers';

import { LOCALIZATION_PAGES, PURCHASE_TABS } from 'utils/constants';
import { usePurchaseContext } from 'context/PurchaseContext';
import { PURCHASE_DIST_SCHEMA } from 'utils/validation';

import { Loader, Tab, Tabs, TabCustomLabel, PurchaseModal, ModalBody, Title } from 'components';
import RenderPurchaseFields from './RenderPurchaseFields';
import ScreeningInfoDistributed from './ScreeningInfoDistributed';
import ValidateAccreditation from '../ValidateAccreditation';
import AccreditationInfoDistributed from './AccreditationInfoDistributed';
import AccreditationLimitMessage from './AccreditationLimitMessage';

const PurchaseDistributed = () => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);

  const {
    purchaseDistOpen,
    loadingAvailability,
    togglePurchaseDist,
    addToCart,
    initialValues,
    formRef,
    handleFormSubmit,
    selectedScreening,
    accreditation,
    handleRemoveAccreditation,
    cart,
    loadingUpdateCartDist,
  } = usePurchaseContext();

  const getTab = selectorId => {
    const Tab = PURCHASE_TABS.find(icon => icon.selectorId === selectorId);
    if (Tab) return Tab;
    else return PURCHASE_TABS.find(x => !x.selectorId);
  };

  const sortByOrdinal = (a, b) => {
    const aOrdinal = getTab(a.ticketTypeSelectorId)?.ordinal;
    const bOrdinal = getTab(b.ticketTypeSelectorId)?.ordinal;
    if (aOrdinal > bOrdinal) {
      return 1;
    }
    if (aOrdinal < bOrdinal) {
      return -1;
    }
    return 0;
  };

  const countTickets = values => {
    let count = 0;
    if (values && values.ticketInserts)
      values.ticketInserts.forEach(insert => {
        if (insert && insert.ticketSeatTypes)
          insert.ticketSeatTypes.forEach(ticketSeatType => {
            if (isNumber(ticketSeatType.quantity) && ticketSeatType.quantity > 0) count += ticketSeatType.quantity;
          });
      });
    return count;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      onSubmit={addToCart}
      innerRef={formRef}
      validationSchema={PURCHASE_DIST_SCHEMA}
      validateOnChange
      validateOnBlur
    >
      {({ values, errors, validateForm, validateField }) => (
        <PurchaseModal
          customSize="md"
          centered
          loading={loadingAvailability || loadingUpdateCartDist}
          isOpen={purchaseDistOpen}
          onDecline={togglePurchaseDist}
          onConfirm={handleFormSubmit}
          confirmDisabled={!values.ticketInserts || Object.keys(errors).length > 0}
          confirmText={tCommon('confirm_tickets', { quantity: countTickets(values) })}
          declineText={tCommon('cancel')}
        >
          {loadingAvailability ? (
            <Loader />
          ) : (
            <Form>
              {values && values.ticketInserts ? (
                <Tabs type="purchase">
                  {[...values.ticketInserts]
                    .filter(x => x.ticketTypeSelectorId !== -3)
                    .sort(sortByOrdinal)
                    .map((screeningSeats, index) => (
                      <Tab
                        key={`${screeningSeats.id}-${index}`}
                        label={
                          <TabCustomLabel
                            ss={screeningSeats}
                            icon={getTab(screeningSeats.ticketTypeSelectorId).iconPath}
                            text={tCommon(getTab(screeningSeats.ticketTypeSelectorId).label)}
                            value={[screeningSeats.totalAvailableSeats, screeningSeats.totalSeats]}
                          />
                        }
                        disabled={parseInt(screeningSeats.totalSeats) === 0}
                      >
                        <PurchaseModalBody>
                          <ScreeningInfoDistributed screening={selectedScreening} />
                          {getTab(screeningSeats.ticketTypeSelectorId).selectorId === -1 ? (
                            <>
                              {accreditation ? (
                                <>
                                  <Separator />
                                  <AccreditationInfoDistributed
                                    accreditation={accreditation}
                                    handleRemoveAccreditation={handleRemoveAccreditation}
                                  />
                                  <Separator />
                                  <FieldArray name="ticketSeatTypes">
                                    <RenderPurchaseFields
                                      values={values}
                                      screeningSeats={screeningSeats}
                                      cart={cart}
                                      accreditation={accreditation}
                                      validateForm={validateForm}
                                    />
                                  </FieldArray>
                                  <AccreditationLimitMessage accreditation={accreditation} values={values} />
                                </>
                              ) : (
                                <ValidateAccreditation screeningId={selectedScreening.id} />
                              )}
                            </>
                          ) : (
                            <>
                              <Separator />
                              <FieldArray name="ticketSeatTypes">
                                <RenderPurchaseFields
                                  values={values}
                                  screeningSeats={screeningSeats}
                                  validateField={validateField}
                                />
                              </FieldArray>
                            </>
                          )}
                        </PurchaseModalBody>
                      </Tab>
                    ))}
                </Tabs>
              ) : (
                <Title className="my-5 text-center">{tCommon('no_data')}</Title>
              )}
            </Form>
          )}
        </PurchaseModal>
      )}
    </Formik>
  );
};

const PurchaseModalBody = styled(ModalBody)`
  padding: 2rem 2rem 2.5rem 2rem;
`;

const Separator = styled.div`
  margin: 2.5rem -2rem;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.05);
`;

export default PurchaseDistributed;
