import React, { useState, useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';

import ReservationList from './ReservationList';
import ReservationDetails from './ReservationDetails';
import { useReservations } from 'utils/hooks/api';
import { API_QUERY } from 'utils/constants';
import { useToggle } from 'utils/hooks';

const initialReservation = {
  id: '',
  cartId: '',
  requesterName: '',
  title: '',
  status: '',
  ticketCount: '',
  createdDate: '',
};

const Reservations = ({ setShowReservations, shiftStatus }) => {
  const [showReservationDetails, setShowReservationDetails] = useToggle(false);
  const [reservationId, setReservationId] = useState(null);
  const [reservation, setReservation] = useImmer(initialReservation);
  const [query, setQuery] = useImmer({
    ...API_QUERY.reservations,
    pageSize: 1000,
  });
  const [{ reservations, loading }, getReservations] = useReservations(query);

  const onSearch = useCallback(
    keyword => {
      setQuery(draft => {
        draft.term = encodeURIComponent(keyword);
      });
    },
    [setQuery]
  );

  useEffect(() => {
    if (reservationId && reservations && reservations.data && reservations.data.length > 0) {
      const res = reservations.data.find(x => x.id === reservationId);
      setReservation(draft => {
        draft.id = res.id;
        draft.cartId = res.cartId;
        draft.requesterName = res.requesterName;
        draft.title = res.title;
        draft.status = res.status;
        draft.ticketCount = res.ticketCount;
        draft.createdDater = res.createdDate;
      });
    }
  }, [reservationId, reservations, setReservation]);

  const showDetails = () => {
    if (showReservationDetails) {
      setReservationId(null);
      getReservations();
      setShowReservationDetails();
    } else {
      setShowReservationDetails();
    }
  };

  return (
    <div>
      {showReservationDetails ? (
        <ReservationDetails
          reservation={reservation}
          setReservation={setReservation}
          setShowReservationDetails={showDetails}
          shiftStatus={shiftStatus}
        />
      ) : (
        <ReservationList
          reservations={reservations}
          setShowReservations={setShowReservations}
          setShowReservationDetails={showDetails}
          setReservationId={setReservationId}
          onSearch={onSearch}
          loading={loading}
          query={query}
          setQuery={setQuery}
        />
      )}
    </div>
  );
};

export default Reservations;
