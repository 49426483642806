import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/Modal';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { Label as DefaultLabel } from 'components';
import { Input } from 'components/Form';
import { useCreateReservation } from 'utils/hooks/api';

let res = {
  status: 0,
  cartId: 0,
  title: '',
};

const ReservationModal = ({ isOpen, toggleReservation, cartId, clearCartLocal }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const [reservationTitle, setReservationTitle] = useState('');
  const [{ result, loading }, createReservation] = useCreateReservation();

  const onSubmit = async () => {
    res.cartId = cartId;
    res.title = reservationTitle;

    await createReservation(res);
  };

  useEffect(() => {
    if (!loading && result) {
      toggleReservation();
      clearCartLocal();
    }
  }, [loading, result, toggleReservation, clearCartLocal]);

  const cancelReservation = () => {
    toggleReservation();
  };

  const handleChange = e => {
    setReservationTitle(e.target.value);
  };

  return (
    <Modal
      centered
      customSize="580"
      title={t('title')}
      confirmText={t('send_request')}
      declineText={t('cancel')}
      loading={false}
      isOpen={isOpen}
      buttonDisabled={!reservationTitle}
      onConfirm={onSubmit}
      onDecline={cancelReservation}
    >
      <div className="w-100">
        <Label>{t('reservation_title_label')}</Label>
        <InputWrapper>
          <Input onChange={handleChange} value={reservationTitle} />
        </InputWrapper>
      </div>
    </Modal>
  );
};

const InputWrapper = styled.div`
  width: 100%;
  & input {
    background-color: rgba(0, 0, 0, 0.11);
    border: solid 1px rgba(226, 229, 237, 0.11);
    height: 49px;
    color: var(--white);
    &:focus {
      background-color: rgba(0, 0, 0, 0.11);
      border: solid 1px rgba(226, 229, 237, 0.11);
      color: var(--white);
    }
  }
  & .is-invalid {
    border-color: #ff004e;
    background: none;
  }
  & .is-invalid:focus {
    border-color: #ff004e;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.19);
  }
`;

const Label = styled(DefaultLabel)`
  text-transform: none;
  color: var(--white);
`;

export default ReservationModal;
