import { useSelector } from 'react-redux';
import useApi from 'utils/hooks/api/useApi';
import { getUser } from 'redux/reducers/sessionReducer';

import { API_ENDPOINTS } from 'utils/constants';

const useShift = () => {
  const path = `${API_ENDPOINTS.SHIFTS}`;
  const user = useSelector(getUser);

  const [{ result: shift, loading, error, setResult: setApiShift }, getShift] = useApi(path, { initialFetch: !!user });

  return [{ shift, loading, error }, getShift, setApiShift];
};

export default useShift;
