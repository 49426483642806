import { object, number, array } from 'yup';

const PURCHASE_DIST_SCHEMA = object().shape({
  screeningId: number().required('purchase:required_screeningId'),
  ticketInserts: array().of(
    object().shape({
      ticketTypeSelectorId: number().required('purchase:required_ticketTypeSelectorId'),
      ticketSeatTypes: array().of(
        object().shape({
          seatTypeId: number().required('purchase:required_seatTypeId'),
          quantity: number()
            .min(0, 'purchase:min_quantity')
            .required('purchase:required_quantity'),
        })
      ),
    })
  ),
});

export default PURCHASE_DIST_SCHEMA;
