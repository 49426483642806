import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DropdownToggle } from 'reactstrap';

import Select from 'components/Select/Select';
import Flexbox from 'components/Flexbox/Flexbox';
import { getLanguage } from 'redux/reducers/sessionReducer';
import { sessionActions } from 'redux/actions';
import { LANGUAGES, LOCALIZATION_PAGES } from 'utils/constants';

const LanguageSelect = ({ selectstyle, ...rest }) => {
  const language = useSelector(getLanguage);
  const dispatch = useDispatch();
  const { t } = useTranslation(LOCALIZATION_PAGES.HEADER);

  const Toggle = () => {
    const langConfig = LANGUAGES.find(l => l.value === language.value);

    return (
      <CustomToggle color={selectstyle === 'light' ? 'light' : 'dark'} block selectstyle={selectstyle}>
        <Icon src={langConfig.iconPath} alt={t('flag_icon')} />
        <ToogleValue>{langConfig.value}</ToogleValue>
        <Icon src={`${process.env.PUBLIC_URL}/assets/icons/select.svg`} alt={t('select_icon')} />
      </CustomToggle>
    );
  };

  return (
    <LanguageContainer>
      <Select
        width="100%"
        value={language}
        items={LANGUAGES}
        onSelect={lng => dispatch(sessionActions.setLanguage(lng))}
        ToggleElem={Toggle}
        color={selectstyle}
        {...rest}
      />
    </LanguageContainer>
  );
};

const LanguageContainer = styled(Flexbox)`
  height: 38px;
  width: 104px;
  align-items: center;
`;

const Icon = styled.img`
  height: 13px;
  width: 13px;
`;

const CustomToggle = styled(DropdownToggle)`
  display: flex;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ selectstyle }) => (selectstyle === 'light' ? ' #fffff' : '#1e2025')};
  border-color: ${({ selectstyle }) => (selectstyle === 'light' ? '#d8dce6' : 'var(--black)')};
`;

const ToogleValue = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

LanguageSelect.propTypes = {
  selectstyle: PropTypes.string,
};

export default LanguageSelect;
