import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button/Button';

const SubmitButton = ({ children, ...rest }) => {
  return (
    <Wrapper color="primary" type="submit" {...rest}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Button)`
  box-shadow: 0px 1px 1px 0 rgba(19, 31, 21, 0.1);
`;

export default SubmitButton;
