import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import CartItem from 'components/PurchaseCart/CartItem';
import Price from 'components/PurchaseCart/Price';
import Loader from 'components/Loader/Loader';
import Timer from 'components/PurchaseCart/Timer';
import TicketCancelationModal from 'components/TicketCancelation/TicketCancelationModal';
import DiscountModal from 'components/Discount/DiscountModal';
import Button from 'components/Button/Button';
import { AdminButtons, PurchaseButtons } from 'components/PurchaseCart/CartButtons';
import { Text } from 'components/Typography';
import { useToggle } from 'utils/hooks';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { usePurchaseContext } from 'context/PurchaseContext';
import { useAddDiscountToCart } from 'utils/hooks/api';
import Reservations from 'components/Reservations/Reservations';
import Authorize from 'components/Authorization/Authorize';
import permissions from 'utils/permissions';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';

const DISCOUNT_ERRORS = {
  INVALID: 'Entered Discount code is invalid!',
};

const PurchaseCart = props => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE_CART);
  const { t: tDiscount } = useTranslation(LOCALIZATION_PAGES.DISCOUNT);
  let user = { role: 'admin' };
  const { cart, loadingUpdateCart, loadingCart, getCart, clearCart, handleRemoveAccreditation } = usePurchaseContext();
  const { onRemoveAccreditation } = useReservedPurchaseContext();
  const [isTicketCancelation, setIsTicketCancelation] = useToggle(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [validationMessage, setValidationMessage] = useState(null);
  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [showReservations, setShowReservations] = useToggle(false);
  const [{ loading: addDiscountLoading, error }, addDiscount, setDiscountError] = useAddDiscountToCart(discountCode);
  const { shiftStatus } = props;

  useEffect(
    () => {
      if (cart && cart.discount) setDiscount(cart.discount);

      if (cart && !cart.discount) setDiscount(null);

      if (discount) setDiscountCode('');
    },
    [cart, discount],
    discount
  );

  useEffect(() => {
    if (!cart?.cartScreenings.length) {
      clearCart();
    }
  }, [cart, clearCart]);

  const handleError = useCallback(() => {
    if (!error && !validationMessage && isOnSubmit) {
      setIsDiscount(false);
    }
    if (error && error.data === DISCOUNT_ERRORS.INVALID) {
      setValidationMessage(tDiscount('discount_code_invalid'));
    } else if (error) {
      setValidationMessage(tDiscount('discount_add_fail'));
    } else if (!error) {
      setValidationMessage(null);
      if (discount) setIsDiscount(false);
    }
  }, [error, tDiscount, validationMessage, isOnSubmit, setIsDiscount, discount]);

  useEffect(() => {
    handleError();
  }, [handleError]);

  const handleChange = e => {
    if (validationMessage) {
      setDiscountError(null);
      setIsOnSubmit(false);
    }
    setDiscountCode(/^\s/.test(e.target.value) ? '' : e.target.value); //discount code should not start with space
  };

  const onSubmit = async () => {
    await addDiscount();
    await getCart();
    setIsOnSubmit(true);
  };

  const onDecline = () => {
    setIsDiscount(false);
    setDiscountCode('');
    setValidationMessage(null);
  };

  const onRemoveDiscount = async () => {
    await addDiscount();
    await getCart();
    setDiscount(null);
    setIsDiscount(false);
  };

  const clearAll = async () => {
    await handleRemoveAccreditation();
    await onRemoveAccreditation();
    await clearCart();
  };

  return (
    <Wrapper>
      {showReservations ? (
        <Reservations setShowReservations={setShowReservations} shiftStatus={shiftStatus} />
      ) : (
        <>
          {loadingUpdateCart || loadingCart ? (
            <div className="align-self-center" style={{ height: '100vh' }}>
              <Loader />
            </div>
          ) : (
            <>
              <CartHeaderWrapper>
                <Timer
                  showTimer={cart?.validUntil ? true : false}
                  validUntil={cart?.validUntil ? cart.validUntil : null}
                />
                {cart?.cartScreenings?.length > 0 && (
                  <Authorize forPermissions={['all', permissions.CanDeleteCart]} renderIfUnauthorized={true}>
                    <CustomButton block color="default" onClick={clearAll}>
                      {t('clear_all')}
                    </CustomButton>
                  </Authorize>
                )}
              </CartHeaderWrapper>
              {cart?.cartScreenings?.length > 0 ? (
                <>
                  <TicketsWrapper className="flex-grow-1">
                    {cart?.cartScreenings?.map((i, index) => (
                      <CartItem key={`${i.screeningCode}.${index}`} item={i} shiftStatus={shiftStatus} />
                    ))}
                  </TicketsWrapper>
                  <DiscountWrapper>
                    <Text>{!discount ? tDiscount('no_discount') : `${tDiscount('discount')}: ${discount.code}`}</Text>
                    {!discount ? (
                      <Authorize forPermissions={['all', permissions.CanUseDiscounts]} renderIfUnauthorized={true}>
                        <CustomButton color="default" onClick={() => setIsDiscount(true)}>
                          {tDiscount('add')}
                        </CustomButton>
                      </Authorize>
                    ) : (
                      <CustomButton color="default" onClick={onRemoveDiscount}>
                        {tDiscount('remove')}
                      </CustomButton>
                    )}
                  </DiscountWrapper>
                </>
              ) : (
                <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                  <Text18>{t('no_tickets')}</Text18>
                </div>
              )}
              <div>
                <Price price={cart?.total} showTotal={cart ? true : false} cart={cart} discount={discount} />
                <AdminButtons
                  user={user}
                  cart={cart?.cartScreenings}
                  setIsTicketCancelation={setIsTicketCancelation}
                  setShowReservations={setShowReservations}
                  shiftStatus={shiftStatus}
                />
                <PurchaseButtons user={user} cart={cart} discount={discount} />
                <FooterLabel>{t('footer')}</FooterLabel>
              </div>
              <TicketCancelationModal
                isTicketCancelation={isTicketCancelation}
                setIsTicketCancelation={setIsTicketCancelation}
              />
            </>
          )}
          <DiscountModal
            isDiscount={isDiscount}
            setIsDiscount={setIsDiscount}
            addDiscountLoading={addDiscountLoading}
            discountCode={discountCode}
            onSubmit={onSubmit}
            onDecline={onDecline}
            handleChange={handleChange}
            validationMessage={discountCode && validationMessage}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  position: sticky;
  top: 0;
  z-index: 1;
  right: 0px;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: right;
  width: 450px;
  min-height: 100vh;
  padding: 0;
  box-shadow: 5px 8px 18px 0 rgba(0, 0, 0, 0.7);
`;

const FooterLabel = styled.p`
  margin-top: 1.5rem;
  opacity: 0.2;
  font-size: 14px;
  line-height: 1.36;
  text-align: center;
  color: var(--white);
`;

const Text18 = styled(Text)`
  font-size: 18px;
`;

const TicketsWrapper = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 170px - 270px);

  // scrollbar width
  ::-webkit-scrollbar {
    width: 6px;
  }

  // scrollbar Handle color
  ::-webkit-scrollbar-thumb {
    background: #676767;
  }

  // scrollbar Handle color on hover
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const DiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2.5rem 1rem 2.5rem;
`;

const CustomButton = styled(Button)`
  height: 29px;
  font-size: 12px;
  padding: 0 1rem;
  width: fit-content;
`;

const CartHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2.5rem 1rem 2.5rem;
`;

export default PurchaseCart;
