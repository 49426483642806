import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ModalBody } from 'components/Modal';
import { Text } from 'components/Typography';
import { useShiftContext } from 'context/ShiftContext';
import { LOCALIZATION_PAGES, SHIFT_MODES } from 'utils/constants';

const viewShiftIcon = `${process.env.PUBLIC_URL}/assets/icons/interface.svg`;
const startShiftIcon = `${process.env.PUBLIC_URL}/assets/icons/clock.svg`;

const ShiftModal = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SHIFT);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { isModalOpen, selectedMode, setSelectedMode, onSelectMode, logOut } = useShiftContext();

  return (
    <Modal
      centered
      loading={false}
      isOpen={isModalOpen}
      onConfirm={onSelectMode}
      onDecline={logOut}
      title={`${t('start_your_shift')}?`}
      confirmText={t('proceed')}
      declineText={tCommon('log_out')}
      buttonDisabled={!selectedMode}
      hideCancelButton={false}
      justifyFooter={'end'}
      useToggle={false}
    >
      <CustomModalBody>
        <div className="d-flex">
          <Card
            className={selectedMode === SHIFT_MODES.VIEW ? 'highlighted-border' : ''}
            onClick={() => setSelectedMode(SHIFT_MODES.VIEW)}
          >
            <ViewImg alt={t('view_only')} src={viewShiftIcon} />
            <Title>{t('view_only')}</Title>
            <CustomText>{t('view_description')}</CustomText>
          </Card>
          <Card
            className={selectedMode === SHIFT_MODES.STARTED ? 'highlighted-border' : ''}
            onClick={() => setSelectedMode(SHIFT_MODES.STARTED)}
          >
            <ViewImg alt={t('start_your_shift')} src={startShiftIcon} />
            <Title>{t('start_your_shift')}</Title>
            <CustomText>{t('start_description')}</CustomText>
          </Card>
        </div>
      </CustomModalBody>
    </Modal>
  );
};

const CustomModalBody = styled(ModalBody)`
  border-bottom: none;
  padding: 3rem;
`;

const Card = styled.div`
  width: 422px;
  height: 422px;
  padding: 3.9rem;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.06);
`;

const ViewImg = styled.img`
  width: 79.6px;
  height: 56px;
  margin-bottom: 1rem;
  object-fit: contain;
`;

const Title = styled(Text)`
  font-size: 21px;
  line-height: 1.62;
`;

const CustomText = styled(Text)`
  opacity: 0.5;
  line-height: 1.61;
  text-align: center;
`;

export default ShiftModal;
