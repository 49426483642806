import { lazy } from 'react';
import { matchPath } from 'react-router-dom';
import { generatePath } from 'react-router';

import Authentication from 'components/Authorization/Authentication';
import Authorization from 'components/Authorization/Authorization';
import permissions from 'utils/permissions';

const Login = lazy(() => import('components/Login/Login'));
const ForgotPassword = lazy(() => import('components/Login/ForgotPassword'));
const MyProfile = Authentication(lazy(() => import('components/Users/MyProfile')));
const Events = Authentication(lazy(() => import('components/Events/Events')));
const Film = Authentication(lazy(() => import('components/Films/FilmView')));
const ScreeningDetails = Authentication(lazy(() => import('components/Screenings/ScreeningDetails')));
const ShiftDetails = Authentication(lazy(() => import('components/Shift/ShiftDetails')));
const PrintLog = Authentication(lazy(() => import('components/PrintLog/PrintLog')));

const routesConfiguration = {
  LOGIN: {
    id: 'LOGIN',
    path: '/login',
    exact: true,
    Component: Login,
  },
  FORGOT_PASSWORD: {
    id: 'FORGOT_PASSWORD',
    path: '/forgot-password',
    exact: true,
    Component: ForgotPassword,
  },
  ROOT: {
    id: 'ROOT',
    path: '/',
    exact: true,
    Component: Events,
    showHeader: true, // indicates Header component is included in the view
    showCart: true,
  },
  MY_PROFILE: {
    id: 'MY_PROFILE',
    path: '/profile',
    exact: true,
    Component: MyProfile,
    showHeader: true,
  },
  FILMS: {
    id: 'FILM',
    path: '/films',
    exact: true,
    Component: Events,
    showHeader: true,
    showCart: true,
  },
  FILM: {
    id: 'FILM',
    path: '/films/:id',
    exact: true,
    Component: Film,
    showHeader: true,
    showCart: true,
  },
  SCREENINGS: {
    id: 'SCREENING',
    path: '/screenings',
    exact: true,
    Component: Events,
    showHeader: true,
    showCart: true,
  },
  SCREENING: {
    id: 'SCREENING',
    path: '/screenings/:id',
    exact: true,
    Component: ScreeningDetails,
    showHeader: true,
    showCart: true,
  },
  SHIFT_DETAILS: {
    id: 'SHIFT_DETAILS',
    path: '/shift-details',
    Component: ShiftDetails,
    showHeader: true,
    showCart: false,
  },
  PRINT_LOG: {
    id: 'PRINT_LOG',
    path: '/print-log',
    exact: true,
    Component: PrintLog,
    allowIf: ['any', permissions.CanViewAllPrintLogs, permissions.CanViewPersonalPrintLogs],
    showHeader: true,
    showCart: false,
  },
};

// export render ready routes but handle authorization first
// according to route configuration, wrap protected routes with withAuthorization HOC
export const routes = Object.values(routesConfiguration).map(route => {
  if (route.allowIf) {
    route.Component = Authorization(route.Component, route.allowIf);
  }
  return route;
});

/**
 * Find a matching route in all application routes
 * @param {String} pathname     Path to search for in routes.
 * @param {Boolean} exact       Find exact match or first partial match.
 */
export function matchRoute(pathname, exact) {
  return Object.values(routesConfiguration).find(r =>
    matchPath(pathname, {
      path: r.path,
      exact: exact == null ? r.exact : exact, // if exact flag provided use it otherwise use as specified in route r
    })
  );
}

/**
 * Generate path for given route id or route. Pass params for rouutes
 * which contain dynamic parts like query params
 * @param {String|Object} routeOrRouteId    Route ID as string or complete Route object to match.
 * @param {Object} params                   Object containing data for query parameters included in matched route.
 */

export function generateLink(routeOrRouteId, params) {
  let route;
  if (typeof routeOrRouteId === 'string') {
    route = routesConfiguration[routeOrRouteId];
  } else if (routeOrRouteId.hasOwnProperty('id') && routesConfiguration[routeOrRouteId.id]) {
    route = routeOrRouteId;
  }

  if (!route) {
    console.error(`Route not found error. Can't generate link for unknown route ${routeOrRouteId}`);
    return '#';
  }
  return generatePath(route.path, params);
}

export default routesConfiguration;
