import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useReservations = query => {
  let queryString = `${API_ENDPOINTS.RESERVATIONS}?Term=${query.term}&StartDate=${query.startDate}&EndDate=${query.endDate}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}&UserId=${query.userId}`;
  if (query.statuses) {
    query.statuses.forEach(status => {
      queryString += `&Statuses=${status}`;
    });
  }
  const [path, setPath] = useState(queryString);
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const { showNotification } = useRoot();
  const [{ result: reservations, loading, error }, getReservations] = useApi(path);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('reservations_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ reservations, loading, error }, getReservations];
};

export default useReservations;
