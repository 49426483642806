import React from 'react';
import styled from 'styled-components';

const Text = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>;

const Wrapper = styled.p`
  margin: 0.6rem 0;
  font-size: 16px;
  line-height: 1.21;
  color: var(--white);
`;

export default Text;
