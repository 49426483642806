import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Text } from 'components/Typography';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { usePurchaseContext } from 'context/PurchaseContext';
import { toLocalDateTime } from 'utils/helpers';

const Timer = ({ interval = 1000, showTimer, validUntil }) => {
  const { clearCartLocal } = usePurchaseContext();
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE_CART);

  const [timer, setTimer] = useState(getTime(validUntil));

  const zero = moment(0).format('mm:ss');

  const validCartTime = moment(toLocalDateTime(validUntil));
  const currentTimeDate = moment(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setTimer(getTime(validUntil)), interval);
    if (validCartTime <= currentTimeDate) {
      clearCartLocal();
      setTimer(0);
    }
    return () => clearInterval(intervalId);
  }, [setTimer, interval, validCartTime, validUntil, clearCartLocal, currentTimeDate]);

  const TimeToDisplay = () => <Wrapper>{<CustomText> {`${t('time_remaining')}: ${timer}`}</CustomText>}</Wrapper>;

  return showTimer && timer !== zero ? <TimeToDisplay /> : <div></div>;
};

const getTime = validUntil => {
  const until = moment(validUntil);
  const current = moment(new Date());
  let initialTimeRemaining = moment(until - current);
  const value = initialTimeRemaining.valueOf();
  const timer = moment(value).format('mm:ss');
  return timer;
};

const Wrapper = styled.div`
  width: 70%;
`;

const CustomText = styled(Text)`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.19;
  color: #92e992;
`;

export default Timer;
