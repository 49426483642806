import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, DEFAULT_PAGE_SIZE, API_QUERY } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const usersQuery = {
  ...API_QUERY,
  search: '',
  includes: 'UserRoles.Role',
  pageSize: DEFAULT_PAGE_SIZE,
  page: '0',
  retrieveAll: 'true',
  includeCount: 'false',
  roles: [],
  isBlocked: '',
  isLocked: '',
};

const useUsers = (query = usersQuery) => {
  const queryString = `${API_ENDPOINTS.USERS}?Term=${query.search}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=FirstName`;
  const [path, setPath] = useState(queryString);

  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);
  const { showNotification } = useRoot();

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  const [{ result, loading, error }, getUsers] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('users_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ result, loading, error }, getUsers];
};

export default useUsers;
