import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import routes, { generateLink } from 'utils/routes';
import { sessionActions } from 'redux/actions';
import { getShift } from 'redux/reducers/sessionReducer';
import { useStartShift, useEndShift, useShift } from 'utils/hooks/api';
import { SHIFT_MODES } from 'utils/constants';
import { getUser } from 'redux/reducers/sessionReducer';

const ShiftContext = React.createContext();

export const ShiftProvider = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const shift = useSelector(getShift);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const [, startShift] = useStartShift();
  const [{ loading: endShiftLoading }, endShift] = useEndShift();
  const [{ shift: apiShift, loading: apiShiftLoading }, , setApiShift] = useShift();
  const user = useSelector(getUser);

  const { shiftStatus, updateShiftStatus } = props;

  const goToShiftDetails = useCallback(() => history.push(generateLink(routes.SHIFT_DETAILS)), [history]);

  const endShiftHandler = useCallback(async () => {
    await endShift();
    setApiShift(null);
    updateShiftStatus(false);
    dispatch(sessionActions.setShift(null));
    history.push(generateLink(routes.MY_PROFILE));
  }, [endShift, history, dispatch, setApiShift, updateShiftStatus]);

  const onSelectMode = useCallback(() => {
    dispatch(sessionActions.setShift(selectedMode));
    if (selectedMode === SHIFT_MODES.STARTED) {
      startShift();
      updateShiftStatus(true);
    } else updateShiftStatus(false);
    setIsModalOpen(false);
  }, [dispatch, selectedMode, setIsModalOpen, startShift, updateShiftStatus]);

  const startShiftHandler = useCallback(() => {
    setSelectedMode(SHIFT_MODES.STARTED);
    dispatch(sessionActions.setShift(SHIFT_MODES.STARTED));
    startShift();
    updateShiftStatus(true);
  }, [dispatch, startShift, updateShiftStatus]);

  const logOut = useCallback(async () => {
    if (shift) dispatch(sessionActions.setShift(null));
    dispatch(sessionActions.logOut());
    history.push(routes.LOGIN.path);
  }, [history, dispatch, shift]);

  useEffect(() => {
    if (!apiShift && !apiShiftLoading && !shift && user) {
      setIsModalOpen(true);
    }
  }, [setIsModalOpen, apiShift, apiShiftLoading, shift, user]);

  useEffect(() => {
    if (apiShift && shift !== SHIFT_MODES.STARTED) {
      dispatch(sessionActions.setShift(SHIFT_MODES.STARTED));
      updateShiftStatus(true);
    }
  }, [dispatch, apiShift, shift, updateShiftStatus]);

  const context = useMemo(
    () => ({
      isModalOpen,
      selectedMode,
      setSelectedMode,
      goToShiftDetails,
      onSelectMode,
      startShift,
      endShiftHandler,
      apiShift,
      apiShiftLoading,
      startShiftHandler,
      endShiftLoading,
      logOut,
      shiftStatus,
      updateShiftStatus,
    }),
    [
      isModalOpen,
      selectedMode,
      setSelectedMode,
      goToShiftDetails,
      onSelectMode,
      startShift,
      endShiftHandler,
      apiShift,
      apiShiftLoading,
      startShiftHandler,
      endShiftLoading,
      logOut,
      shiftStatus,
      updateShiftStatus,
    ]
  );

  return <ShiftContext.Provider value={context}>{props.children}</ShiftContext.Provider>;
};

export function useShiftContext() {
  const context = useContext(ShiftContext);
  if (!context) {
    throw new Error(`useShiftContext must be used within a ShiftProvider`);
  }
  return context;
}

export const Consumer = ShiftContext.Consumer;
