import { useEffect } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const useUpdateCartDist = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { showNotification } = useRoot();
  const path = `/carts/tickets/distributed`;

  const [
    { result: resultUpdateCartDist, loading: loadingUpdateCartDist, error: errorUpdateCartDist },
    updateCartDist,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorUpdateCartDist) {
      showNotification(
        {
          message: t('alert_update_cart_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorUpdateCartDist, showNotification, t]);

  useEffect(() => {
    if (resultUpdateCartDist) {
      showNotification({
        message: t('alert_update_cart_success'),
      });
    }
  }, [resultUpdateCartDist, showNotification, t]);

  return [{ resultUpdateCartDist, loadingUpdateCartDist, errorUpdateCartDist }, updateCartDist];
};

export default useUpdateCartDist;
