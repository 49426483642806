import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { usePath } from 'utils/hooks';

const useCartTicketsWithUnavailableSeats = (cartId = null) => {
  const path = usePath(`carts/${cartId}/unavailable-seats`);
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE_CART);
  const { showNotification } = useRoot();
  const [
    { result: anyTicketsInCartWithSeatUnavailable, loading, error },
    getAnyTicketsInCartWithSeatUnavailable,
  ] = useApi(path, { initialFetch: false });
  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('tickets_in_cart_unavailable_seats'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t, cartId]);

  return [{ anyTicketsInCartWithSeatUnavailable, loading, error }, getAnyTicketsInCartWithSeatUnavailable];
};

export default useCartTicketsWithUnavailableSeats;
