export default {
  CanViewSeatTypes: 'Permission.SeatTypes.View',

  CanViewTicketTypes: 'Permission.TicketTypes.View',

  CanViewDiscounts: 'Permission.Discounts.View',
  CanUseDiscounts: 'Permission.Discounts.Use',

  CanViewAccreditations: 'Permission.Accreditations.View',

  CanUseCart: 'Permission.Cart.Use',
  CanDeleteCart: 'Permission.Cart.Delete',
  CanUseCard: 'Permission.Cart.PaymentCard',
  CanUseCash: 'Permission.Cart.PaymentCash',
  CanUseInvoice: 'Permission.Cart.PaymentInvoice',
  CanCancelTickets: 'Permission.Cart.CancelTickets',

  CanCreateReservations: 'Permission.Reservation.Create',
  CanUpdateReservations: 'Permission.Reservation.Update',
  CanDeleteReservations: 'Permission.Reservation.Delete',
  CanViewAllReservations: 'Permission.Reservation.ViewAll',
  CanViewPersonalReservations: 'Permission.Reservation.ViewPersonal',
  CanSetApprovals: 'Permission.Reservation.SetApproval',
  CanCancelReservations: 'Permission.Reservation.Cancel',
  CanPrintReservations: 'Permission.Reservation.Print',

  CanViewAllPrintLogs: 'Permission.PrintLog.ViewAll',
  CanViewPersonalPrintLogs: 'Permission.PrintLog.ViewPersonal',
};
