import React from 'react';
import { Title, Text as DefaultText } from 'components';
import styled from 'styled-components';
import moment from 'moment';

const ScreeningInfoDistributed = ({ screening }) => {
  const { title, code, date = null, location, startTime, venueName } = screening ? screening : {};
  return (
    <>
      <ScreeningTitle dangerouslySetInnerHTML={{ __html: title }} />
      <ScreeningInfoItems className="d-flex align-items-center mb-4 flex-wrap">
        {code && <Text>{code}</Text>}
        {location && <Text>{location}</Text>}
        {venueName && <Text>{venueName}</Text>}
        {date && <Text>{moment(date).format('DD.MM.YYYY')}</Text>}
        {startTime && <Text>{moment(startTime).format('DD.MM.YYYY')}</Text>}
      </ScreeningInfoItems>
    </>
  );
};

const ScreeningTitle = styled(Title)`
  font-size: 21px;
  line-height: 1.62;
`;
const ScreeningInfoItems = styled.div`
  > p + p:before {
    content: '|';
    margin: 0 0.5rem;
  }
`;

const Text = styled(DefaultText)`
  margin-bottom: 0;
`;

export default ScreeningInfoDistributed;
