import React from 'react';
import styled from 'styled-components';

const ParagraphText = props => <Wrapper {...props}>{props.children}</Wrapper>;

const Wrapper = styled.p`
  font-size: 16px;
  margin: auto;
  line-height: 1.57;
  color: var(--secondary);
`;

export default ParagraphText;
