import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

import { CURRENCY } from 'utils/constants';

const GRATIS = 'INVITATION';

const PrintTickets = ({ tickets, ticket }) => {
  const noPrice = price => {
    return price === 0 || price === 'INVITATION';
  };
  const handlePrice = ticket => {
    if (ticket) {
      if (noPrice(ticket.price)) return GRATIS;
      if (ticket.priceWithDiscount && ticket.priceWithDiscount !== null)
        return `${CURRENCY.BAM} ${Number.parseFloat(ticket.priceWithDiscount).toFixed(2)}`;
      return ticket.price ? `${CURRENCY.BAM} ${Number.parseFloat(ticket.price).toFixed(2)}` : '-';
    }
    return '-';
  };

  const screeningFontResize = length => {
    if (length > 35) {
      if (length > 48) {
        return '12pt';
      } else {
        return '15pt';
      }
    } else {
      return '19pt';
    }
  };

  if (ticket)
    return (
      <div className="ticket-print">
        <div className="page">
          <table style={{ width: '100%', color: 'black' }}>
            <tbody>
              <tr>
                <td style={{ fontSize: '19pt' }}>{ticket.screeningLocation}</td>
                <StyledTd rowSpan="7">
                  <table style={{ color: 'black' }}>
                    <tbody style={{ position: 'absolute', right: '0px' }}>
                      <tr>
                        <div style={{ position: 'relative' }}>
                          <td style={{ padding: '20px', position: 'absolute', right: '10px', bottom: '-105px' }}>
                            <QRCode value={`${ticket.ticketCode}`} size={145} />
                          </td>
                        </div>
                      </tr>
                      <tr>
                        <div style={{ position: 'relative' }}>
                          <td style={{ padding: '20px', position: 'absolute', right: '220px', bottom: '-20px' }}>
                            <QRCode value={`${ticket.ticketCode}`} size={60} />
                          </td>
                        </div>
                      </tr>
                      <tr>
                        <div style={{ position: 'relative' }}>
                          <td
                            style={{
                              position: 'absolute',
                              top: '85px',
                              right: '65px',
                              width: '70px',
                            }}
                          >
                            <div style={{ margin: 'auto', textAlign: 'center', fontSize: '16pt' }}>{ticket.id}</div>
                          </td>
                        </div>
                      </tr>
                      <tr>
                        <div style={{ position: 'relative' }}>
                          <td
                            style={{
                              position: 'absolute',
                              top: '0px',
                              right: '235px',
                              width: '70px',
                            }}
                          >
                            <div style={{ margin: 'auto', textAlign: 'center', fontSize: '12pt' }}>{ticket.id}</div>
                          </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </StyledTd>
              </tr>
              <tr>
                <div>
                  <td style={{ width: '60%', fontSize: ticket.screeningProgram?.length > 70 ? '9pt' : '10pt' }}>
                    {ticket.screeningProgram}
                  </td>
                </div>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: screeningFontResize(ticket.screeningTitle?.length),
                    width: '60%',
                    fontWeight: 'bold',
                    paddingTop: '3px',
                    color: 'black',
                  }}
                >
                  {ticket.screeningTitle}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: '17pt' }}>
                  {ticket.startDate} at {ticket.startTime}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: '17pt', fontWeight: 'bold' }}>{handlePrice(ticket)}</td>
              </tr>
              <tr>
                <td style={{ fontSize: '8pt' }}>{!noPrice(ticket.price) && 'PDV/VAT included in price'}</td>
              </tr>
              <tr>
                <td style={{ paddingTop: '1px' }}>
                  <SpanSeatType> {ticket.seatTypeName}</SpanSeatType>
                  {ticket.seatLabel && ticket.seatLabel.length > 0 && ticket.seatRow && ticket.seatNumber && (
                    <>
                      <SpanUppercaseText> | ROW: {ticket.seatRow}</SpanUppercaseText>
                      <SpanUppercaseText> | SEAT: {ticket.seatNumber}</SpanUppercaseText>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {ticket.accreditationCode && ticket.accreditationCode.length > 0 && (
                    <SpanUppercaseText>ACCR: {ticket.accreditationCode}</SpanUppercaseText>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <SpanSeatType> Ticket Code: {ticket.ticketCode}</SpanSeatType>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <style>
          {`
            .ticket-print {
                width: 88%;
                height: 100%;
                background: white;
                margin: 10px 0 10px 10px;
            }

            @page {
              size: landscape;
              size: auto;
            }

            @media screen {
              .ticket-print {display: none;}
            }

                  @media print {
                    html, body {
                        width: 153mm;
                        height: 51mm;
                        background-color: white;
                    }
                    .page {
                        margin: 10px;
                        width: initial;
                        max-height: initial;
                        max-width: initial;
                        color: black;
                        page-break-after: always;
                    }
                }
      `}
        </style>
      </div>
    );

  return (
    <div className="ticket-print">
      {tickets && tickets.length > 0
        ? tickets.map((ticket, index) => (
            <div key={index} className="page">
              <table style={{ width: '100%', height: '100%', color: 'black' }}>
                <tbody>
                  <tr>
                    <td style={{ fontSize: '19pt' }}>{ticket.location}</td>
                    <StyledTd rowSpan="7">
                      <table style={{ color: 'black' }}>
                        <tbody style={{ position: 'absolute', right: '0px' }}>
                          <tr>
                            <div style={{ position: 'relative', display: 'block' }}>
                              <td style={{ padding: '20px', position: 'absolute', right: '10px', bottom: '-105px' }}>
                                <QRCode value={`${ticket.ticketCode}`} size={145} />
                              </td>
                            </div>
                          </tr>
                          <tr>
                            <div style={{ position: 'relative', display: 'block' }}>
                              <td style={{ padding: '20px', position: 'absolute', right: '220px', bottom: '-20px' }}>
                                <QRCode value={`${ticket.ticketCode}`} size={60} />
                              </td>
                            </div>
                          </tr>
                          <tr>
                            <div style={{ position: 'relative' }}>
                              <td
                                style={{
                                  position: 'absolute',
                                  top: '85px',
                                  right: '65px',
                                  width: '70px',
                                }}
                              >
                                <div style={{ margin: 'auto', textAlign: 'center', fontSize: '16pt' }}>{ticket.id}</div>
                              </td>
                            </div>
                          </tr>
                          <tr>
                            <div style={{ position: 'relative' }}>
                              <td
                                style={{
                                  position: 'absolute',
                                  top: '0px',
                                  right: '235px',
                                  width: '70px',
                                }}
                              >
                                <div style={{ margin: 'auto', textAlign: 'center', fontSize: '12pt' }}>{ticket.id}</div>
                              </td>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </StyledTd>
                  </tr>
                  <tr>
                    <div>
                      <td style={{ width: '60%', fontSize: ticket.screeningProgram?.length > 70 ? '9pt' : '10pt' }}>
                        {ticket.screeningProgram}
                      </td>
                    </div>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: screeningFontResize(ticket.screeningName?.length),
                        width: '60%',
                        fontWeight: 'bold',
                        paddingTop: '3px',
                        color: 'black',
                      }}
                    >
                      {ticket.screeningName}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '17pt' }}>
                      {ticket.startDate} at {ticket.startTime}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '17pt', fontWeight: 'bold' }}>{handlePrice(ticket)}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '8pt' }}>{!noPrice(ticket.price) && 'PDV/VAT included in price'}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: '1px' }}>
                      <SpanSeatType> {ticket.seatTypeName}</SpanSeatType>
                      {ticket.seatLabel && ticket.seatLabel.length > 0 && ticket.seatRow && ticket.seatNumber && (
                        <>
                          <SpanUppercaseText> | ROW: {ticket.seatRow}</SpanUppercaseText>
                          <SpanUppercaseText> | SEAT: {ticket.seatNumber}</SpanUppercaseText>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {ticket.accreditationCode && ticket.accreditationCode.length > 0 && (
                        <SpanUppercaseText>ACCR: {ticket.accreditationCode}</SpanUppercaseText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SpanSeatType> Ticket Code: {ticket.ticketCode}</SpanSeatType>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
        : null}

      <style>
        {`
  .ticket-print {
      width: 88%;
      height: 100%;
      background: white;
      margin: 10px 0 10px 10px;
  }

  @page {
    size: landscape;
    size: auto;
  }

  @media screen {
    .ticket-print {display: none;}
  }

        @media print {
          html, body {
              width: 153mm;
              height: 51mm;
              background-color: white;
          }
          .page {
              margin: 10px;
              width: initial;
              max-height: initial;
              max-width: initial;
              color: black;
              page-break-after: always;
          }
      }
      `}
      </style>
    </div>
  );
};

const SpanSeatType = styled.span`
  font-size: 20px;
`;

const SpanUppercaseText = styled.span`
  text-transform: uppercase;
  font-size: 20px;
`;

const StyledTd = styled.td`
  padding-left: 80px;
  text-align: right;
`;

export default PrintTickets;
