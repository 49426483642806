import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft } from 'react-icons/bs';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

import Button from 'components/Button/Button';
import ReservationItem from 'components/Reservations/ReservationItem';
import ReservationFilterModal from './ReservationFilterModal';
import FilterInput from 'components/FilterInput/FilterInput';
import Loader from 'components/Loader/Loader';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { useToggle } from 'utils/hooks';
import { Text } from 'components/Typography';

const ReservationList = ({
  reservations,
  setShowReservations,
  setShowReservationDetails,
  setReservationId,
  onSearch,
  loading,
  query,
  setQuery,
}) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const [showFilterModal, setShowFilterModal] = useToggle(false);

  return (
    <div>
      <ReservationHeaderWrapper>
        <Left>
          <BackArrow onClick={setShowReservations} />
          <Title>
            <Text16>{t('all_reservations')}</Text16>
            <Detail>
              {reservations?.count ? reservations?.count : 0} {t('total')}
            </Detail>
          </Title>
        </Left>
        <Right>
          <FilterButton color="default" onClick={setShowFilterModal}>
            {t('filters')}
            <IconWrapper>
              <SortIconUp />
              <SortIconDown />
            </IconWrapper>
          </FilterButton>
        </Right>
      </ReservationHeaderWrapper>

      <SearchWrapper>
        <FilterInput onSearch={onSearch} placeholder={t('search_placeholder')} />
      </SearchWrapper>

      {loading ? (
        <div className="align-self-center" style={{ height: '100vh' }}>
          <Loader />
        </div>
      ) : (
        <>
          {reservations?.data?.length > 0 ? (
            <>
              <ReservationsWrapper className="flex-grow-1">
                {reservations?.data?.map((i, index) => (
                  <ReservationItem
                    key={index}
                    item={i}
                    setShowReservationDetails={setShowReservationDetails}
                    setReservationId={setReservationId}
                  />
                ))}
              </ReservationsWrapper>
            </>
          ) : (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <Text18>{t('no_reservations')}</Text18>
            </div>
          )}
        </>
      )}
      <ReservationFilterModal
        showFilterModal={showFilterModal}
        onSubmit={setShowFilterModal}
        onDecline={setShowFilterModal}
        query={query}
        setQuery={setQuery}
      />
    </div>
  );
};

const ReservationHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 3rem 1.5rem 3rem;
`;

const ReservationsWrapper = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 180px);
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;

  // scrollbar width
  ::-webkit-scrollbar {
    width: 6px;
  }

  // scrollbar Handle color
  ::-webkit-scrollbar-thumb {
    background: #676767;
  }

  // scrollbar Handle color on hover
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Text16 = styled(Text)`
  font-size: 16px;
`;

const Text18 = styled(Text)`
  font-size: 18px;
`;

const BackArrow = styled(BsArrowLeft)`
  font-size: 22px;
  margin-top: 0.9em;

  &:hover {
    cursor: pointer;
  }
`;

const Detail = styled.div`
  opacity: 0.5;
  font-size: 14px;
  margin: 0;
  line-height: 1.21;
  color: var(--white);
`;

const SearchWrapper = styled.div`
  padding: 0.5rem 4rem 0.5rem 4rem;
`;

const IconWrapper = styled.div`
  display: inline;
  position: relative;
  margin-left: 20px;
`;

const SortIconUp = styled(FaSortUp)`
  opacity: 30%;
  position: absolute;
  top: 3px;
  left: -10px;
`;

const SortIconDown = styled(FaSortDown)`
  opacity: 30%;
  position: absolute;
  top: 3px;
  left: -10px;
`;

const FilterButton = styled(Button)`
  height: 29px;
  font-size: 12px;
  padding: 0 1rem;
  width: fit-content;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const Right = styled(Left)`
  justify-content: right;
`;

const Title = styled.div`
  padding-left: 1em;
`;

export default ReservationList;
