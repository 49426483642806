import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown as ReactstrapDropdown, DropdownMenu } from 'reactstrap';

import { useToggle } from 'utils/hooks';

const Dropdown = ({ open = false, width, ToggleElem, children }) => {
  const [isOpen, setIsOpen] = useToggle(open);

  return (
    <ReactstrapDropdown isOpen={isOpen} toggle={setIsOpen}>
      <ToggleElem />
      <MenuWrapper width={width} right>
        {children}
      </MenuWrapper>
    </ReactstrapDropdown>
  );
};

const MenuWrapper = styled(DropdownMenu)`
  max-height: 270px;
  width: ${({ width }) => (width ? `${width}px` : '')};
  overflow: auto;
  background-color: ${({ color }) => (color === 'light' ? '' : '#2a2c30')};

  &&::-webkit-scrollbar {
    width: 5px;
    background: var(--darker);
  }
  &&::-webkit-scrollbar-thumb {
    background: var(--secondary);
  }
`;

Dropdown.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.string,
};

export default Dropdown;
