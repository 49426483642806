import { useSelector } from 'react-redux';
import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS } from 'utils/constants';
import { getUser } from 'redux/reducers/sessionReducer';

const useCart = (reservationId = null) => {
  const path = reservationId ? `${API_ENDPOINTS.CART}?reservationId=${reservationId}` : API_ENDPOINTS.CART;
  const user = useSelector(getUser);
  const [{ result: cart, loading, error, setResult: setCart }, getCart] = useApi(path, { initialFetch: !!user });

  return [{ cart, loading, error, setCart }, getCart];
};

export default useCart;
