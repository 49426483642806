import React, { createContext, useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';

import {
  useScreeningSeats,
  useAddReservedSeats,
  useScreeningSeatTypes,
  // useCart,
  useScreening,
  useCustomerAccreditationNumber,
  useTicketType,
} from 'utils/hooks/api';
import { useToggle } from 'utils/hooks';
import { usePurchaseContext } from 'context/PurchaseContext';
import { MARKINGS, ORIENTATION, TICKET_TYPE_SELECTORS } from 'utils/constants';
import { generateLabels } from 'utils/helpers';

export const ReservedPurchaseContext = createContext();

export const ReservedPurchaseProvider = props => {
  const [colLabels, setColLabels] = useImmer(['']); // added an empty item for spacing
  const [rowLabels, setRowLabels] = useImmer([]);
  const [layout, setLayout] = useImmer([]);
  const [selectedSeats, setSelectedSeats] = useImmer([]);
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [screening, setScreening] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ticketTypeSelectorId, setTicketTypeSelectorId] = useState(TICKET_TYPE_SELECTORS.REGULAR);
  const [selectorCapacities, setSelectorCapacities] = useState(null); // Setting (Regular, Accreditation, Gratis) capacities
  const [cartIds, setCartIds] = useState([]); // Used for handling selected seats when they are in cart
  const [accreditationNumber, setAccreditationNumber] = useState('');
  const [accreditationCartNumber, setAccreditationCartNumber] = useState(null); // Customer accreditation barcode
  const [isAccRemainSet, setIsAccRemainSet] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [selectedAccCount, setSelectedAccCount] = useState(0);
  const [{ seats, loading: seatsLoading }, getSeats, setSeats] = useScreeningSeats(screening ? screening.id : null);
  const [{ resultScreening, loadingScreening }, getScreening, setApiScreening] = useScreening(
    screening ? screening.id : null
  );
  const [{ seatTypes, loading: seatTypesLoading }, getSeatTypes, setSeatTypesResult] = useScreeningSeatTypes(
    screening ? screening.id : null
  );

  //! QUICK FIX SOLUTION
  //! Cart and all of its properties and methods should be placed in useCartContext
  //! so that both ReservedPurchaseContext and PurchaseContext inherit those values
  const { cart, loadingCart: cartLoading, getCart, reservationId } = usePurchaseContext();

  const [{ result: reservedTicketsPayment, loading: addingSeatsLoading }, addReservedSeats] = useAddReservedSeats();
  const [
    { accreditation, loading: loadingAccreditation, error: errorAccreditation },
    getAccreditation,
    setAccreditation,
    setAccreditationError,
  ] = useCustomerAccreditationNumber(accreditationNumber, screening?.id);
  const [{ ticketType, loading: ticketTypeLoading }, getTicketType, setTicketType] = useTicketType(
    accreditation ? accreditation.ticketTypeId : null
  );

  const fetchData = useCallback(async () => {
    await getSeats();
    await getScreening();
    await getCart();
    await getSeatTypes();
  }, [getSeats, getScreening, getSeatTypes, getCart]);

  const handleLoading = useCallback(() => {
    if (
      !seatsLoading &&
      !loadingScreening &&
      !seatTypesLoading &&
      !cartLoading &&
      !loadingAccreditation &&
      seats &&
      resultScreening &&
      seatTypes
    ) {
      setIsLoading(false);
    }
  }, [
    seatsLoading,
    seatTypesLoading,
    cartLoading,
    seats,
    seatTypes,
    loadingScreening,
    resultScreening,
    loadingAccreditation,
  ]);

  // Removing accreditation errors
  const limitErrorHandler = useCallback(() => {
    if (accreditationNumber) setAccreditationNumber('');
    if (accreditationCartNumber) setAccreditationCartNumber(null);
    if (errorAccreditation) setAccreditationError(null);
  }, [accreditationNumber, errorAccreditation, setAccreditationError, accreditationCartNumber]);

  const onConfirmAccreditation = useCallback(() => {
    if (accreditation) setAccreditation(null);
    getAccreditation();
  }, [accreditation, setAccreditation, getAccreditation]);

  // 'Remove' button from accreditations info
  const onRemoveAccreditation = useCallback(() => {
    setAccreditation(null);
    setTicketType(null);
    setRemaining(0);
    setIsAccRemainSet(false);
    setSelectedAccCount(0);
    limitErrorHandler();
    setSelectedSeats(draft => draft.filter(s => s.ticketTypeSelectorId !== TICKET_TYPE_SELECTORS.ACCREDITATION));
  }, [setAccreditation, limitErrorHandler, setTicketType, setSelectedSeats]);

  // Clearing accreditation number input field and errors upon closing modal
  const unSelectScreening = useCallback(() => {
    setIsModalOpen();
    if (accreditationNumber) setAccreditationNumber('');
    if (accreditationCartNumber) setAccreditationCartNumber(null);
    limitErrorHandler();
    setSelectedSeats(() => []);
  }, [
    setIsModalOpen,
    accreditationNumber,
    limitErrorHandler,
    accreditationCartNumber,
    setAccreditationCartNumber,
    setSelectedSeats,
  ]);

  const viewReservedSeating = useCallback(
    screening => {
      setScreening(screening);
    },
    [setScreening]
  );

  const onSelectScreening = useCallback(
    scr => {
      setIsLoading(true);

      // Setting Regular sale when new screening is selected
      if (ticketTypeSelectorId !== TICKET_TYPE_SELECTORS.REGULAR)
        setTicketTypeSelectorId(TICKET_TYPE_SELECTORS.REGULAR);

      // Updating seats, cart, seatTypes and customer accreditation when user clicks "Purchase" button for screening that is already selected
      if (screening && screening.id === scr.id) {
        setCartIds([]);
        fetchData();
        getCart();
        if (accreditationNumber) getAccreditation();
      }

      // If new screening is selected clear all data frow previus screening
      if (screening && screening.id !== scr.id) {
        if (accreditation) onRemoveAccreditation();
        if (ticketTypeSelectorId !== TICKET_TYPE_SELECTORS.REGULAR)
          setTicketTypeSelectorId(TICKET_TYPE_SELECTORS.REGULAR);
        setSeats(null);
        setApiScreening(null);
        setSeatTypesResult(null);
        setTicketType(null);
        setSelectorCapacities(null);
        setSelectedSeats(() => []);
        setCartIds([]);
        setLayout(() => []);
      }

      setScreening(scr);
      setIsModalOpen();
    },
    [
      setIsModalOpen,
      ticketTypeSelectorId,
      screening,
      getCart,
      setApiScreening,
      setSeats,
      setSeatTypesResult,
      setTicketType,
      setLayout,
      accreditation,
      accreditationNumber,
      getAccreditation,
      onRemoveAccreditation,
      fetchData,
      setSelectedSeats,
    ]
  );

  const handleCart = useCallback(() => {
    if (cart && screening) {
      // Finding if selected screening has values in cart
      let cartScreening = cart.cartScreenings.find(s => s.screeningId === screening.id);

      if (cartScreening) {
        let arr = [];
        let arr2 = [];

        // Making an array of seatIds
        cartScreening.seatTypes.forEach(seatsArr => {
          // Don't add seatIds if accreditationNumbers don' match
          if (seatsArr.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION && !accreditation) {
            return arr;
          }

          arr = [...arr, ...seatsArr.seatIds];
        });

        // Finding corresponding values for seatIds
        if (seats && seats.reservedSeats.reservedSeats.length > 0)
          arr.forEach(i => {
            let item = seats.reservedSeats.reservedSeats.find(s => s.id === i);
            if (
              ticketType &&
              item.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION &&
              ticketType.id !== item.ticketTypeId
            )
              return arr2;

            arr2 = [...arr2, item];
          });

        setCartIds(arr);
        setSelectedSeats(draft => {
          if (draft !== arr2) {
            return arr2;
          }
        });
      }
    }
  }, [cart, screening, seats, setSelectedSeats, accreditation, ticketType]);

  // Handling accreditations barcode if accreditation is in the cart
  const handleCustomerAccreditation = useCallback(() => {
    if (cart && screening) {
      let cartScreening = cart.cartScreenings.find(s => s.screeningId === screening.id);

      if (cartScreening) {
        let accreditation = cartScreening.seatTypes.find(
          a => a.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION
        );

        if (accreditation) {
          setAccreditationNumber(accreditation.accreditationNumber);
          setAccreditationCartNumber(accreditation.accreditationNumber);
        }
      }
    }
  }, [cart, screening]);

  // Hanling count for selected accreditations
  const handleAccSeatsCount = useCallback(() => {
    let count = 0;
    selectedSeats.forEach(s => {
      if (s.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION) {
        count++;
      }
    });

    setSelectedAccCount(count);
  }, [selectedSeats]);

  const handleUnavailableSeats = useCallback(
    (seat, ticketTypeId) => {
      if (seat?.ticketTypeSelectorId !== ticketTypeSelectorId) return true;
      if (selectedSeats.find(s => s.id === seat.id) && seat.ticketTypeSelectorId === ticketTypeSelectorId) return false;

      if (ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION && ticketType) {
        if (ticketTypeId !== ticketType.id) return true;

        // If 'remaining' is 0, disable accreditation seats
        if (!remaining) {
          return true;
        }
      }

      return false;
    },
    [ticketType, ticketTypeSelectorId, selectedSeats, remaining]
  );

  const handleRemainingAccCount = useCallback(() => {
    const { ticketsUsedForScreening, ticketsUsedForDay, ticketsLimitDaily, ticketsLimitPerScreening } = accreditation;
    let count;

    if (ticketsLimitDaily <= ticketsLimitPerScreening) {
      if (ticketsLimitDaily === 0) {
        count = ticketsUsedForDay;
      } else {
        count = ticketsLimitDaily - ticketsUsedForDay + selectedAccCount;
      }
    } else {
      if (ticketsLimitPerScreening === 0) {
        count = ticketsUsedForDay;
      } else {
        count = ticketsLimitPerScreening - ticketsUsedForScreening + selectedAccCount;
      }
    }

    setRemaining(count);
    setIsAccRemainSet(true);
  }, [accreditation, selectedAccCount]);

  const generateHeadLabels = useCallback(() => {
    setRowLabels(draft => {
      draft.splice(0, draft.length);
      // generate labels for rows
      const areRowsReversed = resultScreening?.columnsOrientation?.toString() === ORIENTATION.reverse.name.toString();
      let labels = generateLabels(areRowsReversed, resultScreening?.rowsCount);

      if (resultScreening?.rowsCount) {
        for (let i = 0; i < resultScreening?.rowsCount; i++) {
          draft.push(
            resultScreening?.rowsMarking.toLowerCase() === MARKINGS.letters.name.toLowerCase()
              ? labels.alphabetLabels[i]
              : labels.numericLabels[i]
          );
        }
      }
    });

    setColLabels(draft => {
      draft.splice(1, draft.length); // do not delete the empty cell, start from 1

      // generate labels for columns
      const areColumnsReversed = resultScreening?.rowsOrientation?.toString() === ORIENTATION.reverse.name.toString();
      let labels = generateLabels(areColumnsReversed, resultScreening?.columnsCount);

      if (resultScreening?.columnsCount) {
        for (let j = 0; j < resultScreening?.columnsCount; j++) {
          draft.push(
            resultScreening?.columnsMarking.toLowerCase() === MARKINGS.letters.name.toLowerCase()
              ? labels.alphabetLabels[j]
              : labels.numericLabels[j]
          );
        }
      }
    });
  }, [resultScreening, setColLabels, setRowLabels]);

  const generateMatrix = useCallback(() => {
    if (
      !seats ||
      !seats.reservedSeats ||
      !seats.reservedSeats.reservedSeats ||
      seats.reservedSeats.reservedSeats.length < 1
    )
      return [];
    const matrix = [];

    for (let i = 0; i < resultScreening.rowsCount; i++) {
      matrix.push([]);
      for (let j = 0; j < resultScreening.columnsCount; j++) {
        const seat = seats.reservedSeats.reservedSeats.find(s => s.row === i && s.number === j);
        const ticketTypeSelectorId = seat?.ticketTypeSelectorId;
        const seatTypeId = seat?.seatTypeId;
        const isAvailable = seat?.isAvailable;
        const label = isAvailable ? seat?.label : '';
        const isDisabled = handleUnavailableSeats(seat, seat?.ticketTypeId);
        const isSold = seat?.isSold && !cartIds.includes(seat.id);
        const isSelected = selectedSeats.find(s => s.id === seat.id) ? true : false;
        const permissions = seat?.permissions;

        const item = {
          id: seat?.id,
          row: i,
          number: j,
          label,
          isAvailable,
          isSelected,
          isDisabled,
          isSold,
          ticketTypeSelectorId,
          seatTypeId,
          permissions,
        };

        matrix[i][j] = item;
      }
    }
    return matrix;
  }, [resultScreening, seats, cartIds, handleUnavailableSeats, selectedSeats]);

  const handleSeat = useCallback(
    (x, y) => {
      if (!layout[x][y].isDisabled && !layout[x][y].isSold) {
        if (!layout[x][y].isSelected)
          setSelectedSeats(draft => {
            if (ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION) setRemaining(prevState => prevState - 1);
            return [...draft, layout[x][y]];
          });
        if (layout[x][y].isSelected) {
          if (ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION) setRemaining(prevState => prevState + 1);
          setSelectedSeats(draft => draft.filter(s => s.id !== layout[x][y].id));
        }
      }
    },
    [layout, setSelectedSeats, ticketTypeSelectorId]
  );

  const onSubmit = useCallback(async () => {
    const regular = {
      ticketTypeSelectorId: TICKET_TYPE_SELECTORS.REGULAR,
      seatIds: [],
    };
    const accreditations = {
      ticketTypeSelectorId: TICKET_TYPE_SELECTORS.ACCREDITATION,
      seatIds: [],
    };
    const gratis = {
      ticketTypeSelectorId: TICKET_TYPE_SELECTORS.GRATIS,
      seatIds: [],
    };

    // Filtering layout and assinging values to corresponding arrays
    layout
      .reduce((a, b) => a.concat(b), [])
      .filter(i => i.isSelected)
      .forEach(i => {
        if (i.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.REGULAR) regular.seatIds.push(i.id);
        if (i.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION) accreditations.seatIds.push(i.id);
        if (i.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.GRATIS) gratis.seatIds.push(i.id);
      });

    // Setting accreditationId value
    if (accreditation && accreditations.seatIds.length > 0) {
      accreditations.accreditationId = accreditation.id;
    }

    let values = {
      screeningId: screening.id,
      ticketInserts: [regular, accreditations, gratis],
      cartId: reservationId ? cart.id : null,
    };

    await addReservedSeats(values);
    await getCart();
    setIsModalOpen();
    setSelectedSeats(() => []);
  }, [
    addReservedSeats,
    screening,
    setIsModalOpen,
    layout,
    accreditation,
    setSelectedSeats,
    getCart,
    reservationId,
    cart,
  ]);

  const init = useCallback(() => {
    generateHeadLabels();
    const matrix = generateMatrix();
    setLayout(draft => {
      draft.splice(0, draft.length);
      matrix.forEach(m => {
        draft.push(m);
      });
    });
    setSelectorCapacities(seats.reservedSeats.ticketTypeSelectors);
  }, [generateHeadLabels, generateMatrix, setLayout, seats]);

  useEffect(() => {
    handleLoading();
  }, [handleLoading]);

  useEffect(() => {
    if (!screening) {
      return;
    }

    fetchData();
  }, [screening, fetchData, setLayout]);

  useEffect(() => {
    if (resultScreening && seats && seatTypes && seats.reservedSeats.reservedSeats.length > 1) {
      init();
    }
  }, [seats, init, seatTypes, cart, resultScreening, handleCart]);

  useEffect(() => {
    handleCart();
  }, [handleCart]);

  useEffect(() => {
    handleCustomerAccreditation();
  }, [handleCustomerAccreditation]);

  useEffect(() => {
    // Setting remaining limit for customer accreditation
    if (accreditation) {
      handleAccSeatsCount();
      if (!isAccRemainSet) handleRemainingAccCount();
    }
  }, [accreditation, handleAccSeatsCount, handleRemainingAccCount, isAccRemainSet]);

  // Handling if there is accreditationNumber (barcode) already in cart
  useEffect(() => {
    if (accreditationCartNumber && accreditationNumber && !accreditation) getAccreditation();
  }, [accreditationCartNumber, getAccreditation, accreditationNumber, accreditation]);

  // Handling ticketType
  useEffect(() => {
    if (accreditation && !ticketType) {
      getTicketType();
    }
  }, [accreditation, getTicketType, ticketType]);

  //Handle clear cart
  useEffect(() => {
    if (!cart) {
      setScreening(null);
      setSeats(null);
      setApiScreening(null);
      setSeatTypesResult(null);
      setTicketType(null);
      setSelectorCapacities(null);
      setSelectedSeats(() => []);
      setCartIds([]);
      setLayout(() => []);
    }
  }, [
    setSeats,
    setApiScreening,
    setSeatTypesResult,
    setTicketType,
    setSelectorCapacities,
    setSelectedSeats,
    setCartIds,
    setLayout,
    cart,
  ]);

  const context = useMemo(
    () => ({
      screening,
      selectedSeats,
      isModalOpen,
      onSelectScreening,
      unSelectScreening,
      isLoading,
      colLabels,
      rowLabels,
      layout,
      handleSeat,
      setTicketTypeSelectorId,
      ticketTypeSelectorId,
      seatTypes,
      onSubmit,
      addingSeatsLoading,
      selectorCapacities,
      resultScreening,
      accreditationNumber,
      setAccreditationNumber,
      accreditation,
      loadingAccreditation,
      errorAccreditation,
      getAccreditation,
      ticketType,
      onRemoveAccreditation,
      selectedAccCount,
      ticketTypeLoading,
      viewReservedSeating,
      remaining,
      onConfirmAccreditation,
      reservedTicketsPayment,
      cartIds,
      setCartIds,
    }),
    [
      screening,
      selectedSeats,
      isModalOpen,
      onSelectScreening,
      unSelectScreening,
      isLoading,
      colLabels,
      rowLabels,
      layout,
      handleSeat,
      setTicketTypeSelectorId,
      ticketTypeSelectorId,
      seatTypes,
      onSubmit,
      addingSeatsLoading,
      selectorCapacities,
      resultScreening,
      accreditationNumber,
      setAccreditationNumber,
      accreditation,
      loadingAccreditation,
      errorAccreditation,
      getAccreditation,
      ticketType,
      onRemoveAccreditation,
      selectedAccCount,
      ticketTypeLoading,
      viewReservedSeating,
      remaining,
      onConfirmAccreditation,
      reservedTicketsPayment,
      cartIds,
      setCartIds,
    ]
  );

  return <ReservedPurchaseContext.Provider value={context}>{props.children}</ReservedPurchaseContext.Provider>;
};

export function useReservedPurchaseContext() {
  const context = useContext(ReservedPurchaseContext);
  if (!context) {
    throw new Error(`ReservedPurchaseContext must be used within a ReservedPurchaseProvider`);
  }
  return context;
}

export const Consumer = ReservedPurchaseContext.Consumer;
