import React from 'react';
import { ScreeningItem } from 'components';
import { useTranslation } from 'react-i18next';

import { LOCALIZATION_PAGES } from 'utils/constants';

const ScreeningItems = ({ screenings }) => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);

  return screenings && screenings.data && screenings.data.length > 0 ? (
    screenings.data.map((screening, index) => <ScreeningItem key={`${screening.id}-${index}`} screening={screening} />)
  ) : (
    <>
      <div>{tCommon('no_entries')}</div>
    </>
  );
};

export default ScreeningItems;
