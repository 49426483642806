import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useLanguages = () => {
  const path = API_ENDPOINTS.LANGUAGES_ASSIGNED_TO_FILMS;
  const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);
  const { showNotification } = useRoot();
  const [{ result: languages, loading, error }, getLanguages] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('languages_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ languages, loading, error }, getLanguages];
};

export default useLanguages;
