import {
  Card as BootstrapCard,
  CardHeader as BootstrapCardHeader,
  CardFooter as BootstrapCardFooter,
  CardBody as BootstrapCardBody,
  CardTitle as BootstrapCardTitle,
  CardText as BootstrapCardText,
} from 'reactstrap';
import styled from 'styled-components';

export const Card = styled(BootstrapCard)`
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 0.07);
`;

export const CardHeader = styled(BootstrapCardHeader)`
  background-color: transparent;
  // divider color
  border-bottom: 1px solid rgba(234, 237, 243, 0.13);
  padding: 1.25rem;
`;
export const CardBody = styled(BootstrapCardBody)`
  padding: 1.25rem;
`;

export const CardFooter = styled(BootstrapCardFooter)`
  background-color: transparent;
  border-top: 1px solid rgba(234, 237, 243, 0.13);
  padding: 1.25rem;
  // && :first-child {
  //   margin-right: 10px;
  // }
`;

export const CardTitle = styled(BootstrapCardTitle)`
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
`;
export const CardSubtitle = styled(BootstrapCardText)`
  color: #9ea0a5;
  font-family: 'Roboto';
  font-size: 14px;
`;
