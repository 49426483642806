import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  TabContent,
  TabPane,
  Nav as ReactstrapNav,
  NavItem as ReactstrapNavItem,
  NavLink as ReactstrapNavLink,
} from 'reactstrap';

import { TAB_TYPES } from 'utils/constants';

const Tabs = ({ children, selected, handleClick, type = TAB_TYPES.DEFAULT, tabwidth }) => {
  const [active, setActive] = useState(selected || 0);

  useEffect(() => {
    if (selected) {
      setActive(selected);
    }
  }, [selected, setActive]);

  const handleTabClick = index => {
    if (handleClick) handleClick(index);
    setActive(index);
  };

  let Nav;
  if (type === TAB_TYPES.SWITCH) {
    Nav = SwitchNav;
  } else if (type === TAB_TYPES.PURCHASE) {
    Nav = PurchaseNav;
  } else {
    Nav = DefaultNav;
  }

  const NavLink = type === TAB_TYPES.PURCHASE ? CustomNavLink : ReactstrapNavLink;
  const NavItem = type === TAB_TYPES.PURCHASE ? FullWidthNavItem : ReactstrapNavItem;

  return (
    <>
      <Nav tabs>
        {type === TAB_TYPES.SWITCH && <Indicator indexPosition={active} />}
        {children &&
          children.map((elem, index) => {
            const label = elem && elem.props ? elem.props.label : null;
            return (
              <NavItem key={index || label}>
                <NavLink
                  className={active === index ? 'active' : ''}
                  onClick={() => handleTabClick(index)}
                  disabled={elem.props.disabled}
                >
                  {label}
                </NavLink>
              </NavItem>
            );
          })}
      </Nav>
      <CustomTabContent activeTab={active} tabwidth={tabwidth}>
        {children &&
          children.map((elem, index) => {
            return (
              <TabPane tabId={index} key={index}>
                {children[index]}
              </TabPane>
            );
          })}
      </CustomTabContent>
    </>
  );
};

const DefaultNav = styled(ReactstrapNav)`
  .nav-link {
    border: 0;
    cursor: pointer;
    padding-bottom: 1rem;
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-link.active,
  .nav-link:hover,
  .nav-item.show .nav-link {
    background-color: transparent;
    color: var(--dark);
    border: 0;
    position: relative;
    :after {
      content: '';
      width: 100%;
      height: 3px;
      z-index: 5;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--primary);
    }
  }
`;

const SwitchNav = styled(ReactstrapNav)`
  height: 55px;
  border-radius: 28px;
  border: solid 1px #707070;
  width: 332px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  .nav-item {
    width: 50%;
    cursor: pointer;
    z-index: 4;
  }
  .nav-link {
    font-size: 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: var(--light);
  }
  .nav-link.active,
  .nav-link:hover,
  .nav-item.show .nav-link {
    background-color: transparent;
    border: 0;
    color: var(--light);
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: 0;
  ${({ indexPosition }) =>
    indexPosition
      ? css`
          left: calc(50% - 4px);
        `
      : css`
          left: 0;
        `};
  background-color: var(--primary);
  width: 50%;
  border-radius: 28px;
  z-index: 2;
  height: calc(100% - 4px);
  margin: 2px;
  transition: all 0.3s ease-in-out;
`;

const CustomTabContent = styled(TabContent)`
  width: ${({ tabwidth }) => (tabwidth ? tabwidth : '')};
`;

const PurchaseNav = styled(DefaultNav)`
  border-bottom: none;
  > .nav-item:first-child .nav-link {
    border-top-left-radius: 4px;
  }
  > .nav-item:last-child .nav-link {
    border-top-right-radius: 4px;
  }
`;

const FullWidthNavItem = styled(ReactstrapNavItem)`
  display: flex;
  flex-grow: 1;
  width: 191px;
`;

const CustomNavLink = styled(ReactstrapNavLink)`
  flex-grow: 1 !important;
  background-color: #672d45 !important;

  &&.active,
  &&:hover {
    opacity: 1;
    background-color: var(--primary) !important;
    border-bottom: 3px solid var(--white);
  }
`;

export default Tabs;
