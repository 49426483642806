import moment from 'moment';

import { LANGUAGES } from 'utils/constants';

export const getDefaultLanguage = () => {
  const lang = LANGUAGES.find(l => l.DEFAULT);
  return lang || LANGUAGES[0];
};

export const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getMonth = date => {
  return monthNames[date.getMonth()];
};

export const getYear = date => date.getFullYear();

export const escapeNull = (value, char = '-') => (value ? value : char);

export const handleData = data => (data || data === 0 ? data : '-');

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const generateNumericArray = arrLength => {
  return Array.from(Array(arrLength), (_, i) => i + 1);
};

// used on Venues, Screenings, ScreeningPreview and ReservedSeatingPreview
export const generateLabels = (isReversed, lablesLength) => {
  let alphabetLabels = [];
  let numericLabels = [];

  if (isReversed) {
    alphabetLabels = alphabet.slice(0, lablesLength).reverse();
    numericLabels = generateNumericArray(lablesLength).reverse();
  } else {
    alphabetLabels = alphabet.slice(0, lablesLength + 1);
    numericLabels = generateNumericArray(lablesLength);
  }

  let generatedLabels = [];
  generatedLabels['alphabetLabels'] = alphabetLabels;
  generatedLabels['numericLabels'] = numericLabels;

  return generatedLabels;
};

export const generatePreviewMatrix = (x, seatTypes, ticketTypes, seats, cartIds) => {
  if (!seats || !seats.data || seats.data.length < 1) return [];
  const matrix = [];
  if (x && seatTypes && ticketTypes && seats) {
    for (let i = 0; i < x.rowsCount; i++) {
      matrix.push([]);
      for (let j = 0; j < x.columnsCount; j++) {
        let item = {};

        let defaultSeat = null;
        let defaultTicket = null;
        let isSold = null;
        if (seatTypes) defaultSeat = seatTypes.data.find(st => st.isDefault);
        if (ticketTypes) defaultTicket = ticketTypes?.data.find(tt => tt.isDefault);

        let seatTypeId = defaultSeat.id;
        let ticketTypeId = defaultTicket.id;

        let isAvailable = true;
        let seatId = null;
        let isSelected = null;

        if (seats && seats.data) {
          const seat = seats.data.find(s => s.row === i && s.number === j);
          seatTypeId = seat?.seatTypeId;
          ticketTypeId = seat?.ticketTypeId;
          isAvailable = seat?.isAvailable;
          seatId = seat?.id;
          isSold = seat?.isSold;
          isSelected = cartIds.includes(seat?.id);

          item = {
            row: i,
            number: j,
            label: isAvailable ? seat?.label : '',
            seatTypeId,
            ticketTypeId,
            isAvailable,
            id: seatId,
            isSold,
            isSelected,
          };
        }
        matrix[i][j] = item;
      }
    }
  }

  return matrix;
};

export const toLocalDateTime = date =>
  moment
    .parseZone(date)
    .local()
    .toDate();

export const toLocalDate = (start, format) =>
  start && format
    ? moment(moment.utc(start).toDate())
        .local()
        .format(format)
    : '-';

export function isAllowed([operator, ...values], authData) {
  const evaluator = v => {
    if (typeof v === 'function') {
      return v(authData);
    } else if (Array.isArray(v)) {
      return isAllowed(v, authData);
    } else if (typeof v === 'boolean') {
      return v;
    } else if (typeof v === 'string') {
      const { user } = authData;
      return user && user?.permissions?.indexOf(v) !== -1;
    } else {
      console.warn('Unknown type for evaluation');
    }
  };

  switch (operator) {
    case 'all':
      return values.every(evaluator);
    case 'any':
      return values.some(evaluator);
    case 'none':
      return !values.some(evaluator);
    default:
      console.error('Invalid operator. Will not allow!');
      return false;
  }
}
