import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GoSearch } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

import { Input } from 'components/Form';
import { DEBOUNCE_TIMEOUT, LOCALIZATION_PAGES } from 'utils/constants';
import { useDebounce } from 'utils/hooks';

const FilterInput = ({ searchTerm = '', placeholder = '', onSearch, ...rest }) => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  if (!placeholder) placeholder = tCommon('search_text');
  const [keyword, setKeyword] = useState(searchTerm);
  const debouncedSearchTerm = useDebounce(keyword, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  const onChange = e => {
    const search = e.target.value;
    setKeyword(search);
  };

  return (
    <InputWrapper>
      <SearchIcon />
      <Container placeholder={placeholder} onChange={onChange} value={keyword} {...rest} />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
`;

const SearchIcon = styled(GoSearch)`
  width: 23px;
  height: 23px;
  position: absolute;
  top: 18px;
  left: 20px;
  z-index: 1;
  color: var(--white);
  ${'@-moz-document url-prefix()'} {
    & {
      top: 25px;
    }
  }
`;

const Container = styled(Input)`
  padding-left: 4rem;
  background-color: rgba(255, 255, 255, 0.02);
  color: var(--white) !important;
  border: solid 1px rgba(226, 229, 237, 0.11);
  border-radius: 29px;

  &&:focus {
    background-color: inherit;
    outline: none !important;
    border-color: var(--secondary);
    box-shadow: 0px 0px 0px 4px var(--dark);
  }

  &&:disabled {
    background-color: var(--dark);
  }
`;

export default FilterInput;
