import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, API_ENDPOINTS, LOCALIZATION_PAGES } from 'utils/constants';

const usePrintLog = query => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PRINT_LOG);
  const { showNotification } = useRoot();
  const queryString = `${API_ENDPOINTS.TICKETS}?StartDateTime=${query.startDate}&EndDateTime=${query.endDate}&Term=${query.term}&UserId=${query.userId}&Status=${query.status}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const [path, setPath] = useState(queryString);
  const [{ result: tickets, loading, error }, getPrintLog] = useApi(path);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('fetch_tickets_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ tickets, loading, error }, getPrintLog];
};

export default usePrintLog;
