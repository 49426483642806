import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Title } from 'components/Typography';
import moment from 'moment';

const Countdown = ({ startDate, onFinish }) => {
  const currentTime = moment(new Date());
  const eventTime = moment(startDate);
  const countdown = moment(eventTime - currentTime);
  const [duration, setDuration] = useState(countdown.valueOf());
  const interval = 1000;

  useEffect(() => {
    if (duration <= 0) onFinish();
    const timer = duration > 0 && setInterval(() => setDuration(duration - interval), 1000);
    return () => clearInterval(timer);
  }, [duration, onFinish]);

  const inDays = moment.duration(duration).asDays();
  const days = Math.floor(inDays);
  const daysRemainder = inDays - Math.floor(inDays);

  const inHours = moment.duration(daysRemainder, 'days').asHours();
  const hours = Math.floor(inHours);
  const hoursRemainder = inHours - Math.floor(inHours);

  const inMinutes = moment.duration(hoursRemainder, 'hours').asMinutes();
  const minutes = Math.floor(inMinutes);
  const minutesRemainder = inMinutes - Math.floor(inMinutes);

  const inSeconds = moment.duration(minutesRemainder, 'minutes').asSeconds();
  const seconds = Math.floor(inSeconds);

  return <CountdownText>{`${days} Days ${hours} hrs ${minutes} mins ${seconds} secs`}</CountdownText>;
};

const CountdownText = styled(Title)`
  font-size: 19px;
  font-weight: 500;
  line-height: 1.26;
  text-align: center;
  margin: 1.5rem 0;
`;

export default Countdown;
