import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getShift } from 'redux/reducers/sessionReducer';

import routes from 'utils/routes';
import { FilmProgrammes, Text, Label, Progress, Button, PurchaseButton } from 'components';
import { LOCALIZATION_PAGES, SCREENING_STATUS, SALE_TYPES, SHIFT_MODES } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import Tooltip from 'components/Tooltip/Tooltip';
import { usePurchaseContext } from 'context/PurchaseContext';

const seatUrl = `${process.env.PUBLIC_URL}/assets/icons/chair.svg`;

const ScreeningItem = ({ screening }) => {
  const history = useHistory();
  const shift = useSelector(getShift);
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);

  const { resultGlobalSalesSettings } = usePurchaseContext();

  const { isEnabled, isPaused, hasStarted } = resultGlobalSalesSettings ? resultGlobalSalesSettings : {};
  const isGlobalSaleDisabled = !isEnabled || isPaused || !hasStarted;

  const {
    id,
    code = '-',
    title = '-',
    startTime = null,
    durationHumanReadable = '-',
    status = '-',
    totalSeatsSold = '-',
    totalCapacity = 0,
    ticketTypeSelectorCapacities = '-',
    location = null,
    screeningSaleTypes = [],
    festivalProgrammes = [],
    isPosSaleEnabled = false,
    hasEnded = false,
    isSoldOut = false,
  } = screening ? screening : {};

  let saleType = {};
  screeningSaleTypes.forEach(type => {
    if (type?.saleType?.name === SALE_TYPES.POS.name) {
      saleType = type;
    }
  });

  let hasShiftStarted = shift === SHIFT_MODES.STARTED;

  const currentDate = moment.utc(new Date()).toDate();
  let startPOSSaleTime = moment
    .parseZone(saleType?.startTime)
    .local()
    .toDate();
  let endPOSSaleTime = moment
    .parseZone(saleType?.endTime)
    .local()
    .toDate();
  const hasPOSSaleStarted = moment(currentDate).diff(startPOSSaleTime) > 0;
  const hasPOSSaleEnded = moment(endPOSSaleTime).diff(moment(currentDate)) < 0;
  const hasLayout = totalCapacity !== 0;

  let { venueName = null } = screening ? screening : {};
  let isDisabled = false;
  screeningSaleTypes.forEach(screening => {
    if (screening?.saleType?.name === SALE_TYPES.POS.name) {
      isDisabled =
        isGlobalSaleDisabled ||
        !isPosSaleEnabled ||
        hasEnded ||
        isSoldOut ||
        !screening?.isEnabled ||
        screening?.isPaused ||
        !hasPOSSaleStarted ||
        hasPOSSaleEnded ||
        !hasLayout ||
        !hasShiftStarted;
    }
  });

  const soldOut = status === SCREENING_STATUS.SOLD; // ??
  let date = startTime ? moment(startTime).format('DD.MM.YYYY') : null;
  let time = startTime ? moment(startTime).format('HH:mm') : null;
  let ticketSelectorTranslations = '';
  time = screening.time ? screening.time : time;
  date = screening.date ? moment(screening.date).format('DD.MM.YYYY') : date;
  let shortTitle = title.length > 80 ? `${title.substring(0, 80)}...` : title;
  let filmProgrammesCount = festivalProgrammes.length ? festivalProgrammes.length : null;

  if (venueName && venueName.length > 28) {
    venueName = `${venueName.substring(0, 28)}...`;
  }

  const goToScreening = () => {
    history.push({
      pathname: `${routes.SCREENINGS.path}/${id}`,
    });
  };

  ticketSelectorTranslations = ticketSelectorId => {
    switch (ticketSelectorId) {
      case -2:
        return t('regular_sale');
      case -1:
        return t('accreditations');
      case -4:
        return t('gratis');
      case -3:
        return t('no_sale');
      default:
        break;
    }
  };

  return (
    <Row className="my-2">
      <Col className="d-flex">
        <ScreeningDate className="d-flex flex-column align-items-center justify-content-between">
          <div>
            {time && <CustomTimeText>{time}</CustomTimeText>}
            {date && <CustomText>{date}</CustomText>}
          </div>
          {code && <CustomText className="font-weight-bold">{code}</CustomText>}
          {venueName && <CustomText>{venueName}</CustomText>}
          {location && <CustomText>{location}</CustomText>}
        </ScreeningDate>

        <ScreeningDetails id={`screening-${id}`}>
          <div className="d-flex justify-content-center flex-column w-100">
            <ScreeningTitle
              onClick={() => goToScreening()}
              dangerouslySetInnerHTML={{ __html: shortTitle }}
            ></ScreeningTitle>
            {title !== shortTitle && (
              <Tooltip placement={'top-start'} target={`screening-${id}`}>
                {title}
              </Tooltip>
            )}
            <div className="d-flex justify-content-start">
              <ScreeningDuration>{durationHumanReadable}</ScreeningDuration>

              {filmProgrammesCount && (
                <Wrapper id={`filmProgrammes-${id}`}>
                  <FilmProgrammes
                    filmProgrammes={
                      filmProgrammesCount === 1
                        ? festivalProgrammes
                        : [`${filmProgrammesCount} ${t('festival_programs')}`]
                    }
                    noMargin={true}
                  ></FilmProgrammes>
                  {filmProgrammesCount > 1 && (
                    <Tooltip placement={'bottom'} target={`filmProgrammes-${id}`}>
                      {festivalProgrammes.map(s => (
                        <React.Fragment>
                          {s}
                          <br />
                        </React.Fragment>
                      ))}
                    </Tooltip>
                  )}
                </Wrapper>
              )}
            </div>
            <Progress max={totalCapacity} value={totalSeatsSold} />
            <div className="d-flex align-items-center ">
              <SeatIcon src={seatUrl} />
              <SeatsInfo>
                {soldOut ? (
                  <span className="text-uppercase">{t('sold_out')}</span>
                ) : (
                  `${totalSeatsSold}/${totalCapacity}`
                )}
              </SeatsInfo>
              <div className="d-flex ml-3">
                {ticketTypeSelectorCapacities != null &&
                  ticketTypeSelectorCapacities.map(t => (
                    <SeatsInfo key={t.ticketTypeSelectorId}>
                      {`${ticketSelectorTranslations(t.ticketTypeSelectorId)}: ${t.totalSeatsSold}/${
                        t.capacity ? t.capacity : '0'
                      }`}{' '}
                    </SeatsInfo>
                  ))}
              </div>
            </div>
          </div>
          <ButtonWrapper status={status} md={3} className="d-flex flex-column justify-content-center">
            <PurchaseButton block disabled={isDisabled} screening={screening}>
              {t('purchase')}
            </PurchaseButton>
            <Button block color="default" onClick={() => goToScreening()}>
              {t('details')}
            </Button>
          </ButtonWrapper>
        </ScreeningDetails>
      </Col>
    </Row>
  );
};

const Wrapper = styled.div`
  margin-left: 5%;
`;

const ScreeningDate = styled.div`
  width: 160px;
  height: 160px;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.18);
`;

const ScreeningDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 160px;
  margin-left: 0.4rem;
  margin-right: 0;
  background-color: rgba(255, 255, 255, 0.07);
  padding: 0 2rem;
`;

const CustomTimeText = styled(Text)`
  font-size: 28px;
  margin-top: 0.7rem;
  margin-bottom: 0;
  text-align: center;
`;

const CustomText = styled(Text)`
  font-size: 14px;
  margin: 0.2rem;
  text-align: center;
`;

const ScreeningTitle = styled.h4`
  color: var(--white);
  font-size: 22px;
  line-height: 1.55;
  &:hover {
    cursor: pointer;
  }
`;

const ScreeningDuration = styled(Label)`
  font-size: 16px;
  line-height: 1.3;
  text-transform: lowercase;
  min-width: fit-content;
`;

const SeatIcon = styled.img`
  width: 17.8px;
  height: 19.2px;
  object-fit: contain;
  margin-right: 1rem;
`;

const SeatsInfo = styled(Text)`
  font-size: 18px;
  margin-right: 0.8rem;
`;

const ButtonWrapper = styled.div`
  opacity: ${({ status }) => (status === 'sold' ? '0.3' : '1')};
  margin-left: 2rem;
`;

export default ScreeningItem;
