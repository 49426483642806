import { object, string } from 'yup';

const USER_SCHEMA = object().shape({
  firstName: string().required('user_profile:required_first_name'),
  lastName: string().required('user_profile:required_last_name'),
  email: string()
    .email('user_profile:invalid_email')
    .required('user_profile:required_email'),
  // phoneNumber: number().required('user:required_phone_number'),
  // country: string().required('user:required_country'),
  // city: string().required('user:required_city'),
  username: string().required('user_profile:required_username'),
});

export default USER_SCHEMA;
