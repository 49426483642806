import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

const useScreenings = query => {
  const queryString = `${API_ENDPOINTS.SCREENINGS}?StartDate=${query.startDate}&EndDate=${query.endDate}&Term=${
    query.term
  }&VenueIds=${query.venues.join(', ')}&Status=${query.status}&PageSize=${query.pageSize}&Page=${
    query.page
  }&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${
    query.sortOrder
  }&Includes=${query.includes}`;
  const signalRPath = `${process.env.REACT_APP_API_URL}/screeningCapacityHub`;
  const [path, setPath] = useState(queryString);
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const [connection, setConnection] = useState();
  const { showNotification } = useRoot();
  const [message, setMessage] = useState(null);

  const [{ result: screenings, loading, error, setResult }, getScreenings] = useApi(path, {
    initialFetch: true,
  });

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('fetching_screenings_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(signalRPath, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, [signalRPath]);

  useEffect(() => {
    if (message && screenings) {
      setResult({
        ...screenings,
        data: screenings.data.map(item => (item.id === message.id ? message : item)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        connection.on('ScreeningChanged', msg => {
          setMessage(msg);
        });
      });
    }
    return () => {
      connection && connection.stop('ScreeningChanged');
    };
  }, [connection]);

  return [{ screenings, loading, error }, getScreenings];
};

export default useScreenings;
