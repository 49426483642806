import React from 'react';
import PropTypes from 'prop-types';
import { Button as ReacstrapButton, Spinner } from 'reactstrap';
import styled, { css } from 'styled-components';

const Button = ({ width = null, color = 'primary', loading, disabled, children, ...rest }) => (
  <CustomButton width={width} color={color} disabled={disabled || loading} {...rest}>
    {loading ? <Spinner color="light" size="sm" /> : children}
  </CustomButton>
);

const CustomButton = styled(ReacstrapButton)`
  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
        `
      : null}
  height: 49px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: transparent;
  line-height: 1.38;
  padding: 0 30px;
  border-radius: 39px;
  color: var(--white);
  ${({ color }) => {
    if (color === 'default') {
      return css`
        color: var(--white);
        border: 1px solid #707070;
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(91, 92, 94, 0.5);
        }
        &:hover {
          color: var(--secondary);
        }
      `;
    }
    if (color === 'primary') {
      return css`
        background-color: #f63275;
        border: none;
      `;
    }
  }}
  svg {
    margin-bottom: 3px;
    margin-right: 1rem;
  }
  &:disabled {
    cursor: auto;
  }
`;

Button.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
};

export default Button;
