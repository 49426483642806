import React from 'react';
import styled from 'styled-components';

const InitialsAvatar = ({ fullName, firstName, lastName, ...rest }) => {
  return (
    <Avatar {...rest}>
      {firstName && firstName[0]}
      {lastName && lastName[0]}
      {fullName && fullName.split(' ')[0][0]}
      {fullName && fullName.split(' ')[1][0]}
    </Avatar>
  );
};

const Avatar = styled.div`
  width: ${({ size }) => (size ? `${size}px` : '38px')};
  height: ${({ size }) => (size ? `${size}px` : '38px')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  background-color: rgba(0, 0, 0, 0.11);
  border: solid 1px rgba(226, 229, 237, 0.11);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light);
`;

export default InitialsAvatar;
