import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal as ReactstrapModal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import PrintTickets from 'components/Payment/PrintTickets';
import ReactToPrint from 'react-to-print';
import Button from 'components/Button/Button';
import { PAYMENT_TABS, LOCALIZATION_PAGES } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import useCheckAuth from 'utils/hooks/api/useCheckAuth';

const PaymentModal = ({
  isOpen = false,
  title,
  onConfirm,
  onDecline = () => {},
  confirmText,
  declineText,
  loading,
  children,
  confirmDisabled,
  isCustomHeader,
  showDecline,
  nav,
  tickets,
  printDisabled,
  printDisabledText,
  ...rest
}) => {
  const [{ resultCheckAuth, loadingCheckAuth }, checkAuth] = useCheckAuth();
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const componentRef = useRef();
  const printTicketsBtnRef = useRef();

  useEffect(() => {
    if (resultCheckAuth && !loadingCheckAuth && printTicketsBtnRef.current) {
      const btn = printTicketsBtnRef.current.querySelector('button');
      if (btn) btn.click();
    }
  }, [resultCheckAuth, loadingCheckAuth]);

  return (
    <Modal isOpen={isOpen} toggle={onDecline} {...rest}>
      {!isCustomHeader ? <Header toggle={onDecline}>{title}</Header> : <CustomHeader />}

      <ModalBody>{children}</ModalBody>
      <Footer>
        {nav !== PAYMENT_TABS.PROCESSING_PAYMENT && showDecline ? (
          <Button color="primary" loading={loading} onClick={onConfirm} disabled={confirmDisabled}>
            {confirmText}
          </Button>
        ) : null}
        {nav === PAYMENT_TABS.PROCESSING_PAYMENT && (
          <div>
            <Button disabled={printDisabled || loadingCheckAuth} onClick={() => checkAuth()}>
              {t('print_tickets')}
            </Button>
            <div ref={printTicketsBtnRef} hidden>
              <ReactToPrint
                trigger={() => <Button disabled={printDisabled || loadingCheckAuth}>{t('print_tickets')}</Button>}
                content={() => componentRef.current}
                onAfterPrint={onConfirm}
              />
            </div>
            <div ref={componentRef} style={{ width: '100%' }}>
              <PrintTickets tickets={tickets} />
            </div>
          </div>
        )}

        <Button color={showDecline ? 'default' : 'primary'} onClick={onDecline}>
          {declineText}
        </Button>
      </Footer>
    </Modal>
  );
};

const Header = styled(ModalHeader)`
  font-size: 21px;
  font-weight: 500;
  padding: 2rem 1.5rem;
  border-bottom: 1px solid #424242;
`;

const CustomHeader = styled.div`
  font-size: 21px;
  font-weight: 500;
  padding: 2rem 1.5rem;
  border-bottom: none;
`;

const Footer = styled(ModalFooter)`
  justify-content: flex-start;
  padding: 1rem 1.3rem;
  border-top: 1px solid #424242;
`;

const Modal = styled(ReactstrapModal)`
  min-width: 590px;
  color: var(--white) !important;

  .modal-content {
    background-color: #2e3034;
  }
`;

PaymentModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  declineText: PropTypes.string,
};

export default PaymentModal;
