import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button/Button';

const SecondaryButton = ({ children, ...rest }) => {
  return (
    <Wrapper color="light" {...rest}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Button)`
  box-shadow: 0px 1px 1px 0 rgba(22, 29, 37, 0.05);
  border: solid 1px #707070;

  &:hover {
    background-color: #3c4048;
    color: var(--white);
    border: solid 1px #707070;
  }
`;

export default SecondaryButton;
