import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import InitialsAvatar from 'components/InitialsAvatar/InitialsAvatar';
import { Text, Button } from 'components';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { handleData } from 'utils/helpers';
import { LOCALIZATION_PAGES } from 'utils/constants';

const AccreditationInfo = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { accreditation, onRemoveAccreditation } = useReservedPurchaseContext();
  const {
    customerFullName,
    barcode,
    ticketsLimitDaily,
    ticketsLimitPerScreening,
    ticketsUsedForDay,
    ticketsUsedForScreening,
  } = accreditation;
  const [firstName, lastName] = customerFullName ? customerFullName.split(' ') : ['-', '-'];

  return (
    accreditation && (
      <>
        <InitialsAvatar firstName={firstName} lastName={lastName} size={57} fontSize={22} />
        <Wrapper>
          <Name>{handleData(customerFullName)}</Name>
          <Code>{handleData(barcode)}</Code>
          <CustomText
            dangerouslySetInnerHTML={{
              __html: t('daily_limit', {
                ticketsLimitDaily: handleData(ticketsLimitDaily),
                todayAndLimit: `${handleData(ticketsUsedForDay)}/${handleData(ticketsLimitDaily)}`,
              }),
            }}
          />
          <CustomText
            dangerouslySetInnerHTML={{
              __html: t('screening_limit', {
                ticketsLimitPerScreening: handleData(ticketsLimitPerScreening),
                screeningAndLimit: `${handleData(ticketsUsedForScreening)}/${handleData(ticketsLimitPerScreening)}`,
              }),
            }}
          />
          <RemoveButton color="default" onClick={onRemoveAccreditation}>
            {tCommon('remove')}
          </RemoveButton>
        </Wrapper>
      </>
    )
  );
};

const Wrapper = styled.div`
  margin-top: 0.9rem;
  margin-bottom: 3rem;
`;

const Name = styled(Text)`
  font-size: 18px;
  margin-bottom: 0;
`;

const Code = styled(Text)`
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  font-weight: 300;
`;

const CustomText = styled(Text)`
  font-weight: 300;
`;

const RemoveButton = styled(Button)`
  margin-top: 1rem;
  border-radius: 4px;
  height: 49px;
  width: 140px;
`;

export default AccreditationInfo;
