import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import { Label as DefaultLabel } from 'components';
import { Input } from 'components/Form';
import { LOCALIZATION_PAGES } from 'utils/constants';

const DiscountModal = ({
  isDiscount,
  addDiscountLoading,
  discountCode,
  onSubmit,
  onDecline,
  handleChange,
  validationMessage,
}) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.DISCOUNT);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);

  return (
    <Modal
      centered
      customSize="580"
      title={t('discounts')}
      confirmText={tCommon('apply')}
      declineText={tCommon('cancel')}
      loading={addDiscountLoading}
      isOpen={isDiscount}
      buttonDisabled={!discountCode}
      onConfirm={onSubmit}
      onDecline={onDecline}
    >
      <div className="w-100">
        <Label>{t('discounts_label')}</Label>
        <div className="d-flex align-items-center">
          <InputWrapper>
            <Input onChange={handleChange} value={discountCode} invalid={validationMessage && true} />
          </InputWrapper>
        </div>
        {validationMessage && <ErrorMsg>{validationMessage}</ErrorMsg>}
      </div>
    </Modal>
  );
};

const InputWrapper = styled.div`
  width: 100%;
  & input {
    background-color: rgba(0, 0, 0, 0.11);
    border: solid 1px rgba(226, 229, 237, 0.11);
    height: 49px;
    color: var(--white);
    &:focus {
      background-color: rgba(0, 0, 0, 0.11);
      border: solid 1px rgba(226, 229, 237, 0.11);
      color: var(--white);
    }
  }
  & .is-invalid {
    border-color: #ff004e;
    background: none;
  }
  & .is-invalid:focus {
    border-color: #ff004e;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.19);
  }
`;

const Label = styled(DefaultLabel)`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.21;
  text-transform: none;
  color: var(--white);
  margin-bottom: 1rem;
`;

const ErrorMsg = styled.p`
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  color: #ff004e;
`;

export default DiscountModal;
