import React, { useEffect, useMemo, useState, useContext, useCallback, useRef } from 'react';

import { useToggle } from 'utils/hooks';
import {
  useSeatAvailability,
  useUpdateCartDist,
  useCart,
  useDeleteCart,
  useCustomerAccreditationNumber,
  useCartTicketsPayment,
  useGlobalSalesSettings,
} from 'utils/hooks/api';

import { useImmer } from 'use-immer';

const PurchaseContext = React.createContext();

export const PurchaseProvider = props => {
  const [reservationId, setReservationId] = useState(null);
  const [purchaseDistOpen, togglePurchaseDist] = useToggle(false);
  const [selectedScreening, setSelectedScreening] = useState(null);
  const [{ seatAvailability, loadingAvailability }, getSeatAvailability] = useSeatAvailability(
    selectedScreening ? selectedScreening.id : null
  );
  const [{ resultUpdateCartDist, loadingUpdateCartDist, errorUpdateCartDist }, updateCartDist] = useUpdateCartDist();
  const [{ cart, loading: loadingCart, error: errorCart, setCart }, getCart] = useCart(reservationId);
  const [{ deleteResultCart, loadingDeleteCart }, deleteCart] = useDeleteCart();
  const [
    { resultTicketsPayment, loadingTicketsPayment, errorTicketsPayment },
    ticketsPayment,
  ] = useCartTicketsPayment();
  const [{ resultGlobalSalesSettings, loadingGlobalSalesSettings }, getGlobalSalesSettings] = useGlobalSalesSettings();

  const [accreditationNumber, setAccreditationNumber] = useState('');
  const [
    { accreditation, loading: loadingAccreditation, error: errorAccreditation },
    getAccreditation,
    setAccreditation,
  ] = useCustomerAccreditationNumber(accreditationNumber, selectedScreening?.id);

  const [screeningAccreditationNumber, setScreeningAccreditationNumber] = useState('');

  const formRef = useRef();

  const [initialValues, setInitialValues] = useImmer({
    cartId: null,
    screeningId: null,
    ticketInserts: [],
  });

  const handleRemoveAccreditation = useCallback(() => {
    setAccreditationNumber('');
    setScreeningAccreditationNumber(null);
    setAccreditation(null);
    setInitialValues(draft => {
      try {
        draft.ticketInserts
          .find(tts => tts.ticketTypeSelectorId === -1)
          .ticketSeatTypes.forEach(sts => {
            delete sts.accreditationId;
            delete sts.quantity;
            sts.quantity = 0;
          });
      } catch (err) {
        return;
      }
    });
  }, [setAccreditationNumber, setAccreditation, setInitialValues]);

  const calcTotalAvailable = useCallback(
    (totalAvailable, ticketTypeSelectorId) => {
      let totalInCart = 0;
      let cartScreening = cart?.cartScreenings?.find(screening => screening.screeningId === selectedScreening.id);
      if (cartScreening && cartScreening.seatTypes)
        cartScreening.seatTypes.forEach(seat => {
          if (seat.ticketTypeSelectorId === ticketTypeSelectorId) totalInCart += seat.count;
        });
      return totalAvailable + totalInCart;
    },
    [cart, selectedScreening]
  );

  const calcTicketQuantity = useCallback(
    (tts, sts) => {
      if (tts.totalAvailableSeats < 0) return 0;
      let ticketQuantity = 0;
      let cartScreening = null;
      let seat = null;
      if (cart && cart.cartScreenings)
        cartScreening = cart.cartScreenings.find(screening => screening.screeningId === selectedScreening.id);
      if (cartScreening && cartScreening.seatTypes)
        seat = cartScreening.seatTypes.find(
          seatType =>
            seatType.ticketTypeSelectorId === tts.ticketTypeSelectorId && seatType.seatTypeId === sts.seatTypeId
        );
      if (seat) ticketQuantity = seat.count;
      // In case admin reduces capacity, meanwhile there is an amount of tickets higher than that
      if (tts.totalAvailableSeats < ticketQuantity) return tts.totalAvailableSeats;
      return ticketQuantity;
    },
    [cart, selectedScreening]
  );

  const openCart = useCallback(
    screening => {
      togglePurchaseDist();
      setSelectedScreening(screening);
    },
    [togglePurchaseDist, setSelectedScreening]
  );

  const clearCart = useCallback(async () => {
    if (cart) await deleteCart();
  }, [deleteCart, cart]);

  const clearCartLocal = useCallback(async () => {
    if (cart) {
      setCart(null);
      setReservationId(null);
    }
  }, [setCart, setReservationId, cart]);

  useEffect(() => {
    if (deleteResultCart && !loadingDeleteCart) {
      setCart(null);
    }
  }, [deleteResultCart, loadingDeleteCart, setCart]);

  const addToCart = useCallback(
    async values => {
      if (reservationId) {
        values.cartId = cart.id;
      }
      await updateCartDist(values);
      setInitialValues(draft => {
        draft.ticketInserts = values.ticketInserts;
        draft.cartId = values.cartId;
      });
      getSeatAvailability();
      getCart();
      if (accreditationNumber) getAccreditation();
      togglePurchaseDist();
    },
    [
      accreditationNumber,
      updateCartDist,
      setInitialValues,
      togglePurchaseDist,
      getSeatAvailability,
      getCart,
      getAccreditation,
      reservationId,
      cart,
    ]
  );

  const handleFormSubmit = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const setUsedAccreditaton = useCallback(() => {
    let cartScreening = null;
    if (cart && cart.cartScreenings) {
      cartScreening = cart.cartScreenings.find(screening => screening.screeningId === selectedScreening.id);
      if (cartScreening && cartScreening.seatTypes) {
        let seatType = null;
        seatType = cartScreening.seatTypes.find(x => x.ticketTypeSelectorId === -1);
        if (seatType) {
          setScreeningAccreditationNumber(seatType.accreditationNumber);
          setAccreditationNumber(seatType.accreditationNumber);
        }
      }
    }
  }, [cart, selectedScreening]);

  const updateInitialValues = useCallback(() => {
    setInitialValues(draft => {
      draft.ticketInserts.forEach(tts => {
        tts.totalAvailableSeats = calcTotalAvailable(tts.totalAvailableSeats, tts.ticketTypeSelectorId);
        tts.ticketSeatTypes.forEach(sts => {
          sts.quantity = calcTicketQuantity(tts, sts);
          sts.totalSoldSeats = sts.totalSoldSeats - calcTicketQuantity(tts, sts);
        });
      });
    });
  }, [setInitialValues, calcTotalAvailable, calcTicketQuantity]);

  const handleInitialValues = useCallback(
    (id, screeningTicketTypeSelectors) => {
      setInitialValues(draft => {
        draft.screeningId = id;
        draft.ticketInserts = screeningTicketTypeSelectors.map(tts => ({
          totalAvailableSeats: tts.totalAvailable - tts.totalNonAvailable,
          totalSeats: tts.totalAvailable,
          ticketTypeSelectorId: tts.ticketTypeSelectorId,
          ticketSeatTypes: tts.screeningSelectorSeatTypes
            .filter(sts => sts.totalSeats > 0)
            .map(sts => ({
              seatTypeId: sts.seatTypeId,
              seatTypeName: sts.seatTypeName,
              totalSeats: sts.totalSeats,
              totalSoldSeats: sts.totalSoldSeats,
              price: tts.ticketTypeSelectorId === -2 ? sts.price : 0,
              quantity: 0,
              permissions: sts.permissions,
            })),
        }));
      });
    },
    [setInitialValues]
  );

  const handleAccreditationValues = useCallback(
    (id, screeningTicketTypeSelectors) => {
      let accreditationTickets = screeningTicketTypeSelectors.find(tts => tts.ticketTypeSelectorId === -1);

      setInitialValues(draft => {
        draft.screeningId = id;
        draft.ticketInserts = [
          ...draft.ticketInserts.filter(tts => tts.ticketTypeSelectorId !== -1),
          {
            ticketTypeSelectorId: -1,
            totalAvailableSeats: accreditationTickets.totalAvailable - accreditationTickets.totalNonAvailable,
            totalSeats: accreditationTickets.totalAvailable,
            totalNonAvailable: accreditationTickets.totalNonAvailable,
            ticketSeatTypes: accreditation?.screeningSelectorSeatTypes
              .filter(sst => sst.totalSeats > 0)
              .map(sts => {
                return {
                  accreditationId: accreditation.id,
                  seatTypeId: sts.seatTypeId,
                  seatTypeName: sts.seatTypeName,
                  totalSeats: sts.totalSeats,
                  totalSoldSeats: sts.totalSoldSeats,
                  price: 0,
                  quantity: 0,
                  permissions: sts.permissions,
                };
              }),
          },
        ];
      });
    },
    [setInitialValues, accreditation]
  );

  useEffect(() => {
    if (purchaseDistOpen) {
      getSeatAvailability();
      getCart();
    }
  }, [purchaseDistOpen, getSeatAvailability, getCart]);

  useEffect(() => {
    if (errorUpdateCartDist) {
      getSeatAvailability();
      getCart();
    }
  }, [errorUpdateCartDist, getSeatAvailability, getCart]);

  useEffect(() => {
    if (selectedScreening && seatAvailability?.distributedSeats?.screeningTicketTypeSelectors && !loadingAvailability) {
      handleInitialValues(selectedScreening.id, seatAvailability.distributedSeats.screeningTicketTypeSelectors);
    }
  }, [selectedScreening, seatAvailability, loadingAvailability, handleInitialValues]);

  useEffect(() => {
    if (
      accreditation &&
      selectedScreening &&
      seatAvailability?.distributedSeats?.screeningTicketTypeSelectors &&
      !loadingAvailability
    ) {
      handleAccreditationValues(selectedScreening.id, seatAvailability.distributedSeats.screeningTicketTypeSelectors);
    }
  }, [selectedScreening, seatAvailability, loadingAvailability, handleAccreditationValues, accreditation]);

  useEffect(() => {
    if (seatAvailability && !loadingAvailability && cart && !loadingCart && !errorCart) {
      updateInitialValues();
    }
  }, [updateInitialValues, cart, loadingCart, seatAvailability, loadingAvailability, errorCart]);

  useEffect(() => {
    if (seatAvailability) setUsedAccreditaton();
  }, [setUsedAccreditaton, seatAvailability]);

  useEffect(() => {
    if (accreditation && !loadingAccreditation) {
      setInitialValues(draft => {
        draft.ticketInserts
          .find(tts => tts.ticketTypeSelectorId === -1)
          .ticketSeatTypes.forEach(sts => {
            sts.accreditationId = accreditation.id;
          });
      });
    }
  }, [setInitialValues, accreditation, loadingAccreditation]);

  useEffect(() => {
    if (screeningAccreditationNumber) {
      getAccreditation();
    }
  }, [screeningAccreditationNumber, getAccreditation]);

  const context = useMemo(
    () => ({
      purchaseDistOpen,
      togglePurchaseDist,
      loadingAvailability,
      seatAvailability,
      openCart,
      clearCart,
      addToCart,
      initialValues,
      resultUpdateCartDist,
      loadingUpdateCartDist,
      formRef,
      handleFormSubmit,
      errorUpdateCartDist,
      selectedScreening,
      getSeatAvailability,
      getCart,
      accreditationNumber,
      setAccreditationNumber,
      accreditation,
      loadingAccreditation,
      errorAccreditation,
      getAccreditation,
      setAccreditation,
      cart,
      handleRemoveAccreditation,
      loadingCart,
      clearCartLocal,
      reservationId,
      setReservationId,
      resultTicketsPayment,
      loadingTicketsPayment,
      errorTicketsPayment,
      ticketsPayment,
      resultGlobalSalesSettings,
      loadingGlobalSalesSettings,
      getGlobalSalesSettings,
    }),
    [
      purchaseDistOpen,
      togglePurchaseDist,
      seatAvailability,
      loadingAvailability,
      openCart,
      clearCart,
      addToCart,
      initialValues,
      resultUpdateCartDist,
      loadingUpdateCartDist,
      formRef,
      handleFormSubmit,
      errorUpdateCartDist,
      selectedScreening,
      getSeatAvailability,
      getCart,
      accreditationNumber,
      setAccreditationNumber,
      accreditation,
      loadingAccreditation,
      errorAccreditation,
      getAccreditation,
      setAccreditation,
      cart,
      handleRemoveAccreditation,
      loadingCart,
      clearCartLocal,
      reservationId,
      setReservationId,
      resultTicketsPayment,
      loadingTicketsPayment,
      errorTicketsPayment,
      ticketsPayment,
      resultGlobalSalesSettings,
      loadingGlobalSalesSettings,
      getGlobalSalesSettings,
    ]
  );

  return <PurchaseContext.Provider value={context}>{props.children}</PurchaseContext.Provider>;
};

export function usePurchaseContext() {
  const context = useContext(PurchaseContext);
  if (!context) {
    throw new Error(`usePurchaseContext must be used within a PurchaseProvider`);
  }
  return context;
}

export const Consumer = PurchaseContext.Consumer;
