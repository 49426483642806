import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from 'components/Typography';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { handleData } from 'utils/helpers';
import { LOCALIZATION_PAGES, TICKET_TYPE_SELECTORS, CURRENCY } from 'utils/constants';

const SeatsLegend = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
  const { seatTypes, ticketTypeSelectorId } = useReservedPurchaseContext();

  const allSeatsHandler = useCallback(
    dbSeats => {
      return (
        dbSeats && [
          ...dbSeats,
          { screeningId: 4, color: '#d9d9d9', seatTypeName: t('unavailable_seat'), opacity: '0.2;' },
          { screeningId: 5, color: '#f63275', seatTypeName: t('sold_seat') },
          { screeningId: 6, color: '#1e2025', seatTypeName: t('selected_seat'), border: true },
        ]
      );
    },
    [t]
  );

  const allSeats = useMemo(() => allSeatsHandler(seatTypes), [seatTypes, allSeatsHandler]);

  const priceHandler = price =>
    price > -1 &&
    `${Number.parseFloat(ticketTypeSelectorId === TICKET_TYPE_SELECTORS.REGULAR ? price : 0).toFixed(2)} ${
      CURRENCY.BAM
    }`;

  return (
    <Wrapper>
      {allSeats &&
        allSeats.map((s, i) => {
          return (
            <React.Fragment key={`${s.screening ? s.screening.id : s.screeningId}.${i}`}>
              <SeatWrapper>
                <div className="d-flex my-2">
                  <SeatColor color={s.color} border={s.border} opacity={s.opacity} />
                  <CustomText>{handleData(s.seatType ? s.seatType.name : s.seatTypeName)}</CustomText>
                </div>
                <CustomText>{priceHandler(s.price)}</CustomText>
              </SeatWrapper>
              {i === allSeats.length - 4 && <br />}
            </React.Fragment>
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 300px;
`;

const SeatWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SeatColor = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  border: ${({ border }) => border && '1px solid #f63275'};
  background-color: ${({ color }) => (color ? color : null)};
  opacity: ${({ opacity }) => (opacity ? opacity : null)};
`;

const CustomText = styled(Text)`
  font-size: 18px;
  margin: 0;
`;

export default SeatsLegend;
