import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import TicketInfo from 'components/TicketCancelation/TicketInfo';
import TicketInput from 'components/TicketCancelation/TicketInput';
import { useTicketByCode, useVoidTicket } from 'utils/hooks/api';
import { LOCALIZATION_PAGES, TICKET_STATUSES } from 'utils/constants';

const TicketCancelationModal = ({ isTicketCancelation, setIsTicketCancelation }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.TICKET_CANCELATION);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const [ticketNumber, setTicketNumber] = useState('');
  const [validationMessage, setValidationMessage] = useState(null);
  const [{ ticket, loading: ticketLoading, error }, getTicket, setTicket] = useTicketByCode(ticketNumber);
  const [{ loading: voidTicketLoading }, voidTicket] = useVoidTicket(ticket?.id);

  const handleTicket = useCallback(() => {
    if (ticket.status === TICKET_STATUSES.DRAFT) {
      setValidationMessage(t('ticket_in_cart'));
    } else if (ticket.status === TICKET_STATUSES.CANCELLED) {
      setValidationMessage(t('ticket_voided'));
    } else {
      setValidationMessage(null);
    }
  }, [ticket, t]);

  const handleError = useCallback(() => {
    if (error) {
      setValidationMessage(t('invalid_ticket_number'));
    } else {
      setValidationMessage(null);
    }
  }, [error, t]);

  useEffect(() => {
    if (ticket) return handleTicket();

    handleError();
  }, [ticket, handleTicket, handleError]);

  const handleChange = e => {
    if (validationMessage) setValidationMessage(null);
    setTicketNumber(e.target.value);
  };

  const inputOnClick = () => {
    if (ticket) setTicket(null);
    getTicket();
  };

  const onConfirm = async () => {
    await voidTicket();
    onDecline();
  };

  const onDecline = () => {
    setIsTicketCancelation();
    setTicketNumber('');
    if (validationMessage) setValidationMessage(null);
    if (ticket) setTimeout(() => setTicket(null), 400);
  };

  const isSubmitBtnDisabled = () => {
    if (!ticket) return true;
    if (ticket.status !== TICKET_STATUSES.PAID && ticket.status !== TICKET_STATUSES.USED) return true;

    return false;
  };

  return (
    <Modal
      centered
      customSize="580"
      title={t('cancel_tickets')}
      confirmText={t('void_ticket')}
      declineText={tCommon('cancel')}
      isOpen={isTicketCancelation}
      onConfirm={onConfirm}
      onDecline={onDecline}
      buttonDisabled={isSubmitBtnDisabled()}
      loading={voidTicketLoading}
    >
      <TicketInput
        handleChange={handleChange}
        ticketNumber={ticketNumber}
        inputOnClick={inputOnClick}
        ticketLoading={ticketLoading}
        validationMessage={validationMessage}
      />
      {ticket && (ticket.status === TICKET_STATUSES.PAID || ticket.status === TICKET_STATUSES.USED) && (
        <TicketInfo ticket={ticket || {}} />
      )}
    </Modal>
  );
};

export default TicketCancelationModal;
