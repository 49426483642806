import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const useTickets = (cartId = null) => {
  const path = `/carts/tickets?cartId=${cartId}`;

  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { showNotification } = useRoot();

  const [{ result: tickets, loading: loadingTickets, error: errorTickets }, getTickets] = useApi(path, {
    initialFetch: false,
  });

  useEffect(() => {
    if (errorTickets) {
      showNotification(
        {
          message: t('tickets_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorTickets, showNotification, t]);

  return [{ tickets, loadingTickets, errorTickets }, getTickets];
};

export default useTickets;
