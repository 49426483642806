import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { usePurchaseContext } from 'context/PurchaseContext';

const iconPath = `${process.env.PUBLIC_URL}/assets/icons/alert-circle-white.svg`;

const Banner = ({ hasCart }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { resultGlobalSalesSettings } = usePurchaseContext();
  const { isEnabled, isPaused, hasStarted } = resultGlobalSalesSettings ? resultGlobalSalesSettings : {};

  const isGlobalSaleDisabled = !isEnabled || isPaused || !hasStarted;

  return (
    <>
      {resultGlobalSalesSettings && isGlobalSaleDisabled && (
        <Navigation hasCart={hasCart}>
          <Icon src={iconPath} alt="!" />
          <Text>{t('global_sale_paused')}</Text>
        </Navigation>
      )}
    </>
  );
};

const Text = styled.span`
  text-transform: uppercase;
  color: var(--white);
  font-size: 16px;
  margin-left: 17px;
  line-height: 19px;
`;

const Navigation = styled.div`
  display: flex;
  z-index: 5;
  left: 0;
  ${({ hasCart }) =>
    hasCart
      ? css`
          position: sticky;
        `
      : css`
          position: relative;
        `}
  height: 60px;
  background-color: #2e3034;
  align-items: center;
  padding: 0.5rem 50px 0.5rem 60px;
  width: -webkit-fill-available;
`;

const Icon = styled.img`
  width: 25px;
  display: inline-block;
`;

export default Banner;
