import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FilmAdditionalData = ({ translations, filmAdditionalData }) => {
  return (
    <div>
      {filmAdditionalData ? (
        <Wrapper>
          <DetailText>{translations}:</DetailText>
          <>
            {translations === 'Trailer' ? (
              <Link rel={'external'} to={`//${filmAdditionalData.replace('https:', '')}`} target="_blank">
                <DetailLabel> {filmAdditionalData}</DetailLabel>
              </Link>
            ) : (
              <DetailLabel> {filmAdditionalData}</DetailLabel>
            )}
          </>
        </Wrapper>
      ) : null}
    </div>
  );
};
const Wrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const DetailLabel = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 1.6;
  width: auto;
  padding: 0 8px;
  color: #9ea0a5;
`;

const DetailText = styled.div`
  font-size: 16px;
  line-height: 1.6;
  width: auto;
  min-width: 200px;
  color: #ffffff;
`;

export default FilmAdditionalData;
