import React from 'react';
import styled from 'styled-components';

const FilmProgrammes = ({ filmProgrammes, noMargin = false }) => {
  return (
    filmProgrammes &&
    filmProgrammes.length > 0 && (
      <Programme noMargin={noMargin}>
        {filmProgrammes.map((programme, index) => (
          <div key={`${index}-programme-${programme}`}>{programme}</div>
        ))}
      </Programme>
    )
  );
};

const Programme = styled.h6`
  font-size: 16px;
  line-height: 1.3;
  color: #9da3b4;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '1.75rem')};
`;

export default FilmProgrammes;
