import styled from 'styled-components';

import { UncontrolledDropdown } from 'reactstrap';

const DatePickerWrapper = styled(UncontrolledDropdown)`
  display: inline-flex;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.08);
  & .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: var(--primary);
    color: var(--light);
  }
  & .react-datepicker__day:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover {
    background-color: var(--dark);
    color: var(--light);
  }
  & .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
  .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
    background-color: var(--primary);
    color: var(--light);
  }
  & .react-datepicker__month--selecting-range .react-datepicker__day--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:hover {
    background-color: var(--dark);
    color: var(--light);
  }
  & .react-datepicker__time-container {
    width: 100px;
  }
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px;
  }
  & .react-datepicker__month-container {
    box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.08);
  }
  & .react-datepicker__header {
    background-color: var(--gray);
    color: var(--light);
    padding: 1rem;
  }
  & .react-datepicker__day-name,
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: var(--light);
  }
  & .react-datepicker__month {
    padding: 1rem;
    margin: 0;
  }
  & .react-datepicker__navigation {
    top: 20px;
  }
  & .react-datepicker__navigation--next {
    right: 15px;
  }
  & .react-datepicker__navigation--previous {
    left: 15px;
  }
`;

export default DatePickerWrapper;
