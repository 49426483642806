import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import routes from 'utils/routes';
import { LOCALIZATION_PAGES, CONTACTS_TYPES } from 'utils/constants';
import { useHistory } from 'react-router-dom';

import { FilmPoster, CrewTypeMembers, Button, CastTypeMembers, FilmAdditionalData } from 'components';
import FilmBasicInfo from './FilmBasicInfo';

const FilmDetails = ({ film, toggleView }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const history = useHistory();

  const {
    title = '-',
    runtimeHumanReadable = '-',
    filmProgrammes = [],
    filmCastCsv = [],
    similarFilms = [],
    filmCrew = [],
    filmInfo = [],
    completionYear = '',
    countriesCsv = '',
    languagesCsv = '',
    subtitleLanguagesCsv = '',
    filmContacts = [],
  } = film ? film : {};
  const { longSynopsis = '-' } = film && film.filmSynopsis ? film.filmSynopsis : {};

  const goToSimilarFilm = id => {
    history.push(`${routes.FILMS.path}/${id}`);
  };

  let publicContacts = filmContacts?.filter(x => x.contactTypeName === CONTACTS_TYPES.CONTACT);
  let productionCompanies = filmContacts?.filter(x => x.contactTypeName === CONTACTS_TYPES.PRODUCTION);
  let coProductionCompanies = filmContacts?.filter(x => x.contactTypeName === CONTACTS_TYPES.CO_PRODUCTION);

  return film ? (
    <div>
      <FilmBasicInfo title={title} runtime={runtimeHumanReadable} filmProgrammes={filmProgrammes} />
      <Button color="default" onClick={toggleView}>
        {tCommon('screenings')}
      </Button>
      <Synopsis> {longSynopsis} </Synopsis>
      <Row>
        <Col xs={8}>
          <CrewTypeMembers crewMembers={filmCrew} />
        </Col>
        <Col xs={4}>
          <CastTypeMembers castMembers={filmCastCsv} />
        </Col>
      </Row>
      <FilmAdditionalData translations={t('film_colour')} filmAdditionalData={filmInfo.colour} />
      <FilmAdditionalData translations={t('film_year')} filmAdditionalData={completionYear} />
      <FilmAdditionalData translations={t('film_countries')} filmAdditionalData={countriesCsv} />
      <FilmAdditionalData translations={t('film_languages')} filmAdditionalData={languagesCsv} />
      <FilmAdditionalData translations={t('film_subtitles')} filmAdditionalData={subtitleLanguagesCsv} />
      <FilmAdditionalData translations={t('film_trailer')} filmAdditionalData={film.filmInfo?.onlineTrailerUrl} />
      {productionCompanies && (
        <FilmAdditionalData
          translations={t('film_production')}
          filmAdditionalData={productionCompanies.map(e => e.contactsCsv).join(', ')}
        />
      )}

      {coProductionCompanies && (
        <FilmAdditionalData
          translations={t('co-production')}
          filmAdditionalData={coProductionCompanies.map(e => e.contactsCsv).join(', ')}
        />
      )}

      {publicContacts && (
        <FilmAdditionalData
          translations={t('film_contact')}
          filmAdditionalData={publicContacts.map(e => e.contactsCsv).join(', ')}
        />
      )}

      {<SimilarFilmLabel className="mb-4 mt-4">{similarFilms[0] ? t('similar_films') : null}</SimilarFilmLabel>}
      <SimilarFilms>
        {similarFilms &&
          similarFilms.map((film, index) => (
            <SimilarFilmWrapper key={`${film.id}-${index}`}>
              <FilmPoster
                clickable={true}
                poster={film.poster ? film.poster : null}
                size={'small'}
                onClick={() => goToSimilarFilm(film.id)}
              ></FilmPoster>
              <FilmTitle
                onClick={() => goToSimilarFilm(film.id)}
                dangerouslySetInnerHTML={{ __html: film.title }}
              ></FilmTitle>
              <Duration>{film.runtimeHumanReadable}</Duration>
            </SimilarFilmWrapper>
          ))}
      </SimilarFilms>
    </div>
  ) : (
    <div>{tCommon('no_data')}</div>
  );
};

const SimilarFilms = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilmTitle = styled.p`
  cursor: pointer;
  margin-bottom: 0;
`;

const SimilarFilmWrapper = styled.div`
  width: 161px;
  margin-right: 1rem;
`;

const Synopsis = styled.p`
  font-size: 18px;
  line-height: 1.83;
  color: var(--secondary);
  margin-top: 3rem;
`;

const Duration = styled.div`
  font-size: 12px;
  color: #9ea0a5;
  font-weight: 400;
`;

const SimilarFilmLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #9ea0a5;
`;

export default FilmDetails;
