import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Text as DefaultText } from 'components/Typography';
import { handleData } from 'utils/helpers';
import { LOCALIZATION_PAGES, CURRENCY } from 'utils/constants';

const TicketInfo = ({ ticket }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.TICKET_CANCELATION);
  const {
    screeningName,
    id: ticketNumber,
    ticketCode,
    startDate,
    startTime,
    location,
    seatTypeName: seat,
    price,
    priceWithDiscount,
    ticketTypeName,
    printedBy,
    printTime,
  } = ticket;

  const handlePrintedBy = printedBy
    ? `${t('printed_by')} ${printedBy} ${t('on')} ${moment
        .parseZone(printTime)
        .local()
        .format('DD.MM.YYYY')} @ ${moment
        .parseZone(printTime)
        .local()
        .format('HH:mm')}h`
    : `${t('printed_on')} ${moment
        .parseZone(printTime)
        .local()
        .format('DD.MM.YYYY')} @ ${moment
        .parseZone(printTime)
        .local()
        .format('HH:mm')}h`;
  const screeningDateAndTime =
    startDate && startTime ? `${moment(startDate).format('DD.MM.YYYY')} @ ${startTime}h` : '-';

  return (
    <Wrapper>
      <ScreeningName>{handleData(screeningName)}</ScreeningName>
      <div>
        <div className="d-flex justify-content-between">
          <Text>{t('ticket_number')}</Text>
          <Text>{handleData(ticketNumber)}</Text>
        </div>
        <div className="d-flex justify-content-between">
          <Text>{t('ticket_code')}</Text>
          <Text>{handleData(ticketCode)}</Text>
        </div>

        <div className="d-flex justify-content-between">
          <Text>{t('screening_date_and_time')}</Text>
          <Text>{screeningDateAndTime}</Text>
        </div>

        <div className="d-flex justify-content-between">
          <Text>{t('venue')}</Text>
          <Text>{handleData(location)}</Text>
        </div>

        <div className="d-flex justify-content-between">
          <Text>{t('ticket_type')}</Text>

          <Text>{handleData(ticketTypeName)}</Text>
        </div>

        <div className="d-flex justify-content-between">
          <Text>{t('seat')}</Text>

          <Text>{handleData(seat)}</Text>
        </div>

        <div className="d-flex justify-content-between">
          <Text>{t('price')}</Text>

          <Text>
            {/^[A-Za-z]+$/.test(price)
              ? handleData(price)
              : handleData(
                  priceWithDiscount && priceWithDiscount !== null
                    ? `${CURRENCY.BAM} ${Number.parseFloat(priceWithDiscount).toFixed(2)}`
                    : `${CURRENCY.BAM} ${Number.parseFloat(price).toFixed(2)}`
                )}
          </Text>
        </div>

        <div>
          <Text>{printTime && handlePrintedBy}</Text>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 350px;
  margin-top: 2.5rem;
  margin-left: 0.1rem;
`;

const ScreeningName = styled(DefaultText)`
  font-size: 18px;
  line-height: 1.22;
  margin-bottom: 0.8rem;
`;

const Text = styled(DefaultText)`
  font-weight: 300;
  line-height: 1.19;
`;

export default TicketInfo;
