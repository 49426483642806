import React from 'react';
import styled from 'styled-components';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, FormText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import routes, { generateLink } from 'utils/routes';
import { CardBody } from 'components/Card';
import { useToggle } from 'utils/hooks';
import { sessionActions } from 'redux/actions';
import { getShift, getUser } from 'redux/reducers/sessionReducer';
import { useShiftContext } from 'context/ShiftContext';
import { LOCALIZATION_PAGES, SHIFT_MODES } from 'utils/constants';

const HeaderMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useToggle(false);
  const { t } = useTranslation(LOCALIZATION_PAGES.HEADER);
  const { t: tShift } = useTranslation(LOCALIZATION_PAGES.SHIFT);
  const dispatch = useDispatch();
  const history = useHistory();
  const shift = useSelector(getShift);
  const user = useSelector(getUser);
  const { startShiftHandler } = useShiftContext();
  const { firstName, lastName } = user || {};

  const logOut = async () => {
    if (shift) dispatch(sessionActions.setShift(null));
    dispatch(sessionActions.logOut());
    history.push(routes.LOGIN.path);
  };

  const goToProfile = async () => {
    history.push(routes.MY_PROFILE.path);
  };

  const goToShiftDetails = () => history.push(generateLink(routes.SHIFT_DETAILS));

  const menu = [
    {
      iconPath: `${process.env.PUBLIC_URL}/assets/icons/lock-closed-outline.svg`,
      label: t('my_profile'),
      onClick: goToProfile,
    },
    {
      iconPath: `${process.env.PUBLIC_URL}/assets/icons/clock.svg`,
      label: shift === SHIFT_MODES.STARTED ? tShift('view_shift') : tShift('start_shift'),
      onClick: shift === SHIFT_MODES.STARTED ? goToShiftDetails : startShiftHandler,
    },
    {
      iconPath: `${process.env.PUBLIC_URL}/assets/icons/log-out-outline.svg`,
      label: t('log_out'),
      onClick: logOut,
    },
  ];

  return (
    <CustomDropdown isOpen={dropdownOpen} toggle={setDropdownOpen}>
      <CustomToggle color="transparent">
        {user && firstName && lastName && (
          <Initials>
            {firstName[0]}
            {lastName[0]}
          </Initials>
        )}
        <Icon src={`${process.env.PUBLIC_URL}/assets/icons/select.svg`} alt={t('select_icon')} />
      </CustomToggle>
      <CustomDropdownMenu right>
        {menu.map(m => {
          return (
            <CustomDropdownItem onClick={m.onClick} key={m.label}>
              <CustomImg alt={m.label} src={m.iconPath} />
              <CustomFormText color="light">{m.label}</CustomFormText>
            </CustomDropdownItem>
          );
        })}
      </CustomDropdownMenu>
    </CustomDropdown>
  );
};

const CustomDropdown = styled(Dropdown)`
  margin-left: 1.5rem;
`;

const CustomDropdownItem = styled(DropdownItem)`
  display: flex;
  height: 70px;
  align-items: center;
  :hover {
    background-color: #3c4048;
  }

  && small {
    margin-top: 0;
  }
`;

const CustomImg = styled.img`
  margin-right: 0.8rem;
`;

const CustomFormText = styled(FormText)`
  font-size: 16px;
  line-height: 1.57;
`;

const CustomDropdownMenu = styled(DropdownMenu)`
  background-color: #2a2c30;
  width: 250px;
  padding: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.47);
  button:first-child {
    border-bottom: 1px solid rgba(234, 237, 243, 0.13);
  }
`;

const Initials = styled(CardBody)`
  height: 38px;
  width: 38px;
  background-color: #1e2025;
  color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 100%;
  padding: 0px;
  float: left;
  margin: 0px !important;
  &:hover {
    background-color: #2b2c2e;
    border: none;
    box-shadow: 1px #2b2c2e;
  }
`;

const CustomToggle = styled(DropdownToggle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 38px;
  padding: 0px;
  border: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  &:active {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  &:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;

const Icon = styled.img`
  height: 13px;
  width: 13px;
`;

export default HeaderMenu;
