import styled, { css } from 'styled-components';
import {
  ModalHeader as ReactstrapModalHeader,
  ModalBody as ReactstrapModalBody,
  ModalFooter as ReactstrapModalFooter,
  Modal as ReactstrapModal,
} from 'reactstrap';

import Modal from './Modal';
import PurchaseModal from './PurchaseModal';
import PaymentModal from './PaymentModal';
import ModalText from './ModalText';

const ModalContainer = styled(ReactstrapModal)`
  color: var(--white) !important;
  .modal-content {
    background-color: #2e3034;
  }
  .close {
    color: var(--white);
    text-shadow: none;
    span {
      font-size: 1.8rem;
    }
  }
`;

const ModalHeader = styled(ReactstrapModalHeader)`
  font-size: 21px;
  font-weight: 500;
  padding: 2rem 1.5rem;
  border-bottom: 1px solid #424242;
`;

const ModalBody = styled(ReactstrapModalBody)`
  padding: 1.5rem;
  border-bottom: 1px solid #424242;
`;

const ModalFooter = styled(ReactstrapModalFooter)`
  padding: 1rem 1.3rem;
  ${({ justifyfooter }) =>
    justifyfooter
      ? css`
          justify-content: ${justifyfooter};
        `
      : css`
          justify-content: space-between;
        `}
  padding: 1rem 1.3rem;
  border-top: 0;
`;

export { Modal, ModalContainer, ModalText, ModalBody, ModalHeader, ModalFooter, PurchaseModal, PaymentModal };
