import React, { useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import GlobalStyle from 'globalStyle';

import routes, { matchRoute } from 'utils/routes';
import Alerts from 'components/Alerts/Alerts';
import Header from 'components/Layout/Header';
import Banner from 'components/Banner/Banner';

import styled, { css } from 'styled-components';
import { Row, Col } from 'reactstrap';
import PurchaseDistributed from 'components/Purchase/PurchaseDistributed/PurchaseDistributed';
import ReservedPurchase from 'components/Purchase/ReservedPurchase/ReservedPurchase';
import ShiftModal from 'components/Shift/ShiftModal';
import { PurchaseProvider } from 'context/PurchaseContext';
import { ReservedPurchaseProvider } from 'context/ReservedPurchaseContext';
import { ShiftProvider } from 'context/ShiftContext';
import PurchaseCart from 'components/PurchaseCart/PurchaseCart';

const Root = () => {
  const history = useHistory();

  // find active route
  const currentRoute = matchRoute(history.location.pathname);

  const [shiftStatus, updateShiftStatus] = useState(false);

  const isOnLogin = () => currentRoute && currentRoute.id === routes.LOGIN.id;
  const isOnForgotPassword = () => currentRoute && currentRoute.id === routes.FORGOT_PASSWORD.id;

  const WrapPurchaseProvider = ({ children }) => {
    return isOnLogin() || isOnForgotPassword() ? (
      <>{children}</>
    ) : (
      <PurchaseProvider>
        <ReservedPurchaseProvider>
          <ShiftProvider shiftStatus={shiftStatus} updateShiftStatus={updateShiftStatus}>
            {children}
            <ShiftModal />
            <ReservedPurchase />
            <PurchaseDistributed />
          </ShiftProvider>
        </ReservedPurchaseProvider>
      </PurchaseProvider>
    );
  };

  return (
    <>
      <Alerts />
      <GlobalStyle />
      <Container>
        <WrapPurchaseProvider>
          <Column>
            <CustomRow>
              {currentRoute && currentRoute.showHeader && <Header hasCart={currentRoute && currentRoute.showCart} />}
            </CustomRow>
            {/* on the same routes where header is present, banner should be too */}
            <CustomRow>{currentRoute && currentRoute.showHeader && <Banner />}</CustomRow>
            <RouteRow headingOffset={currentRoute && currentRoute.showHeader}>
              <RouteCol hasPadding={!isOnLogin() && !isOnForgotPassword()}>
                <Switch>
                  {Object.values(routes).map((r, i) => {
                    return <Route key={i} path={r.path} exact={r.exact} component={r.Component} />;
                  })}

                  {/* if no match for the route - redirect to / */}
                  <Route path="*">
                    <Redirect to={{ pathname: '/' }} />
                  </Route>
                </Switch>
              </RouteCol>
            </RouteRow>
          </Column>
          <Column md="auto">
            {currentRoute && currentRoute.showCart && <PurchaseCart shiftStatus={shiftStatus} />}
          </Column>
        </WrapPurchaseProvider>
      </Container>
    </>
  );
};

const Container = styled(Row)`
  height: 100%;
  width: 100%;
  margin: 0;
`;

const Column = styled(Col)`
  padding-right: 0;
  padding-left: 0;
`;

const RouteCol = styled(({ hasPadding, children, ...rest }) => <Col {...rest}>{children}</Col>)`
  ${({ hasPadding }) =>
    hasPadding
      ? css`
          padding: 30px;
        `
      : css`
          padding: 0;
        `}
`;

const CustomRow = styled(({ children, ...rest }) => <Row {...rest}>{children}</Row>)`
  margin: 0;
`;

const RouteRow = styled(({ headingOffset, children, ...rest }) => <Row {...rest}>{children}</Row>)`
  ${({ headingOffset }) =>
    headingOffset
      ? css`
          height: auto;
        `
      : css`
          height: 100%;
        `}
  margin: 0;
`;

export default Root;
