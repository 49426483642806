import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Authorize from 'components/Authorization/Authorize';
import { FormikField, ModalTicketInput } from 'components/Form';
import { usePurchaseContext } from 'context/PurchaseContext';
import { LOCALIZATION_PAGES } from 'utils/constants';

const RenderPurchaseFields = ({ values, validateForm, screeningSeats, cart, accreditation }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const { selectedScreening } = usePurchaseContext();
  const compare = ticketInsert => ticketInsert.ticketTypeSelectorId === screeningSeats.ticketTypeSelectorId;
  const ticketInsert = values.ticketInserts.find(compare);
  const ticketInsertIdx = values.ticketInserts.findIndex(compare);

  //! this useEffect is not desired, but serves as a workaround
  //  formik validates previous values instead of values after change
  //  this effect validates the form again when the values have changed
  useEffect(() => {
    if (validateForm) {
      validateForm();
    }
  }, [values, validateForm]);

  const validate = (value, seat, accreditation) => {
    const availableSeats = seat.totalSeats - seat.totalSoldSeats;

    const cartTicketsForCurrentScreening = countCartTicketsForCurrentScreening();
    const accreditationTickets = countAccreditationTickets(values);
    const remainingTickets = countRemainingAccreditationTickets();

    let error = null;

    if (accreditation && remainingTickets - accreditationTickets + cartTicketsForCurrentScreening < 0) {
      error = 'accreditationLimitExceeded';
    }

    if (value > availableSeats) {
      error = t('max_quantity', { maxValue: availableSeats });
    }

    setReachedLimit(remainingTickets, accreditationTickets, cartTicketsForCurrentScreening);

    return error;
  };

  const countRemainingAccreditationTickets = () => {
    if (!accreditation) return;
    const { ticketsUsedForScreening, ticketsUsedForDay, ticketsLimitDaily, ticketsLimitPerScreening } = accreditation;

    const remainingDaily = ticketsLimitDaily - ticketsUsedForDay;
    const remainingScreening = ticketsLimitPerScreening - ticketsUsedForScreening;

    const lesserValue = Math.min(remainingDaily, remainingScreening);

    return lesserValue;
  };

  const setReachedLimit = (remainingTickets, accreditationTickets, cartTicketsForCurrentScreening) => {
    if (!accreditation) return;
    try {
      remainingTickets - accreditationTickets + cartTicketsForCurrentScreening <= 0
        ? setIsLimitReached(true)
        : setIsLimitReached(false);
    } catch (err) {
      setIsLimitReached(false);
    }
  };

  const countAccreditationTickets = values => {
    let totalAccreditatonTickets = 0;

    try {
      values.ticketInserts
        .find(tts => tts.ticketTypeSelectorId === -1)
        .ticketSeatTypes.forEach(sts => {
          totalAccreditatonTickets += sts.quantity;
        });
    } catch (err) {
      return totalAccreditatonTickets;
    }
    return totalAccreditatonTickets;
  };

  const countCartTicketsForCurrentScreening = () => {
    let cartAccreditationTickets = 0;

    try {
      cart.cartScreenings
        .filter(
          x =>
            x.screeningId === selectedScreening.id &&
            moment(x.screeningDateTime).isSame(selectedScreening.startTime, 'day')
        )
        .forEach(screening => {
          screening.seatTypes.forEach(seatType => {
            if (seatType.ticketTypeSelectorId === -1) {
              cartAccreditationTickets += seatType.count;
            }
          });
        });

      return cartAccreditationTickets;
    } catch (err) {
      return cartAccreditationTickets;
    }
  };

  if (ticketInsert) {
    return !ticketInsert?.ticketSeatTypes.length ? (
      <div
        className="text-center mt-5 mb-3"
        dangerouslySetInnerHTML={{
          __html: accreditation?.accreditationName
            ? t('no_tickets_accreditation', { accreditation: accreditation.accreditationName })
            : t('no_tickets'),
        }}
      />
    ) : (
      <div>
        {ticketInsert.ticketSeatTypes &&
          ticketInsert.ticketSeatTypes.map((seat, index) => (
            <Authorize forPermissions={['all'].concat(seat.permissions)} renderIfUnauthorized={true}>
              <FormikField
                key={`${seat.seatTypeId}-${index}-${seat.ticketTypeSelectorId}`}
                component={ModalTicketInput}
                seat={seat}
                name={`ticketInserts[${ticketInsertIdx}].ticketSeatTypes[${index}].quantity`}
                validate={value => validate(value, seat, accreditation)}
                isLimitReached={accreditation ? isLimitReached : false}
              />
            </Authorize>
          ))}
      </div>
    );
  } else return null;
};

export default RenderPurchaseFields;
