import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

const useScreeningSeatTypes = id => {
  const path = `${API_ENDPOINTS.SCREENINGS}/${id}/seat-types`;
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
  const { showNotification } = useRoot();
  const [{ result: seatTypes, loading, error, setResult: setSeatTypesResult }, getSeatTypes] = useApi(path, {
    initialFetch: false,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('fetching_seat_types_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ seatTypes, loading, error }, getSeatTypes, setSeatTypesResult];
};

export default useScreeningSeatTypes;
