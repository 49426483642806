import useApi from 'utils/hooks/api/useApi';

const useTicketByCode = ticketCode => {
  const path = `/carts/tickets/ticketCode/${ticketCode}`;
  const [{ result: ticket, loading, error, setResult: setTicket }, getTicket] = useApi(path, { initialFetch: false });

  return [{ ticket, loading, error }, getTicket, setTicket];
};

export default useTicketByCode;
