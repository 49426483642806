import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ScreeningInfo from 'components/Purchase/ReservedPurchase/ScreeningInfo';
import { Input } from 'components/Form';
import { Button, Label as DefaultLabel } from 'components';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { useReservedPurchaseContext } from '../../../context/ReservedPurchaseContext';
import Authorize from 'components/Authorization/Authorize';
import permissions from 'utils/permissions';

const ValidateAccreditation = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVED_PURCHASE);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const {
    accreditationNumber,
    setAccreditationNumber,
    errorAccreditation,
    loadingAccreditation,
    ticketTypeLoading,
    layout,
    accreditation,
    ticketType,
    onConfirmAccreditation,
  } = useReservedPurchaseContext();
  const [validationMessage, setValidationMessage] = useState(null);

  const handleChange = e => {
    if (validationMessage) setValidationMessage(null);
    setAccreditationNumber(e.target.value);
  };

  useEffect(() => {
    if (errorAccreditation) {
      const { data } = errorAccreditation?.data;
      if (data?.Message?.includes('User is not authorized')) setValidationMessage(t('not_authorized'));
      else setValidationMessage(t('invalid_accreditation_number'));
    } else if (accreditation && !ticketTypeLoading && !ticketType) {
      setValidationMessage(
        t('accreditation_no_ticket_type', {
          customerFullName: accreditation.customerFullName,
          accreditationName: accreditation.accreditationName,
        })
      );
    } else {
      setValidationMessage(null);
    }
  }, [errorAccreditation, setValidationMessage, t, accreditation, ticketType, ticketTypeLoading]);

  return layout && layout.length > 0 ? (
    <>
      <ScreeningInfo />
      <Wrapper>
        <Label>{t('enter_accreditation_number')}</Label>
        <div className="d-flex align-items-center">
          <InputWrapper>
            <Input onChange={handleChange} value={accreditationNumber} invalid={validationMessage && true} />
          </InputWrapper>
          <Authorize forPermissions={['all', permissions.CanViewAccreditations]} renderIfUnauthorized={true}>
            <ConfirmButton
              color="default"
              disabled={!accreditationNumber || accreditationNumber.length > 10}
              onClick={onConfirmAccreditation}
              loading={loadingAccreditation || ticketTypeLoading}
            >
              {tCommon('confirm')}
            </ConfirmButton>
          </Authorize>
        </div>
        {validationMessage && <ErrorMsg>{validationMessage}</ErrorMsg>}
      </Wrapper>
    </>
  ) : (
    <span className="flex-grow-1 text-center">{t('no_layout')}</span>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1.5rem 3.5rem 1.5rem;
`;

const InputWrapper = styled.div`
  width: 400px;
  & input {
    background-color: rgba(0, 0, 0, 0.11);
    border: solid 1px rgba(226, 229, 237, 0.11);
    height: 49px;
    color: var(--white);
    &:focus {
      background-color: rgba(0, 0, 0, 0.11);
      border: solid 1px rgba(226, 229, 237, 0.11);
      color: var(--white);
    }
  }
  & .is-invalid {
    border-color: #ff004e;
    background: none;
  }
  & .is-invalid:focus {
    border-color: #ff004e;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.19);
  }
`;

const Label = styled(DefaultLabel)`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.21;
  text-transform: none;
  color: var(--white);
  margin-bottom: 0.8rem;
`;

const ConfirmButton = styled(Button)`
  border-radius: 4px;
  height: 49px;
  margin-left: 0.5rem;
  width: 140px;
`;

const ErrorMsg = styled.p`
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  color: #ff004e;
`;

export default ValidateAccreditation;
