import React from 'react';
import CenterContent from 'components/Layout/CenterContent';
import { Spinner } from 'reactstrap';

const Loader = () => {
  return (
    <CenterContent>
      <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
    </CenterContent>
  );
};

export default Loader;
