import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, ErrorMessage as DefaultErrorMessage } from 'components/Form';
import { Button, Label as DefaultLabel } from 'components';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { usePurchaseContext } from '../../context/PurchaseContext';
import Authorize from 'components/Authorization/Authorize';
import permissions from 'utils/permissions';

const ValidateAccreditation = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const {
    accreditationNumber,
    setAccreditationNumber,
    getAccreditation,
    errorAccreditation,
    loadingAccreditation,
  } = usePurchaseContext();
  const [validationMessage, setValidationMessage] = useState(null);
  const handleChange = e => {
    setAccreditationNumber(e.target.value);
  };

  useEffect(() => {
    if (errorAccreditation) {
      const { data } = errorAccreditation?.data;
      if (data?.Message?.includes('User is not authorized')) setValidationMessage(t('not_authorized'));
      else setValidationMessage(t('invalid_accreditation_number'));
    } else {
      setValidationMessage(null);
    }
  }, [errorAccreditation, setValidationMessage, t]);

  return (
    <div className="my-5">
      <Label>{t('enter_accreditation_number')}</Label>
      <div className="d-flex align-items-center">
        <AccreditatonInputWrapper>
          <Input onChange={handleChange} value={accreditationNumber} invalid={validationMessage} />
        </AccreditatonInputWrapper>
        <Authorize forPermissions={['all', permissions.CanViewAccreditations]} renderIfUnauthorized={true}>
          <ConfirmButton color="default" onClick={() => getAccreditation()} loading={loadingAccreditation}>
            {tCommon('confirm')}
          </ConfirmButton>
        </Authorize>
      </div>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

const AccreditatonInputWrapper = styled.div`
  width: 400px;
  & input {
    background-color: rgba(0, 0, 0, 0.11);
    border: solid 1px rgba(226, 229, 237, 0.11);
    height: 49px;
    color: var(--white);
    &:focus {
      background-color: rgba(0, 0, 0, 0.11);
      border: solid 1px rgba(226, 229, 237, 0.11);
      color: var(--white);
    }
  }
  & .is-invalid {
    border-color: #ff004e;
    background: none;
  }
  & .is-invalid:focus {
    border-color: #ff004e;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.19);
  }
`;

const Label = styled(DefaultLabel)`
  text-transform: none;
  color: var(--white);
`;

const ConfirmButton = styled(Button)`
  border-radius: 4px;
  height: 49px;
  margin-left: 0.5rem;
  width: 140px;
`;

const ErrorMessage = styled(DefaultErrorMessage)`
  & {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    color: #ff004e;
  }
`;

export default ValidateAccreditation;
