import React from 'react';
import styled from 'styled-components';
import { FilmProgrammes } from 'components';

const FilmBasicInfo = ({ title, runtime, code, filmProgrammes }) => (
  <>
    {title && <Heading dangerouslySetInnerHTML={{ __html: title }} />}
    {runtime && <Duration> {runtime} </Duration>}
    <div className="d-flex justify-items-start">
      {code && <Code>{code}</Code>} <FilmProgrammes filmProgrammes={filmProgrammes} />
    </div>
  </>
);

const Heading = styled.h2`
  font-size: 24px;
  line-height: 1.42;
  margin-bottom: 1.5rem;
`;

const Duration = styled.h6`
  font-size: 16px;
  line-height: 1.64;
  margin-bottom: 0.5rem;
`;

const Code = styled.h6`
  font-size: 16px;
  line-height: 1.64;
  color: #9ea0a5;
  margin-bottom: 1.75rem;
`;

export default FilmBasicInfo;
