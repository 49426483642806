import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useFilms = query => {
  const queryString = `${API_ENDPOINTS.FILMS}?Term=${query.term}&LanguageId=${query.languageId}&Program=${query.program}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const [path, setPath] = useState(queryString);
  const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);
  const { showNotification } = useRoot();
  const [{ result: films, loading, error }, getFilms] = useApi(path, { initialFetch: false });

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('films_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ films, loading, error }, getFilms];
};

export default useFilms;
