import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import { Text } from 'components/Typography';
import { toLocalDate } from 'utils/helpers';
import { RESERVATION_STATUS, LOCALIZATION_PAGES } from 'utils/constants';

const ReservationItem = ({ item, setShowReservationDetails, setReservationId }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.RESERVATION);
  const { title, requesterName, createdDate, ticketCount, status } = item;
  const date = createdDate ? toLocalDate(createdDate, 'DD.MM.YYYY @ HH:mm') : null;

  const showDetails = () => {
    setReservationId(item.id);
    setShowReservationDetails();
  };

  const getLabel = status => {
    switch (status) {
      case RESERVATION_STATUS.PENDING:
        return <span style={{ color: '#ffb200', fontSize: '14px' }}>{t('status_pending')}</span>;
      case RESERVATION_STATUS.APPROVED:
        return <span style={{ color: '#92e992', fontSize: '14px' }}>{t('status_approved')}</span>;
      case RESERVATION_STATUS.CUSTOM_APPROVED:
        return <span style={{ color: '#92e992', fontSize: '14px' }}>{t('status_custom_approved')}</span>;
      case RESERVATION_STATUS.DECLINED:
        return <span style={{ color: '#ff004e', fontSize: '14px' }}>{t('status_declined')}</span>;
      case RESERVATION_STATUS.DELIVERED:
        return <span style={{ color: '#92e992', fontSize: '14px' }}>{t('status_delivered')}</span>;
      case RESERVATION_STATUS.EXPIRED:
        return <span style={{ color: '#ff004e', fontSize: '14px' }}>{t('status_expired')}</span>;
      case RESERVATION_STATUS.CANCELLED:
        return <span style={{ color: '#ff004e', fontSize: '14px' }}>{t('status_cancelled')}</span>;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <div className="d-flex justify-content-between">
        <div>
          <Text16 onClick={showDetails}>{title ? title : ''}</Text16>
        </div>
        <CustomButton color="default" onClick={showDetails}>
          {t('details')}
        </CustomButton>
      </div>
      <div>
        <Detail>
          {date ? date : null} by {requesterName ? requesterName : null}
        </Detail>
      </div>
      <div className="d-flex">
        <Text14 className="mr-4">
          {ticketCount} {ticketCount === 1 ? t('ticket') : t('ticket_pl')}
        </Text14>
        <Text14>{getLabel(status)}</Text14>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-bottom: 1px solid rgba(226, 229, 237, 0.11);

  &&:last-child {
    border-bottom: none;
  }
`;

const Text16 = styled(Text)`
  font-size: 16px;
`;

const Text14 = styled(Text)`
  font-size: 14px;
`;

const Detail = styled.div`
  opacity: 0.5;
  font-size: 14px;
  margin: 0;
  line-height: 1.21;
  color: var(--white);
`;

const CustomButton = styled(Button)`
  height: 29px;
  font-size: 12px;
  padding: 0 1rem;
  width: fit-content;
  margin-top: 0.3rem;
`;

export default ReservationItem;
