import useApi from 'utils/hooks/api/useApi';

import { API_ENDPOINTS } from 'utils/constants';

const useTicketType = id => {
  const path = `${API_ENDPOINTS.TICKET_TYPES}/${id}`;

  const [{ result: ticketType, loading, error, setResult: setTicketType }, getTicketType] = useApi(path, {
    initialFetch: false,
  });

  return [{ ticketType, loading, error }, getTicketType, setTicketType];
};

export default useTicketType;
