import moment from 'moment';

export const TOKEN_KEY = 'JWT-TOKEN';
export const REFRESH_TOKEN_KEY = 'REFRESH-TOKEN';
export const ACCESS_TOKEN_KEY = 'ACCESS-TOKEN';
export const LANG_KEY = 'CURRENT_LANGUAGE';
export const USER = 'CURRENT_USER';

export const HTTP_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  OK: 200,
};

export const NOTIFICATION_DISMISS_TIMEOUT = 5000;

export const ALERT_VARIANTS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
};

export const LOCALIZATION_PAGES = {
  COMMON: 'common',
  CHANGE_PASSWORD: 'change_password',
  EVENTIVAL_ACCREDITATIONS: 'eventival_accreditations',
  FORGOTTEN_PASSWORD: 'forgotten_password',
  HEADER: 'header',
  LOGIN: 'login',
  USER_PROFILE: 'user_profile',
  SCREENINGS: 'screenings',
  FILMS: 'films',
  PURCHASE_CART: 'purchase_cart',
  RESERVED_PURCHASE: 'reserved_purchase',
  PURCHASE: 'purchase',
  DISCOUNT: 'discount',
  TICKET_CANCELATION: 'ticket_cancelation',
  SHIFT: 'shift',
  PRINT_LOG: 'print_log',
  RESERVATION: 'reservations',
};

export const LANGUAGES = [
  {
    id: 1,
    value: 'ENG',
    NAME: 'English',
    LANGUAGE_CODE: 'en-us',
    LCID: 'en-us',
    iconPath: `${process.env.PUBLIC_URL}/assets/icons/uk.svg`,
    DEFAULT: true,
    ISO_CODE: 'en',
  },
  {
    id: 2,
    value: 'BiH',
    NAME: 'Bosnian',
    LANGUAGE_CODE: 'bs-BA',
    LCID: 'bs-BA',
    iconPath: `${process.env.PUBLIC_URL}/assets/icons/bh.svg`,
    ISO_CODE: 'bs',
  },
];

export const DEFAULT_PAGE_SIZE = 10;

export const ROLES = {
  SuperAdmin: 'SuperAdmin',
  Admin: 'Admin',
  User: 'User',
};

export const HTTP_VERBS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const DEBOUNCE_TIMEOUT = 600;

export const SORT_ORDER = {
  ASC: 0,
  DESC: 1,
};

export const API_ENDPOINTS = {
  AUTH_TOKEN: 'auth/token',
  REFRESH_AUTH_TOKEN: 'auth/token/refresh',
  CHECK_AUTH: 'auth/check-auth',
  RESET_PASSWORD: 'accounts/password/reset',
  FILMS: 'films',
  SCREENINGS: 'screenings',
  VENUES: 'venues',
  LANGUAGES: 'common/languages',
  LANGUAGES_ASSIGNED_TO_FILMS: 'common/languages-assigned-to-films',
  CART: 'carts',
  CUSTOMER_ACCREDITATIONS: 'customer-accreditations',
  TICKET_TYPES: 'ticket-types',
  SEAT_TYPES: 'seat-types',
  SHIFTS: 'shifts',
  TICKETS: 'tickets',
  USERS: 'users',
  RESERVATIONS: 'reservations',
};

export const DATEPICKER = {
  today: 'today',
  reset: 'reset',
};

export const SCREENING_STATUS = {
  SALE: 'sale',
  SOLD: 'sold',
};

export const TAB_TYPES = {
  DEFAULT: 'default',
  SWITCH: 'switch',
  PURCHASE: 'purchase',
};

export const USER_ROLES = {
  REGULAR: 'regular',
  ADMIN: 'admin',
};

export const MARKINGS = {
  letters: { id: 0, name: 'Letters' },
  numbers: { id: 1, name: 'Numbers' },
};

export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const TICKET_TYPE_SELECTORS = {
  ACCREDITATION: -1,
  REGULAR: -2,
  GRATIS: -4,
};

export const CURRENCY = {
  KM: 'KM',
  BAM: 'BAM',
};

export const SEATING_TYPES = {
  DISTRIBUTION: 'DISTRIBUTION',
  RESERVED: 'RESERVED',
};

export const SALE_TYPES = {
  POS: { id: -1, name: 'POS' },
  ONLINE: { id: -2, name: 'Online' },
};

export const TICKET_STATUSES = {
  DRAFT: 'DRAFT',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  USED: 'USED',
};

export const SHIFT_MODES = {
  VIEW: 'VIEW',
  STARTED: 'STARTED',
};

const Query = {
  shared: {
    name: '',
    term: '',
    sortBy: '',
    sortOrder: 0,
    includes: '',
  },
  defaultAll: {
    pageSize: '',
    page: '',
    retrieveAll: true,
    includeCount: '',
  },
  default: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: '0',
    retrieveAll: 'false',
    includeCount: 'true',
  },
  custom: {
    screenings: {
      term: '',
      startDate: '',
      endDate: '',
      status: 'Scheduled',
      venues: [],
    },
    films: {
      term: '',
      program: '',
      languageId: '',
    },
    filmScreenings: {
      includes: '',
      pageSize: 30,
      //'ScreeningFilms.Film.FilmSections.Section.SectionTranslations.Language&Includes=ScreeningFilms.FilmPackage.FilmPackageSections.Section.SectionTranslations.Language&Includes=ScreeningFilms.Film.FilmLanguages.Language',
    },
    printLog: {
      term: '',
      user: '',
      status: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      userId: '',
    },
    reservations: {
      term: '',
      startDate: '',
      endDate: '',
      statuses: [],
      sortOrder: 0,
      includes: '',
      userId: '',
    },
  },

  generateQuerries: function() {
    let querries = {
      default: {
        ...this.default,
        ...this.shared,
      },
      defaultAll: {
        ...this.defaultAll,
        ...this.shared,
      },
    };
    Object.keys(this.custom).forEach(x => {
      querries[x] = {
        ...this.shared,
        ...this.default,
        ...this.custom[x],
      };
      querries[`${x}All`] = {
        ...this.shared,
        ...this.defaultAll,
        ...this.custom[x],
      };
    });

    return querries;
  },
};

export const API_QUERY = Query.generateQuerries();

export const PURCHASE_TABS = [
  {
    selectorId: -2,
    iconPath: `${process.env.PUBLIC_URL}/assets/icons/money.svg`,
    label: 'selector_regular',
    ordinal: 1,
  },
  {
    selectorId: -1,
    iconPath: `${process.env.PUBLIC_URL}/assets/icons/contact.svg`,
    label: 'selector_accreditations',
    ordinal: 2,
  },
  {
    selectorId: -4,
    iconPath: `${process.env.PUBLIC_URL}/assets/icons/gift.svg`,
    label: 'selector_gratis',
    ordinal: 3,
  },
  {
    selectorId: null,
    iconPath: '',
    label: '-',
    ordinal: 0,
  },
];

export const PAYMENT_TABS = {
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  PROCESSING_PAYMENT: 'PROCESSING_PAYMENT',
  PRINTING_TICKETS: 'PRINTING_TICKETS',
};

export const RESERVATION_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  CUSTOM_APPROVED: 'CUSTOM_APPROVED',
  DECLINED: 'DECLINED',
  DELIVERED: 'DELIVERED',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
};

export const RESERVATION_STATUS_VALUE = {
  PENDING: 0,
  APPROVED: 1,
  CUSTOM_APPROVED: 2,
  DECLINED: 3,
  DELIVERED: 4,
  EXPIRED: 5,
  CANCELLED: 6,
};

export const CONTACTS_TYPES = {
  PRODUCTION: 'Production Company',
  CONTACT: 'Public Contact',
  CO_PRODUCTION: 'Co-Production Company',
};

export const PAYMENT_TYPE = {
  CASH: 'CASH',
  CREDIT_CARD: 'CC',
  INVOICE: 'INVOICE',
  NONE: 'NONE',
};

export const ORIENTATION = {
  normal: { id: 0, name: 'NORMAL' },
  reverse: { id: 1, name: 'REVERSE' },
};
