import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    box-sizing: border-box;
    font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    .close {
      span {
        font-size: 30px;
        opacity:1;
        font-weight: 400;
        color: #ffffff;      
      }
    }
  }
  html, body {
        margin: 0;
        height: 100%;
        color: var(--white);
        background-color: #1e2025;
    }
  #root{
      height: 100%;
  }
  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
    &:focus{
      outline:none;
    }
  }
  a{
    text-decoration: none;
    &:hover{
      text-decoration:none;
    }
  }

  .clickable{
    cursor:pointer;
  }
  
  /* Bootstrap overrides */
  .alert-dismissible .close{
    top: -1px;
  }

  /* PAGINATION */
  nav[aria-label="pagination"]{
    display:flex;
    justify-content:center;
  }
  .pagination{
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.08);
  }
  .page-item .page-link {
    position:relative;
    text-align: center;
    color: var(--dark);
    background-color: var(--white);
    border: 0;
    border-top: solid 1px #d8dce6;
    border-bottom: solid 1px #d8dce6;
    width: 38px;
    height: 38px;
  }
  /* override bootstrap primary active */
  .page-item:active .page-link, .page-item.active .page-link{
    z-index: 3;
    color: var(--dark);
    background-color: var(--white);
    border-color: #d8dce6;
  }
  .page-item:hover .page-link .page-item:active .page-link:after, .page-item.active .page-link:after{
    content: "";
    position:absolute;
    top:2px;
    left:3px;
    width: 32px;
    height: 32px;
    z-index: 3;
    border-radius: 4px;
    background-color: rgba(158, 160, 165, 0.19);
  }
  .page-item:first-child .page-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid #d8dce6;
  }
  .page-item:first-child .page-link:after {
    content: "";
    position:absolute;
    top:3px;
    right: 0px;
    width: 1px;
    height: 30px;
    background-color: #eaedf3;
    z-index: 10;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #d8dce6;
  }
  .page-item:last-child .page-link:after {
    content: "";
    position:absolute;
    top:3px;
    left: 0px;
    width: 1px;
    height: 30px;
    background-color: #eaedf3;
    z-index: 10;
  }
  .page-item .page-link > span{
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 1.2;
  }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0;
  }
  /* END_PAGINATION */

  .highlighted-border {
    border: 3px solid #f63275;
  }

`;
